import { Component, OnInit } from '@angular/core';
import { TariffLocationsService } from '../../services/tariff-locations-service/tariff-locations-service.service';
import { SaveFleetSubsService } from '../../services/save-fleet-subs-service/save-fleet-subs.service';
import { CompanyCrudUtils } from './companies-crud-utils';
import { CompanyCrudHelper } from './companies-crud-helper';

@Component({
  selector: 'app-companies-crud',
  templateUrl: './companies-crud.component.html',
  styleUrls: ['./companies-crud.component.scss']
})
export class CompaniesCrudComponent implements OnInit {

  private companyCrudUtils: any = new CompanyCrudUtils();
  private companyCrudHelper: any = new CompanyCrudHelper();

  fleetSubscribers: any;
  singleCompanies: any;
  freeAccess: any;
  createTable: any;
  deleteWarning: boolean;
  clickedRow: any;

  constructor( public companies: TariffLocationsService, public fleetSubsService: SaveFleetSubsService) { }

  ngOnInit() {
    this.companies.getAllCompanies().toPromise()
    .then(response => this.createFleetSubTable(response[response.length - 1].company.fleetplan))
      .then(tableFragment => this.renderButton(tableFragment))
      .then(res => console.log(res))
      .catch(err => { console.log(err)})
  }

  removeModal(e:any) {
    this.clickedRow = e.id;
    document.getElementById('warningModal').style.visibility = 'visible';
    document.getElementById('warningModal').style.transition = 'visibility 0s, opacity 2.5s linear';
  }


  removeCompanyYes() {
    document.getElementById('warningModal').style.visibility = 'hidden';
    document.getElementById(this.clickedRow).remove();

    // TODO change the ids of all the rows
    let tableRows = document.querySelector('table').getElementsByTagName('tr');
    for(let i = 1; i < tableRows.length; i++) {
      tableRows[i].id = `row_${i}`;
    }
  }

  removeCompanyNo() {
    document.getElementById('warningModal').style.visibility = 'hidden';
  }


  createFleetSubTable(fleetSubscribers: any): any {

    this.fleetSubscribers = this.companyCrudUtils.sortCompany(fleetSubscribers) // Sorting by alphabetical order
    try {
      this.createTable = document.createDocumentFragment();
      const table = document.createElement('table');
      table.style.backgroundColor = '#b1b1b1';
      const tableRow = document.createElement('tr');
      tableRow.appendChild(document.createElement('th'));
      tableRow.style.backgroundColor = '#a8ca99';
      tableRow.style.color = '#343434';

      this.companyCrudHelper.createTableHeaders(table, tableRow);

      // Create appropriate cell for each row/fleet subscriber
      let counter = 0;
      for(let company = 0; company < fleetSubscribers.length; company++) {
        counter++;

        let newRow = document.createElement('tr')
        newRow.id = `row_${counter}`;        

        let removeRow = document.createElement('td')
        let minusButton = this.createMinusButton(table) as any;
        removeRow.appendChild(minusButton);

        removeRow.style.color = '#ff4503';
        minusButton.style.cursor = 'pointer';

        counter % 2 === 0 ? removeRow.style.backgroundColor = 'white' : removeRow.style.backgroundColor = 'rgb(238, 238, 238)';

        removeRow.addEventListener('click', () => this.removeModal(newRow) );
        newRow.appendChild(removeRow);

        this.companyCrudHelper.createFleetRow(newRow, fleetSubscribers[company], counter);

        table.appendChild(newRow);
      }
      this.createTable.appendChild(table);

      return this.createTable;
    } catch(e) {
      console.error(e);
    }
  }

  renderButton(area: any) {
    let createRowButtonContainer = document.createElement('div');

    // Add Client button
    let addRowButton = document.createElement('button');
    addRowButton.append('Add Client');
    addRowButton.style.marginTop = '1rem';
    addRowButton.style.marginRight = '.5rem';
    addRowButton.style.maxWidth = '6rem';
    

    addRowButton.onclick = () => {
      this.createNewRow(this.companyCrudUtils);
    }

    area.appendChild(createRowButtonContainer);

    let buttonsContainer = document.createElement('buttonsContainer');
    buttonsContainer.style.display = 'grid';
    buttonsContainer.style.gridTemplateColumns = '1fr 1fr';
    buttonsContainer.style.maxWidth = '18rem';

    let saveButton = document.createElement('button');
    saveButton.append('Save All');
    saveButton.style.marginLeft = '.5rem';
    saveButton.style.maxWidth = '6rem';
    saveButton.style.marginTop = '1rem';
    saveButton.style.float = 'right';
    saveButton.style.backgroundColor = '#1f4cff';
    saveButton.id = 'saveButton';
    saveButton.onclick = (event: any) => {
      this.gatherFleetSubTableData(event.target.parentElement.parentElement.parentElement.querySelector('table'));
    }
    saveButton.onmouseenter = () => {
      saveButton.style.backgroundColor = '#d9e1ff';
    }
    saveButton.onmouseleave = () => {
      saveButton.style.backgroundColor = '#1f4cff';
    }

    buttonsContainer.appendChild(addRowButton)
    buttonsContainer.appendChild(saveButton);

    createRowButtonContainer.appendChild(buttonsContainer)
    area.appendChild(createRowButtonContainer)
    document.querySelector('#table-container').appendChild(area);
  }

  gatherFleetSubTableData(table: any): void {

    let fleetSubs = []
    for(let row = 1; row < table.querySelectorAll('tr').length; row++) {
      let sub = table.querySelectorAll('tr')[row];

      let fleetSub = {
        'name' : sub.querySelectorAll('td')[1].textContent,
        'clientId' : sub.querySelectorAll('td')[2].textContent,
        'poNumber' : sub.querySelectorAll('td')[3].textContent,
        'sapCode' : sub.querySelectorAll('td')[4].textContent,
        'contactName' : sub.querySelectorAll('td')[5].textContent,
        'contactEmail' : sub.querySelectorAll('td')[6].textContent,
        'driverInvoicing' : sub.querySelector('.driverCheckbox').checked,
        'excelReportOption' : sub.querySelector('.excelReportOptionCheckbox').checked,
        'oceanUK' : sub.querySelector('.oceanCheckbox').checked
      }
      fleetSubs.push(fleetSub)
    }

    console.log(fleetSubs);
    this.fleetSubsService.postFleetSubAccounts(fleetSubs);
  }


  createMinusButton(table: any) {
    
    let minusButton = document.createElement('button');
    minusButton.className = 'fa fa-minus';
    minusButton.id = 'minusId';
    minusButton.onclick = () => {
      
      let tableRows = table.querySelectorAll('tr');
      for(let row = 0; row < tableRows.length; row++) {
        tableRows.item(row).id = `row_${row}`;
      }
    }

    minusButton.onmouseenter = () => {
      minusButton.style.backgroundColor = 'rgb(255 221 220 / 1)';
      minusButton.style.color = '#960000';
    }
    minusButton.onmouseleave = () => {
      minusButton.style.backgroundColor = 'red';
      minusButton.style.color = 'white';
    }

    minusButton.style.backgroundColor = 'red';
    return minusButton;
  }

  sortCompany(company: any) {
    company.sort((companyA, companyB) => {
      if(companyA.name.toLowerCase() < companyB.name.toLowerCase()) {
        return -1;
      }
      if(companyA.name.toLowerCase() > companyB.name.toLowerCase()) {
        return 1;
      }
      return 0
    });
  }

  createNewRow(companyCrudUtils: any) {
    let table = document.querySelector('table');
    let rowCount = table.querySelectorAll('tr').length;
    let newRow = document.createElement('tr');
    newRow.id = `row_${rowCount}`;
    let newMinusButton = companyCrudUtils.createMinusButton();
    let newMinusButtonContainer = document.createElement('td');
    newMinusButtonContainer.appendChild(newMinusButton);

    newMinusButton.onclick = (e) => {
        this.removeModal(e.target.parentElement.parentElement)
    }

    let newUserPayerId = document.createElement('td');
    let newPONumber = document.createElement('td');
    let newSapCode = document.createElement('td');
    let newClient = document.createElement('td');
    let newName = document.createElement('td');
    let newEmail = document.createElement('td');
    let newDriverInvoicing = document.createElement('td');
    let newExcelReportOption = document.createElement('td');
    let newOceanUK = document.createElement('td');

    if(rowCount % 2 == 0) {
        newMinusButtonContainer.style.backgroundColor = 'rgb(238, 238, 238)';
        newUserPayerId.style.backgroundColor = 'rgb(238, 238, 238)';
        newPONumber.style.backgroundColor = 'rgb(238, 238, 238)';
        newSapCode.style.backgroundColor = 'rgb(238, 238, 238)';
        newClient.style.backgroundColor = 'rgb(238, 238, 238)';
        newName.style.backgroundColor = 'rgb(238, 238, 238)';
        newEmail.style.backgroundColor = 'rgb(238, 238, 238)';
        newDriverInvoicing.style.backgroundColor = 'rgb(238, 238, 238)';
        newExcelReportOption.style.backgroundColor = 'rgb(238, 238, 238)';
        newOceanUK.style.backgroundColor = 'rgb(238, 238, 238)';
    } else {
        newMinusButtonContainer.style.backgroundColor = 'white';
        newUserPayerId.style.backgroundColor = 'white';
        newPONumber.style.backgroundColor = 'white';
        newSapCode.style.backgroundColor = 'white';
        newClient.style.backgroundColor = 'white';
        newName.style.backgroundColor = 'white';
        newEmail.style.backgroundColor = 'white';
        newDriverInvoicing.style.backgroundColor = 'white';
        newExcelReportOption.style.backgroundColor = 'white';
        newOceanUK.style.backgroundColor = 'white';
    }
    newUserPayerId.append('-');
    newUserPayerId.style.color = 'rgb(52, 52, 52)';
    newUserPayerId.style.textAlign = 'centre';
    newUserPayerId.contentEditable = 'true';
    newUserPayerId.style.cursor = 'default';

    newPONumber.append('-');
    newPONumber.style.color = 'rgb(52, 52, 52)';
    newPONumber.style.textAlign = 'centre';
    newPONumber.contentEditable = 'true';
    newPONumber.style.cursor = 'default';

    newSapCode.append('-');
    newSapCode.style.color = 'rgb(52, 52, 52)';
    newSapCode.style.textAlign = 'centre';
    newSapCode.contentEditable = 'true';
    newSapCode.style.cursor = 'default';

    newClient.append('-');
    newClient.style.color = 'rgb(52, 52, 52)';
    newClient.style.textAlign = 'center';
    newClient.contentEditable = 'true';

    newName.append('-');
    newName.style.color = 'rgb(52, 52, 52)';
    newName.style.textAlign = 'center';
    newName.contentEditable = 'true';

    newEmail.append('-');
    newEmail.style.color = 'rgb(52, 52, 52)';
    newEmail.style.textAlign = 'center';
    newEmail.contentEditable = 'true';

    let driverCheckbox = document.createElement('input');
    driverCheckbox.type = "checkbox";
    driverCheckbox.className = "driverCheckbox";
    newDriverInvoicing.append(driverCheckbox);
    newDriverInvoicing.style.color = 'rgb(52, 52, 52)';
    newDriverInvoicing.style.textAlign = 'center';
    newDriverInvoicing.contentEditable = 'true';

    let excelReportOptionCheckbox = document.createElement('input');
    excelReportOptionCheckbox.type = "checkbox";
    excelReportOptionCheckbox.className = "excelReportOptionCheckbox";
    newExcelReportOption.append(excelReportOptionCheckbox);
    newExcelReportOption.style.color = 'rgb(52, 52, 52)';
    newExcelReportOption.style.textAlign = 'center';
    newExcelReportOption.contentEditable = 'true';

    let oceanUkCheckbox = document.createElement('input');
    oceanUkCheckbox.type = "checkbox";
    oceanUkCheckbox.className = "oceanCheckbox";
    newOceanUK.append(oceanUkCheckbox);
    newOceanUK.style.color = 'rgb(52, 52, 52)';
    newOceanUK.style.textAlign = 'center';
    newOceanUK.contentEditable = 'true';

    newRow.appendChild(newMinusButtonContainer);
    newRow.appendChild(newUserPayerId);
    newRow.appendChild(newPONumber);
    newRow.appendChild(newSapCode);
    newRow.appendChild(newClient);
    newRow.appendChild(newName);
    newRow.appendChild(newEmail);
    newRow.appendChild(newDriverInvoicing);
    newRow.appendChild(newExcelReportOption);
    newRow.appendChild(newOceanUK);
    table.appendChild(newRow);
}

}