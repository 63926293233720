import { Component, OnInit } from '@angular/core';
import { AuthguardGuard } from 'src/app/guards/authguard.guard';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Input } from '@angular/core';


@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss']
  
})
export class SideNavbarComponent implements OnInit {
  showInvoiceDropdown: boolean = false;
  showTariffsDropdown: boolean = false;
  showCompaniesDropdown: boolean = false;
  showMapsDropdown: boolean = false;
  showKeyFobAddressRequestDropdown: boolean = false;
  name: string = '';
  userRole: string = '';

  constructor(public authGuard: AuthguardGuard, public authService: AuthService) { }

  ngOnInit(): void {
    this.name = this.authService.user.name.replace('_', ' ');
    if(this.authService.user.roles.includes('ROLE_MODERATOR')) {
      this.userRole = 'Admin';
      document.getElementById('nameColour').style.backgroundColor = '#ff2222';
      document.getElementById('roleBgColour').style.backgroundColor = '#bb0000';
    } else if(this.authService.user.roles.includes('ROLE_SALES')) {
      this.userRole = 'Sales';
     } //else if(this.authService.user.roles.includes('ROLE_KIA')) {
    //   this.userRole = 'Kia';
    // }
  }

  invoicesDropdown() {
    this.showInvoiceDropdown = !this.showInvoiceDropdown;
  }

  tariffsDropdown() {
    this.showTariffsDropdown = !this.showTariffsDropdown;
  }

  companiesDropdown() {
    this.showCompaniesDropdown = !this.showCompaniesDropdown;
  }

  mapsDropdown() {
    this.showMapsDropdown = !this.showMapsDropdown;
  }

  keyFobAddressesRequestDropdown() {
    this.showKeyFobAddressRequestDropdown = !this.showKeyFobAddressRequestDropdown;
  }

}
