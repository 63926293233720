<div class="button-container">
    <!-- Using Property Binding to conditionally set the "color" attribute based on the selected button -->
    <!-- "Color" is the property in this instance -->
    <button (click)="loadAssets()" class="button" mat-raised-button [color]="selectedButton === 'Assets' ? 'primary' : ''">Assets</button>
    <button (click)="loadUsage()" class="button" mat-raised-button [color]="selectedButton === 'Usage' ? 'primary' : ''">Usage</button>
    <button (click)="loadUsers()" class="button" mat-raised-button [color]="selectedButton === 'Users' ? 'primary' : ''">Users</button>
</div>

<div class="information-container">
    <p>Select EasyGo {{ this.selectedButton }} information by the below filters.</p>
</div>

<section class="section" *ngIf="selectedButton === 'Assets'">
    <div class="assets-general-info">
        <h2>General Asset Information 
            <span (click)="toggleGeneralInfo()">
                <i class="fa" [ngClass]="toggleIcon" aria-hidden="true"></i>
            </span>
        </h2>
        
        <div [ngClass]="{'expanded': displayGeneralInfo, 'collapsed': !displayGeneralInfo}" class="table-wrapper">
            <table class="general-info-table" *ngIf="displayGeneralInfo === true">
                <tr>
                    <td></td>
                    <td>
                        <img class="general-info-image" src="assets/data-retriever/ac-charging.jpg" alt="AC Charging image"> 
                        AC Chargers
                    </td>
                    <td>
                        <img class="general-info-image" src="assets/data-retriever/dc-charging.jpg" alt="DC Charging image"> 
                        DC Chargers
                    </td>
                    <td>
                        <img class="general-info-image" src="assets/data-retriever/ac-socket.png" alt="AC Socket image"> 
                        AC Sockets
                    </td>
                    <td>
                        <img class="general-info-image" src="assets/data-retriever/dc-socket.png" alt="DC Socket image"> 
                        DC Sockets
                    </td>
                </tr>
                <tr>
                    <td>
                        <img class="general-info-flag" src="assets/data-retriever/ireland-gif.gif" alt="Flag of Ireland">
                        Ireland
                    </td>
                    <td><b>{{ this.irishChargersAC }}</b></td>
                    <td><b>{{ this.irishChargersDC }}</b></td>
                    <td><b>{{ this.irishSocketsAC }}</b></td>
                    <td><b>{{ this.irishSocketsDC }}</b></td>
                </tr>
                <tr>
                    <td>
                        <img class="general-info-flag" src="assets/data-retriever/uk-gif.gif" alt="Flag of UK">
                        United Kingdom
                    </td>
                    <td><b>{{ this.ukChargersAC }}</b></td>
                    <td><b>{{ this.ukChargersDC }}</b></td>
                    <td><b>{{ this.ukSocketsAC }}</b></td>
                    <td><b>{{ this.ukSocketsDC }}</b></td>
                </tr>
                <tr>
                    <td>Combined</td>
                    <td><b>{{ this.noOfChargersAC }}</b></td>
                    <td><b>{{ this.noOfChargersDC }}</b></td>
                    <td><b>{{ this.noOfSocketsAC }}</b></td>
                    <td><b>{{ this.noOfSocketsDC }}</b></td>
                </tr>
            </table>
        </div>
        
    </div>

    <form (ngSubmit)="submitQuery()" #queryForm="ngForm">
        <div class="assets-filtered-info">
          <h2>Filtered Asset Information</h2>
      
          <div id="assets-insertion-picker" class="inline-container">
            <p class="inline-element">Get all EasyGo Assets inserted on Ocean (dates inclusive): </p>
      
            <mat-form-field class="inline-element">
              <mat-label>Inserted from</mat-label>
              <input matInput [matDatepicker]="fromDatepicker" placeholder="Choose a date" [(ngModel)]="insertedFrom" name="fromDate">
              <mat-datepicker-toggle matSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #fromDatepicker></mat-datepicker>
            </mat-form-field>
              
            <mat-form-field class="inline-element">
              <mat-label>Inserted to</mat-label>
              <input matInput [matDatepicker]="toDatepicker" placeholder="Choose a date" [(ngModel)]="insertedTo" name="toDate">
              <mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #toDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <!-- Radio buttons for AC, DC, and Both -->
        <div class="inline-container">
            <p class="inline-element">Filter assets by charge type: </p>
            <mat-radio-group [(ngModel)]="filteredChargingOption" name="chargingOption" class="inline-element">
                <mat-radio-button class="radio-button" value="AC">AC</mat-radio-button>
                <mat-radio-button class="radio-button" value="DC">DC</mat-radio-button>
                <mat-radio-button value="Both" checked>Both</mat-radio-button> <!-- Default selected value -->
            </mat-radio-group>
        </div>

        <!-- Radio buttons for Self-Owned, Third-Party, and Both -->
        <div class="inline-container">
            <p class="inline-element">Filter by ownership: </p>
            <mat-radio-group [(ngModel)]="filteredOwnershipOption" name="ownershipOption" class="inline-element">
                <mat-radio-button class="radio-button" value="Self-Owned">Self Owned</mat-radio-button>
                <mat-radio-button class="radio-button" value="Third-Party">Third Party</mat-radio-button>
                <mat-radio-button value="Both" checked>Both</mat-radio-button>
            </mat-radio-group>
        </div>

        <!-- Radio buttons for Ireland, UK, Both -->
        <div class="inline-container">
            <p class="inline-element">Filter assets by Ocean instance: </p>
            <mat-radio-group [(ngModel)]="filteredCountryOption" name="countryOption" class="inline-element">
                <mat-radio-button class="radio-button" value="Ireland">Ireland</mat-radio-button>
                <mat-radio-button class="radio-button" value="UK">UK</mat-radio-button>
                <mat-radio-button value="Both" checked>Both</mat-radio-button> <!-- Default selected value -->
            </mat-radio-group>
        </div>

        <!-- Filter by county -->
        <div class="inline-container">
            <p class="inline-element">Filter by county: </p>
            <mat-form-field class="inline-element">
                <mat-label>Select Counties</mat-label>
                <mat-select [(ngModel)]="selectedCounties" name="counties" multiple>
                  <mat-option *ngFor="let county of counties" [value]="county">{{ county }}</mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" (click)="resetCountiesSelected()" class="multi-input-btn" mat-raised-button>Reset</button>
            <button type="button" (click)="selectAllCounties()" class="multi-input-btn" mat-raised-button>Select All</button>
        </div>

        <!-- Filter by sector -->
        <div class="inline-container">
            <p class="inline-element">Filter by sector: </p>
            <mat-form-field class="inline-element">
                <mat-label>Select Sector</mat-label>
                <mat-select [(ngModel)]="selectedSectors" name="sectors" multiple>
                  <mat-option *ngFor="let sector of sectors" [value]="sector">{{ sector }}</mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" (click)="resetSectorsSelected()" class="multi-input-btn" mat-raised-button>Reset</button>
            <button type="button" (click)="selectAllSectors()" class="multi-input-btn" mat-raised-button>Select All</button>
        </div>

        <!-- Filter by vendor -->
        <div class="inline-container">
            <p class="inline-element">Filter by vendor: </p>
            <mat-form-field class="inline-element">
                <mat-label>Select Vendor</mat-label>
                <mat-select [(ngModel)]="selectedVendors" name="vendors" multiple>
                  <mat-option *ngFor="let vendor of vendors" [value]="vendor">{{ vendor }}</mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" (click)="resetVendorsSelected()" class="multi-input-btn" mat-raised-button>Reset</button>
            <button type="button" (click)="selectAllVendors()" class="multi-input-btn" mat-raised-button>Select All</button>
        </div>

        <!-- Filter by LandOwner -->
        <div class="inline-container">
            <p class="inline-element">Filter by land owner: </p>
            <mat-form-field class="inline-element">
                <mat-label>Select Land Owner</mat-label>
                <mat-select [(ngModel)]="selectedLandOwners" name="landOwners" multiple>
                  <mat-option *ngFor="let landOwner of landOwners" [value]="landOwner">{{ landOwner }}</mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" (click)="resetLandOwnersSelected()" class="multi-input-btn" mat-raised-button>Reset</button>
            <button type="button" (click)="selectAllLandOwners()" class="multi-input-btn" mat-raised-button>Select All</button>
        </div>

        <!-- Filter by ChargePointOwner -->
        <div class="inline-container">
            <p class="inline-element">Filter by charge point owner: </p>
            <mat-form-field class="inline-element">
                <mat-label>Select Charge Point Owner</mat-label>
                <mat-select [(ngModel)]="selectedChargePointOwners" name="chargePointOwners" multiple>
                  <mat-option *ngFor="let cpo of chargePointOwners" [value]="cpo">{{ cpo }}</mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" (click)="resetChargePointOwnersSelected()" class="multi-input-btn" mat-raised-button>Reset</button>
            <button type="button" (click)="selectAllChargePointOwners()" class="multi-input-btn" mat-raised-button>Select All</button>
        </div>

        <!-- Filter by Access Types -->
        <div class="inline-container">
            <p class="inline-element">Filter by access types: </p>
            <mat-form-field class="inline-element">
                <mat-label>Select Access Types</mat-label>
                <mat-select [(ngModel)]="selectedAccessTypes" name="accessTypes" multiple>
                  <mat-option *ngFor="let accessType of accessTypes" [value]="accessType">{{ accessType }}</mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" (click)="resetAccessTypesSelected()" class="multi-input-btn" mat-raised-button>Reset</button>
            <button type="button" (click)="selectAllAccessTypes()" class="multi-input-btn" mat-raised-button>Select All</button>
        </div>

        <!-- Filter by Location Types -->
        <div class="inline-container">
            <p class="inline-element">Filter by location type: </p>
            <mat-form-field class="inline-element">
                <mat-label>Select Location Types</mat-label>
                <mat-select [(ngModel)]="selectedLocationTypes" name="locationTypes" multiple>
                  <mat-option *ngFor="let locationType of locationTypes" [value]="locationType">{{ locationType }}</mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" (click)="resetLocationTypesSelected()" class="multi-input-btn" mat-raised-button>Reset</button>
            <button type="button" (click)="selectAllLocationTypes()" class="multi-input-btn" mat-raised-button>Select All</button>
        </div>

        <!-- Filter by Model -->
        <div class="inline-container">
            <p class="inline-element">Filter by model: </p>
            <mat-form-field class="inline-element">
                <mat-label>Select Models</mat-label>
                <mat-select [(ngModel)]="selectedModels" name="models" multiple>
                  <mat-option *ngFor="let model of models" [value]="model">{{ model }}</mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" (click)="resetModelsSelected()" class="multi-input-btn" mat-raised-button>Reset</button>
            <button type="button" (click)="selectAllModels()" class="multi-input-btn" mat-raised-button>Select All</button>
        </div>

        <!-- Filter by Connector Types -->
        <div class="inline-container">
            <p class="inline-element">Filter by connector type: </p>
            <mat-form-field class="inline-element">
                <mat-label>Select Connector Types</mat-label>
                <mat-select [(ngModel)]="selectedConnectorTypes" name="connectorTypes" multiple>
                  <mat-option *ngFor="let connectorType of connectorTypes" [value]="connectorType">{{ connectorType }}</mat-option>
                </mat-select>
            </mat-form-field>
            <button type="button" (click)="resetConnectorTypesSelected()" class="multi-input-btn" mat-raised-button>Reset</button>
            <button type="button" (click)="selectAllConnectorTypes()" class="multi-input-btn" mat-raised-button>Select All</button>
        </div>

        <!-- Filter by Max Output Power -->
        <div class="inline-container">
            <p class="inline-element">Max Output Power Range (kWh):</p>
            <mat-form-field class="inline-element">
                <input matInput type="number" placeholder="From" [(ngModel)]="maxOutputPowerFrom" name="maxOutputPowerFrom">
            </mat-form-field>
            <mat-form-field class="inline-element">
                <input matInput type="number" placeholder="To" [(ngModel)]="maxOutputPowerTo" name="maxOutputPowerTo">
            </mat-form-field>
        </div>
      
        <div class="submit-filtered-query">
          <button type="submit" class="button" mat-raised-button [color]="selectedButton === 'Assets' ? 'primary' : ''">
            Submit Query
          </button>
        </div>
      </form>

</section>
