
<div id="tariffTableWrapper">
    <div class="table-grid">
        <div id='side'>
            <table id="tariff-locations-table" (click)="highlightRowHeaders($event)">
            </table>
        </div>
        <div>
            <table id="tariff-locations-table-details" (click)="highlightRowLocations($event)" (dblclick)="addLocation($event)">
            </table>
    
        </div>
    </div>
</div>

<div>
    <span style='margin-top: 1rem;'>
        {{this.tariffLocationData.responseMessage}}
    </span>
    <button id="submit" (click)="saveNewLocations()">
        Save Locations
    </button>
</div>

