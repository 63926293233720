import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TariffsService {

  url:string = "https://www.easygo-dev.com:8081/v1"; // for production in digitalocean TODO change url

  savedConfirm: string;
  timestamp: Date;

  constructor(private http: HttpClient) { }

  public getAllTariffs() {
    try {
      return this.http.get<any>(`${this.url}/tariffs`);
    } catch (error) {
      console.error(console.error());
      return EMPTY;
    }
  }

  public postTariffs(tariffObject: any) {
    try {
      const headers = { 'Content-Type': 'application/json'};
      let body = `{"tariffs":${JSON.stringify(tariffObject)}}`;

      const req = this.http.post<any>(`${this.url}/postTariffs`, body, {'headers': headers});
      req.subscribe(data => {
        this.timestamp = new Date();
        this.savedConfirm = data + ' at ' + this.timestamp;
      });

    } catch(error) {
      console.error(error);
      return EMPTY;
    }
  }
}
