import { Component, OnInit } from '@angular/core';
import { RfidAddressesService } from 'src/app/services/rfid-addresses-service/rfid-addresses.service';

@Component({
  selector: 'app-rfid-addresses',
  templateUrl: './rfid-addresses.component.html',
  styleUrls: ['./rfid-addresses.component.scss']
})
export class RfidAddressesComponent implements OnInit {

  constructor(
    public rfidAddressesService: RfidAddressesService
  ) { }

  ngOnInit(): void {}

  getRfidAddressesPDF() {
    this.rfidAddressesService.getRfidAddressesPdf();
  }

}
