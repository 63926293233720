<div id='login_grid'>

    <div></div>

    <div id='container'>
        <img
            id='profile-img'
            src='http://ssl.gstatic.com/accounts/ui/avatar_2x.png'
            class='profile-img-card'
        />
        <form
            *ngIf='!isLoggedIn'
            name='form'
            (ngSubmit)='f.form.valid && onSubmit()'
            #f='ngForm'
            novalidate
        >
            <!-- Username -->
            <div class='form-group'>
                <label for='username'>Username</label>
                <input
                    id='username'
                    type='text'
                    class='form-control'
                    name='username'
                    [(ngModel)]='form.username'
                    required
                    #username='ngModel'
                />
                <div
                    class='alert alert-danger'
                    role='alert'
                    *ngIf='username.errors && f.submitted'
                >
                    Username is required!
                </div>
            </div>

            <!-- Password -->
            <div class='form-group'>
                <label for='password'>Password</label>
                <input
                    id='password'
                    type='password'
                    class='form-control'
                    name='password'
                    [(ngModel)]='form.password'
                    required
                    minlength= '8'
                    #password= 'ngModel'
                />
                <div
                    class='alert alert-danger'
                    role='alert'
                    *ngIf='password.errors && f.submitted'
                >
                    <div *ngIf='password.errors.required'>Password is required</div>
                    <div *ngIf='password.erros.minlength'>
                        Password must be at least 8 characters
                    </div>
                </div>
            </div>
            <div class='form-group'>
                <button id='login' class='btn btn-primary btn-block'>
                    Login
                </button>
            </div>
            <div class='form-group'>
                <div
                    class='alert alert-danger'
                    role='alert'
                    *ngIf='f.submitted && isLoginFailed'    
                >
                    Login Failed: {{errorMessage}}
                </div>

            </div>

        </form>

        <div class='alert alert-success' *ngIf='isLoggedIn'>
            Logged in as {{ roles }}.
        </div>

    </div>

</div>
