import { Action } from '@ngrx/store';

export function simpleReducer(state: string = 'false', action: Action) {

    switch (action.type) {
        case 'FALSE' :
            return state = 'false';
        case 'TRUE' :
            return state = 'true';
    }

}