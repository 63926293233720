<div class="map-sidebar">
  <div class="month-selector">
    <div class="btn-group-nav">
      <app-tab-navigator [mapType]="'heatmapnew'"></app-tab-navigator>
    </div>
    <h5 class="title-d">Apply Filter to Map</h5>
    <div class="filter-container">
      <!-- <div class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Network</mat-label>
          <mat-select [formControl]="sessionControl">
           
            <mat-option *ngFor="let sessionType of sessionTypes" [value]="sessionType">{{
              sessionType
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Network</mat-label>
          <mat-select [formControl]="sessionControl" multiple>
            <mat-select-trigger>
              {{ sessionControl.value ? sessionControl.value[0] : "" }}
              <span
                *ngIf="sessionControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ sessionControl.value.length - 1 }}
                {{ sessionControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let sessionType of sessionTypes" [value]="sessionType">{{
              sessionType
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="sessionControl.value?.includes('EasyGo') " class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select</mat-label>
          <mat-select [formControl]="easyGoControl" multiple>
            <mat-select-trigger>
              {{ easyGoControl.value ? easyGoControl.value[0] : "" }}
              <span
                *ngIf="easyGoControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ easyGoControl.value.length - 1 }}
                {{ easyGoControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let easyGoType of easyGoTypes"
              [value]="easyGoType"
              >{{ easyGoType }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="sessionControl.value?.includes('EasyGo') " class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Charge Point Owner Type</mat-label>
          <mat-select [formControl]="chargePointOwnerTypeControl" multiple>
            <mat-select-trigger>
              {{ chargePointOwnerTypeControl.value ? chargePointOwnerTypeControl.value[0] : "" }}
              <span
                *ngIf="chargePointOwnerTypeControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ chargePointOwnerTypeControl.value.length - 1 }}
                {{ chargePointOwnerTypeControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let cpot of chargePointOwnerType"
              [value]="cpot"
              >{{ cpot }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Charge Type</mat-label>
          <mat-select [formControl]="chargeTypeControl" multiple>
            <mat-select-trigger>
              {{ chargeTypeControl.value ? chargeTypeControl.value[0] : "" }}
              <span
                *ngIf="chargeTypeControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ chargeTypeControl.value.length - 1 }}
                {{ chargeTypeControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let chargeType of chargeTypes"
              [value]="chargeType"
              >{{ chargeType }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Year</mat-label>
          <mat-select [formControl]="yearControl">
            <mat-option *ngFor="let year of years" [value]="year">{{
              year
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Months</mat-label>
          <mat-select [formControl]="monthControl" multiple>
            <mat-select-trigger>
              {{ monthControl.value ? monthControl.value[0] : "" }}
              <span
                *ngIf="monthControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ monthControl.value.length - 1 }}
                {{ monthControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let month of months" [value]="month">{{
              month
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="btn-container">
    <button (click)="getMarkerData()" mat-raised-button color="primary">
      Apply
    </button>
    <button class="reset-btn" (click)="reset()" mat-raised-button>
      Reset
    </button>
  </div>
  
  <!-- <h5 class="title-d" style="margin-top: 25px">Upload Files</h5>
  <div class="file-uploader">
    <div class="content">
      <div id="upload-datasets">
        <input
          type="file"
          class="hidden"
          (change)="onFileChange($event)"
          text="esbSessions"
        />
      </div>
    </div>
  </div> -->

  <div *ngIf="!isLoading && _selectedLocation" class="show-details">

    <h5 class="city-name title-d">{{ _selectedLocation.friendlyName }}</h5>

    <div *ngIf="sessionControl.value === 'EasyGo'" class="content">
      <ul class="average-list">
        <li>
          Energy Consumption:
          <span>{{ countsData.energyConsumption | number: "0.0-2" }}</span>
        </li>
        <li>
          Charge Sessions:
          <span>{{ countsData.noOfSessions | number: "0.0-2" }}</span>
        </li>
        <li>
          Time Charging:
          <span>{{ countsData.chargingTime | number: "0.0-2" }}</span>
        </li>
        <li>
          No. of Chargers:
          <span>{{ countsData.noOfChargers | number: "0.0-2" }}</span>
        </li>
      </ul>
      <div class="btn-container">
        <button (click)="navigateToChart()" mat-raised-button color="primary">
          Show More Details
        </button>
      </div>
    </div>
    
    <div *ngIf="sessionControl.value == 'ESB'" class="content">
      <ul class="average-list">
        <li>
          Number of Sessions:
          <span>{{ _selectedLocation.noOfSessions | number: "0.0-2" }}</span>
        </li>
      </ul>
    </div>

  </div>
  
  <div class="loader-container">
    <mat-spinner [diameter]="50" *ngIf="isLoading"></mat-spinner>
  </div>
</div>
