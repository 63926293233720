<div class='couponGrid'>
    <div>

    </div>
    <div>
        <div id='couponHeading'>
            Please select a coupon type:
        </div>
        <div id='couponsContainer'>
                <table id='couponsTable'>
                    <tr>
                        <th>Id</th>
                        <th>Title</th>
    
                        <th>Description</th>
    
                        <th>Valid From</th>
                        <th>Valid To</th>
                        <th>Usage Flavour Id</th>
                        <th>Merchant Company Name</th>

                    </tr>


                </table>
        </div>
    </div>
        <span class="fa fa-arrow-right arrow" aria-hidden="true"></span>
    <div class='formContainer'>
        <form id='couponForm'>
            <div>
                <label for='quantity'>Select coupon quantity:</label>
            </div>
            <div id='couponNumberContainer'>
                <input id='couponInput' type='number' name='quantity' min='1' value='1'>
            </div>
            <div id='idContainer'>
                <span id='selectedId'>Id: &nbsp;</span>

            </div>

        </form>
        <div id='submitButton' (click)='createCoupon($event)'>
            <button>Create Coupons</button>
        </div>
    </div>

    <div>
        
    </div>    
</div>
<div class='returnedMessage'>
    {{this.numberOfCoupons}}&nbsp;{{this.couponService.returnedMessage}}
</div>
