<div class="loader-container">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>

<div class="google-heat-map">
  <div class="map-container">
    <google-map #gMap (zoomChanged)="zoomChange()" [options]="options">
      <ng-container *ngIf="currentZoomLevel >= 10">
        <map-marker
          #markerEle="mapMarker"
          *ngFor="let marker of mapMarkers"
          (mapClick)="onMarkClick($event, marker)"
          (mapMouseover)="openInfoWindow(markerEle, marker)"
          (mapMouseout)="closeInfoWindow()"
          [clickable]="true"
          [position]="marker"
          [options]="marker.options"
        ></map-marker>
        <map-info-window>
          <div class="city-name-d">{{
            hoveredMarker.friendlyName || hoveredMarker.address
          }}</div>
          <div class="city-sessions">
            Number of Sessions: {{
              hoveredMarker.noOfSessions
            }}
          </div>
        </map-info-window>
      </ng-container>
    </google-map>
  </div>
  <div class="map-menu">
    <app-map-sidebar
      [selectedLocation]="selectedMarker"
      (onDateApply)="filterChange($event)"
    ></app-map-sidebar>
  </div>
</div>
