import { Component, OnInit } from '@angular/core';
import { LocationsService } from 'src/app/services/locations-service/locations.service';

@Component({
  selector: 'app-charging-sessions-csv-gen',
  templateUrl: './charging-sessions-csv-gen.component.html',
  styleUrls: ['./charging-sessions-csv-gen.component.scss']
})
export class ChargingSessionsCsvGenComponent implements OnInit {

  locationsArr: Object[];
  canSubmit: boolean;

  dateFromValue: string = null;
  dateToValue: string = null;

  constructor( public locations: LocationsService) { }

  ngOnInit(): void {
    this.locations.getAllLocations().toPromise()
    .then(response =>  {
      this.locationsArr = response[0].locations;
    });
  }

  getLocation(event: any) : void {

    // Only add if not already added
    let selectedLength = document.getElementsByClassName('selectedContainer')[0].children.length;

    let existing = '';
    let divSelects = document.getElementsByClassName('selectedContainer')[0].querySelectorAll('div');

    if(selectedLength > 0) {
      for(let i = 0; i < divSelects.length; i++) {
        existing += divSelects[i].textContent;
      }
    }
    
    let submitContainer = document.createElement('div') as any;
    let text = event.target.textContent.trim() as string;
    
    if(text.startsWith('IE*')) {
      text += ' ' + event.target.parentElement.children[1].textContent;
    } else if(text.startsWith('EasyGo')) {
      text = event.target.parentElement.children[0].textContent + ' ' + text;
    }


    if(!existing.includes(text)) {
      submitContainer.textContent = text;
      const selectedContainer = document.getElementsByClassName('selectedContainer')[0] as any;
      selectedContainer.appendChild(submitContainer);
      submitContainer.style.opacity = '0';
      selectedContainer.style.opacity = '0';
      selectedContainer.style.marginTop = '1rem';
  
      // used to create a transition appearing effect
      setTimeout(() => {
        document.getElementById('submitButton').style.transition = 'opacity 1s';
        document.getElementById('submitButton').style.opacity = '1';
        selectedContainer.style.transition = 'opacity 1s';
        submitContainer.style.transition = 'opacity 1s';
        selectedContainer.style.opacity = '1';
        submitContainer.style.opacity = '1';
  
        document.getElementById('datePicker').style.transition = 'opacity 1s';
        document.getElementById('datePicker').style.opacity = '1';
      }, 10)

    }

    const chargingFrom = (document.getElementById('chargingFrom') as any).value;
    const chargingTo = (document.getElementById('chargingTo') as any).value;

    if(chargingFrom === '' || chargingTo === '') {
      document.getElementById('submitBut').style.backgroundColor = '#8d8d8d';
      document.getElementById('submitBut').style.color = 'black';
      document.getElementById('submitBut').style.cursor = 'not-allowed';
    } else if(chargingFrom !== '' || chargingTo !== '') {
      document.getElementById('submitBut').style.backgroundColor = '#97d204';
      document.getElementById('submitBut').style.color = 'white';
      document.getElementById('submitBut').style.cursor = 'pointer';
    }
  }

  createCsvFiles(event: any) {

    if(document.getElementById('submitBut').style.cursor !== 'not-allowed') {
      let locationsLocal = event.target.parentElement.parentElement.getElementsByClassName('selectedContainer')[0].children;
      
      let postObject = {
        locations: [],
        chargingFrom: '',
        chargingTo: ''
      };

      // Post to API to create CSV files
      let locationsToPost = [];
  
      for(let location of locationsLocal) {
        locationsToPost.push(location.textContent.trim());
      }

      if( (this.dateFromValue !== null && this.dateFromValue !== '') && (this.dateToValue !== null && this.dateToValue !== '') ) { 

        postObject.locations = locationsToPost;
        postObject.chargingFrom = this.dateFromValue;
        postObject.chargingTo = this.dateToValue;

        this.locations.postLocationsForCsvCreation(postObject);
      }
    }
  }

  checkDates() {

    document.getElementById('errorMessages').style.display = 'block';

    setTimeout(() => {
      document.getElementById('errorMessages').style.transition = 'opacity 1s';
      document.getElementById('errorMessages').style.opacity = '1';
    }, 10);

    this.dateFromValue = (document.getElementById('chargingFrom') as any).value;
    this.dateToValue = (document.getElementById('chargingTo') as any).value;

    let dateNow = new Date();
    let dateFrom = new Date(this.dateFromValue);
    let dateTo = new Date(this.dateToValue);

    if( (dateNow.getTime() < dateTo.getTime()) || (dateNow.getTime() < dateFrom.getTime()) ) {
      let futureTimeMessage = document.createElement('div');
      futureTimeMessage.textContent = 'Please select a to date in the past';
      document.getElementById('errorMessages').appendChild(futureTimeMessage);
    }

    if(dateTo.getTime() < dateFrom.getTime()) {
      let toDateGreaterThanFromDate = document.createElement('div');
      toDateGreaterThanFromDate.textContent = 'From date must be before to date';
      document.getElementById('errorMessages').appendChild(toDateGreaterThanFromDate);
    }
  }

}
