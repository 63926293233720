import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import * as XLSX from 'xlsx';

export interface AddressInfo {
  Address: string;
  County: string;
  Latitude: string;
  Longitude: string;
  PostCode: string;
}

export interface Charger {
  accessInstructions: string;
  accessType: string;
  chargePointAssetStatus: string;
  chargePointId: number;
  chargePointOwner: string;
  chargerType: string;
  connectionsPerDay: 0;
  connectors: any;
  contractedDate: string;
  daysLiveDuringMonth: number;
  energyConsumedPerConnection: number;
  energySoldPerChargerPerDay: number;
  friendlyCode: string;
  inOperationSince: string;
  insertedDate: string;
  installationFinishDate: string;
  installationStartDate: string;
  invoiceAmount: number;
  landOwner: string;
  location: string;
  locationType: string;
  maxOutputPower: number;
  model: string;
  noOfConnections: number;
  noOfSockets: number;
  owner: string;
  oceanInstance: string;
  reimbursement: number;
  salesOrderNo: number;
  sector: string;
  serialNumber: string;
  totalEnergyConsumedInMonth: number;
  updateTimed: string;
  vendor: string;
}

@Injectable({providedIn: 'root'})
export class ProjectRapidService {

    // url: string = 'http://localhost:8080/api'; // For local testing
    url: string = "https://www.easygo-dev.com:8041/api"; // For production
    public chargers: any;

    constructor(private http: HttpClient) {}

    public getEasyGoAssetsFromMongo() {
      if (this.chargers == null) { // Load this only once
        try {
            this.http.get<JSON>(this.url + '/getEasyGoAssetsFromMongo')
            .pipe(
              catchError(this.handleError)
            )
            .subscribe(data => {
                // Handle the returned data
                this.chargers = data;
            })
        } catch (error) {
          console.error(error);
          return EMPTY;
        }
      }
    }

    private handleError(error: HttpErrorResponse) {
      // Log error details
      console.error('An error occurred:', error);
      return throwError(
          'Something bad happened; please try again later.');
  }

    public getChargers() {

      let chargersWithAddressInfoForExcel: Charger[] = [];

      // Make Address Info more easily retrievable for excel file creation
      for(let charger in this.chargers) {
        let chargerInfo = this.chargers[charger];
        let addressInfo = chargerInfo['addressInfo'];

        let friendlyCode = chargerInfo['friendlyCode'];
        let friendlyCodeWithoutHyphen = friendlyCode.split('-')[0];
        chargerInfo['friendlyCode'] = friendlyCodeWithoutHyphen;

        chargerInfo['address'] = addressInfo['Address'];
        chargerInfo['county'] = addressInfo['County'];
        chargerInfo['latitude'] = addressInfo['Latitude'];
        chargerInfo['longitude'] = addressInfo['Longitude'];

        chargersWithAddressInfoForExcel.push(chargerInfo);
      }

      return this.chargers;
    }

    public getCountry(friendlyCode: string) {
      let country: string = "Ireland";
      friendlyCode.startsWith("GB*") ? country = "UK" : country = "Ireland";
      return country;
    }

    public createExcel(filteredChargers: Charger[]) {

      let sortedChargers = this.sortChargersByFriendlyCode(filteredChargers);
      let reorderChargers = this.reorderAndCapitalizeChargerFields(sortedChargers);

      // Convert filtered data to Excel format
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(reorderChargers);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

      // Export the Excel file
      XLSX.writeFile(workbook, 'Filtered EasyGo Assets.xlsx');
    }

    sortChargersByFriendlyCode(chargers: Charger[]) {
      return chargers.slice().sort((a, b) => a.friendlyCode.localeCompare(b.friendlyCode));
    }

    // Method to reorder Charger fields and capitalize the first letter
    private reorderAndCapitalizeChargerFields(chargers: Charger[]): Charger[] {
      return chargers.map(charger => {
        // Define the desired order of fields
        const orderedFields = [
          'location',
          'friendlyCode',
          'sector',
          'accessInstructions',
          'accessType',
          'county',
          'address',
          'latitude',
          'longitude',
          'postCode',
          'oceanInstance',
          'owner',
          'chargePointOwner',
          'landOwner',
          'chargePointId',
          'serialNumber',
          'vendor',
          'model',
          'noOfSockets',
          'chargerType',
          'maxOutputPower',
          'chargePointAssetStatus',
          'connectorOneType',
          'connectorTwoType',
          'connectorThreeType',
          'insertedDate',
          'installationStartDate',
          'installationFinishDate',
          'updateTimed',
          'locationType',
          'reimbursement'
          // Add other field names in the desired order
        ];

        // Create a new Charger object with fields reordered and capitalized
        const reorderedCharger: Partial<Charger> = {};
        orderedFields.forEach(field => {
          if (
            (field !== 'addressInfo' 
            && field !== 'noOfConnections'
            && field !== 'daysLiveDuringMonth'
            && field !== 'connectionsPerDay'
            && field !== 'totalEnergyConsumedInMonth'
            && field !== 'energyConsumedPerConnection'
            && field !== 'energySoldPerChargerPerDay'
            ) && charger.hasOwnProperty(field)) {
            const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
            reorderedCharger[capitalizedField] = charger[field];
          }
        });

        // Copy over any remaining fields not explicitly reordered and capitalized
        for (const key in charger) {
          if (!orderedFields.includes(key) && 
          (key !== 'addressInfo' 
          && key !== 'noOfConnections'
          && key !== 'daysLiveDuringMonth'
          && key !== 'connectionsPerDay'
          && key !== 'totalEnergyConsumedInMonth'
          && key !== 'energyConsumedPerConnection'
          && key !== 'energySoldPerChargerPerDay'
          )) {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            reorderedCharger[capitalizedKey] = charger[key];
          }
        }

        return reorderedCharger as Charger;
      });
    }

}