import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HeatmapService } from 'src/app/services/heatmap-service/heatmap.service';

@Component({
  selector: 'app-charger-sidebar',
  templateUrl: './charger-sidebar.component.html',
  styleUrls: ['./charger-sidebar.component.scss']
})
export class ChargerSidebarComponent implements OnInit {
  dateForm: FormGroup;
  isLoading = false;
  chargeTypes: any = ['AC', 'DC'];
  chargers: any = ['EasyGo', 'ESB', 'Ionity', 'Tesla', 'Eir', 'MYB', 'Weev/EPower'];
  eirTypes: any = [{
    label: 'Eir - In Progress',
    key: 'InProgress'
  }, {
    label: 'Eir - Live 2024',
    key: 'Live 2024'
  }, {
    label: 'Eir - Installed',
    key: 'Installed'
  },{
    label: 'RoE EV Charger Project',
    key: 'RoE EV Charger Project'
  },{
    label: 'Eir September Sales',
    key: 'Eir September Sales'
  }];
  mybTypes: any = [{   
    label: 'MYB - 24kW',
    key: 'MYB - 24'
   }, {
    label: 'MYB - 50kW',
    key: 'MYB - 50'
  }, {
    label: 'MYB - 75kW',
    key: 'MYB - 75'
  },{
    label: 'MYB - 180kW',
    key: 'MYB - 180'
  },{
    label: 'MYB - AC',
    key: 'MYB - AC'
  }];
  weevEPowerTypes: any = [{
    label: 'Weev',
    key: 'Weev'
  },{
    label: 'ePower',
    key: 'ePower'
  }]
  easyGoTypes: any = ['Public', 'Private'];
  chargeTypeControl: FormControl = new FormControl([], []);
  chargerControl: FormControl = new FormControl([], []);
  eirTypeControl: FormControl = new FormControl(["InInstallation"], []);
  easyGoControl: FormControl = new FormControl(['Public', 'Private'], []);
  mybTypeControl: FormControl = new FormControl([], []);
  weevEPowerTypeControl: FormControl = new FormControl([], []);
  
  chargePointOwnerType: any = ['EasyGo', 'Client', 'Multiple'];
  chargePointOwnerTypeControl: any = new FormControl(['EasyGo']);
  @Output() onDateApply = new EventEmitter<any>();
  energyAverage: number;
  chargingAverage: any;
  timeAverage: number;
  _selectedLocation: any;
  @Input() set selectedLocation(value) {
    this._selectedLocation = value;
    // this.applyFilter();
  }

  constructor(private fbBuilder: FormBuilder,
    public heatmap: HeatmapService, private _snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit(): void {
  }

  getEirSelectedItem(key) {
    return this.eirTypes.find((eir) => eir.key === key);
  }

  getMYBSelectedItem(key) {
    return this.mybTypes.find((myb) => myb.key === key);
  }

  getWeevEPowerSelectedItem(key) {
    return this.weevEPowerTypes.find((weevEPower) => weevEPower.key === key);
  }

  generateData() {
    const data: any = {
      charger: this.chargerControl.value,
      chargeType: this.chargeTypeControl.value,
      accessType: this.easyGoControl.value,
      eirType: this.eirTypeControl.value,
      mybType: this.mybTypeControl.value,
      weevEPowerType: this.weevEPowerTypeControl.value,
      chargePointOwnerType: this.chargePointOwnerTypeControl.value
    }
     console.log('Generated Data:', data);
    return data;
  }

  reset() {
    this.chargerControl.setValue([]);
    this.getMarkerData();
  }

  getMarkerData() {
    const data = this.generateData();
    this.onDateApply.emit(data);
    console.log('Generated Data:', data);
  }

  applyFilter() {

    const data = this.generateData();

    if (this._selectedLocation) {
      // this.getChartData(data)
    }
    this.onDateApply.emit(data);
    // this.onDateApply.emit(data);
    console.log('Generated Data:', data);

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  generateChartData(data): any {
    return {
      labels: Object.keys(data),
      dataset: Object.values(data)
    }
  }

  getChartData(data) {
    this.isLoading = true;
    this.energyAverage = 0;
    this.timeAverage = 0;
    this.chargingAverage = 0;
    this.heatmap.getChartData(data).subscribe((res: any) => {
      this.isLoading = false;
      if (res.energyConsumption) {
        const { labels, dataset } = this.generateChartData(res.energyConsumption);
        this.energyAverage = dataset.reduce((a, b) => a + b, 0) / dataset.length;
      }

      if (res.noOfChargingSessions) {
        const { labels, dataset } = this.generateChartData(res.noOfChargingSessions);
        this.chargingAverage = dataset.reduce((a, b) => a + b, 0);
      }

      if (res.chargingTime) {
        const { labels, dataset } = this.generateChartData(res.chargingTime);
        this.timeAverage = dataset.reduce((a, b) => a + b, 0) / dataset.length;
      }
    }, (error) => {
      this.openSnackBar("Something Went Wrong!", "Close");
      this.isLoading = false;
    })
  }

  navigateToChart() {
    const data = this.generateData();

    if (this._selectedLocation) {
      this.router.navigate(['/chart'], {
        queryParams: {
          locationId: data.locationId,
          chargeType: data.chargeType
        }
      })
    }

  }
}
