import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { InvoiceApiRetrieveService } from '../../services/invoice-api-retrieve-service/invoice-api-retrieve.service';
import { ChargingSessionsQuery } from '../../interfaces/chargingSessionsQuery';
import { ChargingSessionsFleetQuery } from '../../interfaces/chargingSessionsFleetQuery';
import * as moment from 'moment';
import { StateManagementService } from 'src/app/services/state-management-service/state-management-service.service';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-api-invoice',
  templateUrl: './api-invoice.component.html',
  styleUrls: ['./api-invoice.component.scss']
})
export class ApiInvoiceComponent implements OnInit {

  private chargingFromMultiple: string = '';
  private chargingToMultiple: string = '';
  public flaggedSessions = [];
  public uniqueFlaggedSessions = [];

  private chargingSessionsQuery: ChargingSessionsQuery = { 
    companies : [],
    accessTypes: '',
    chargingFrom: '',
    chargingTo: ''
  };

  private chargingSessionsFleetQuery: ChargingSessionsFleetQuery = {
    company: '',
    chargingFrom: '',
    chargingTo: '',
    userPayerId: ''
  }

  public profileForm = new FormGroup({
    chargingFrom : new FormControl(''),
    chargingTo : new FormControl(''),
    singleCompany: new FormControl(''),
    accessType: new FormControl('')
  });

  public multipleFleetSubReportForm = new FormGroup({
    fleetSubCompanies: new FormControl(''),
    chargingFrom : new FormControl(''),
    chargingTo : new FormControl('')
  });

  public showTable: boolean;
  public singleCompaniesList: any[];
  public fleetplanCompaniesList: any[];
  private data: any;
  private showFleetPlanCompanies = false;
  private returnedData: any;
  public noDataFound = false;
  public dataFound = false;
  private chargingFromFleet: any;
  private chargingToFleet: any;

  private fleetCompany: string;
  private fleetFormMessage: string;
  private userPayerId: string;
  public showSubmitButton: boolean;
  public selectAllFleetSubs:boolean;

  public showFormInputs: boolean;
  public showFleetFormInputs: boolean;

  constructor(
    public companies: InvoiceApiRetrieveService,
    private formBuilder: FormBuilder,
    public stateManagement: StateManagementService
    ) { 
      this.profileForm = this.formBuilder.group({
        companies: [ false, Validators.requiredTrue],
        chargingFrom: [''],
        chargingTo: [''],
        accessType: ['', Validators.required]
      })
      this.multipleFleetSubReportForm = this.formBuilder.group({
        fleetSubCompanies: [ '', Validators.required],
        chargingFrom: [''],
        chargingTo: ['']
      })
    }

  
  public getFreeAccessCompaniesList() {
    this.companies.getFreeAccessCompaniesList().subscribe(() => {
      this.fleetplanCompaniesList = this.data[0][1];
    })
  }

  public ngOnInit() :void {
    this.getFleetplanCompaniesListForMultipleReportGen();
    if(this.stateManagement.showSingleCompanies) {
      this.getSingleCompaniesList();
    }
    this.companies.getBadSessions().subscribe( (res: any) => {
      for(let sessionList of res) {
        for ( let session of sessionList.sessions) {
          this.flaggedSessions.push(session);
        }
      }
      this.uniqueFlaggedSessions = this.getUniqueListBy(this.flaggedSessions, 'sessionId');
      console.log(this.uniqueFlaggedSessions);
    
    })
  }

  public getUniqueListBy(flaggedSessions, key) {
    return [ ...new Map(flaggedSessions.map(session => [session[key], session])).values()]
  }

  public hideFleetDropdown() :void {
    (document.getElementsByClassName('dropdownContent')[0] as HTMLElement).style.visibility = 'hidden';
  }

  public highlightCompanyChecks(e: any) {
    if (e.path[2].localName !== 'form' && (e.path[0].innerText !== 'Private access - home' && e.path[0].innerText !== 'Private - only for employees' &&
    e.path[0].innerText !== 'Public accessible - Free access' && e.path[0].innerText !== 'Public access - payable')) {
      e.path[0].checked ? e.path[2].style.backgroundColor = '#6c9603' : e.path[2].style.backgroundColor = '#555'
    }
  }

   public getSingleCompaniesList() :void {
     this.companies.getSingleCompaniesList().subscribe((data: any) => {
       this.singleCompaniesList = data;

       // save boolean in service
       this.stateManagement.showSingleCompanies = true;
        this.companies.dateTo = moment().format('yyyy-MM-DDTHH:mm');
        this.companies.dateFrom = moment().startOf('month').format('yyyy-MM-DDTHH:mm');
        
      });
      this.showFormInputs = true;
      this.showFleetPlanCompanies = true;
    }

    public getFleetplanCompaniesListForMultipleReportGen() :void {
      this.companies.getFleetPlanCompaniesList().subscribe((data: any) => {
        this.fleetplanCompaniesList = data;
        console.log(this.fleetplanCompaniesList)

        let currentDate = new Date();
        let startOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth()-1, 1);
        let endOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0, 23, 59, 59);

        this.companies.dateTo = moment(startOfPreviousMonth).format('yyyy-MM-DDTHH:mm');
        this.companies.dateFrom = moment(endOfPreviousMonth).startOf('month').format('yyyy-MM-DDTHH:mm');
        (document.getElementById('fleetSubscriberMultipleChargingTo') as any).value = moment().format('yyyy-MM-DDTHH:mm');
        (document.getElementById('fleetSubscriberMultipleChargingFrom') as any).value = moment().startOf('month').format('yyyy-MM-DDTHH:mm');
      });

    }
    
    public getFleetplanCompaniesList(event) :void {
      this.companies.getFleetPlanCompaniesList().subscribe((data: any) => {
        this.fleetplanCompaniesList = data;

        (document.getElementById('fleetDropdownList') as any).style.visibility = 'visible';
        
        let currentDate = new Date();

        let startOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth()-1, 1);
        let endOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0, 23, 59, 59);

        this.companies.dateTo = moment(startOfPreviousMonth).format('yyyy-MM-DDTHH:mm');
        this.companies.dateFrom = moment(endOfPreviousMonth).startOf('month').format('yyyy-MM-DDTHH:mm');
        let chargingTimes = event.target.parentElement.parentElement.parentElement.parentElement.getElementsByClassName('charging')
        let chargingFrom = chargingTimes[0].lastChild.children[1];
        let chargingTo = chargingTimes[1].lastChild.children[1];

        chargingFrom.value = moment(startOfPreviousMonth).format('yyyy-MM-DDTHH:mm');
        chargingTo.value = moment(endOfPreviousMonth).format('yyyy-MM-DDTHH:mm');

        (document.getElementById('fleetSubscriberChargingFrom') as any).value = chargingFrom.value;
        (document.getElementById('fleetSubscriberChargingTo') as any).value = chargingTo.value;
      });
      
    }

    public getCompanyUserPayers(): void {
      this.companies.getCompanyUserPayers().subscribe((data: any) => {
        this.sendFormQuery(data);

      });
    }

    public getFleetCompanySessions(e: any) :void {
      this.chargingFromFleet = (document.getElementsByClassName('input-group')[0].firstElementChild as any).value;
      this.chargingToFleet = (document.getElementsByClassName('input-group')[1].firstElementChild as any).value;
      this.fleetFormMessage = '';
      
      if(this.chargingFromFleet > this.chargingToFleet) {
        this.fleetFormMessage = 'The to date must be before the from date.  ';
      }
      if(this.chargingFromFleet === '') {
        this.fleetFormMessage += 'Charging from date must not be empty.  ';
      }
      if(this.chargingToFleet === '') {
        this.fleetFormMessage += 'Charging to date must not be empty.  ';
      }
      if(this.fleetFormMessage.length > 0) {
        this.fleetFormMessage = 'Sorry, unable to post request due to the following:  ' + this.fleetFormMessage;
      }
      if(this.fleetFormMessage === '') {

        let toDisplayDate = this.chargingToFleet.split('T')[0];
        let fromDisplayDate = this.chargingFromFleet.split('T')[0];
        let toDate = toDisplayDate.split('-')[2] + '-' + toDisplayDate.split('-')[1] + '-' + toDisplayDate.split('-')[0];
        let fromDate = fromDisplayDate.split('-')[2] + '-' + fromDisplayDate.split('-')[1] + '-' + fromDisplayDate.split('-')[0];

        this.fleetFormMessage = `Attempting to generate report for ${this.fleetCompany} 
          from ${fromDate} to ${toDate}`;
      }
      this.chargingSessionsFleetQuery.company = this.fleetCompany;
      this.chargingSessionsFleetQuery.chargingFrom = this.chargingFromFleet
      this.chargingSessionsFleetQuery.chargingTo = this.chargingToFleet
      this.chargingSessionsFleetQuery.userPayerId = this.userPayerId;
      this.companies.sendFleetApiQuery(this.chargingSessionsFleetQuery);
    }


   public createFleetSubscriberForm(event: any) :void {
      this.showSubmitButton = true;
      this.fleetCompany = event.target.value;
      
      this.fleetplanCompaniesList.forEach(val => {
        if(val.name === this.fleetCompany) {
          this.userPayerId = val.clientId;
        }
      });

      // need to generate form
      this.companies.dateTo = moment().format('yyyy-MM-DDTHH:mm');
      this.companies.dateFrom = moment().startOf('month').format('yyyy-MM-DDTHH:mm');
      this.showFleetFormInputs = true;
    }

    submitMultipleFleetsubs(event): void {  
      let arrOfCompanies = event.target.parentElement.parentElement;
      let selectedCompanies = arrOfCompanies.querySelectorAll('input[type=checkbox]:checked');
      let selectedCompaniesListNames: string[] = [];

      for(let node of selectedCompanies) {
        selectedCompaniesListNames.push(node.name as string);
      }
      let selectedCompaniesList: ChargingSessionsFleetQuery[] = [];

      if(!this.selectAllFleetSubs) {
        for(let selectedCompany of selectedCompaniesListNames) {
          for(let fleetCompany of this.fleetplanCompaniesList) {
            if(selectedCompany === fleetCompany.name) {
              selectedCompaniesList.push(fleetCompany);
            }
          }
        }
      } else {
        selectedCompaniesList = this.fleetplanCompaniesList;
      }
      
      this.chargingFromMultiple = (document.getElementById('fleetSubscriberMultipleChargingFrom') as any).value;
      this.chargingToMultiple = (document.getElementById('fleetSubscriberMultipleChargingTo') as any).value;

      this.companies.sendFleetsApiQuery(selectedCompaniesList, this.chargingFromMultiple, this.chargingToMultiple);
    }

    sendFormQuery(companyUserPayersList) {

      let checkedElements: Array<any> = document.querySelectorAll ('input[type="checkbox"]:checked') as any;    
      let checkedValues = []; 
      for(let i = 0; i <checkedElements.length; i++) {
        checkedValues.push(checkedElements[i].value);
      } 

      let chargingTypes = [];
      for(let i = 0; i < checkedValues.length; i++) {
        if( 
          checkedValues[i] == "Private access - home" || checkedValues[i] == "Private - only for employees" || 
          checkedValues[i] == "Public accessible - Free access" || checkedValues[i] == "Public access - payable" ||
          checkedValues[i] == "Topup funds and pay per usage" ) {
          chargingTypes.push(checkedValues[i]);
        }
      }
      checkedValues = checkedValues.slice(0, checkedValues.length - chargingTypes.length);
      
      
      let userPayers = [];
      for(let companyUserPayers = 0; companyUserPayers < companyUserPayersList.length; companyUserPayers++) {
        for(let checkedValue = 0; checkedValue < checkedValues.length; checkedValue++) {
          
          if(companyUserPayersList[companyUserPayers].companyName === checkedValues[checkedValue] ) {
            userPayers.push(companyUserPayersList[companyUserPayers]);
          }
        }
      }

      this.chargingSessionsQuery.companies = userPayers;
      this.chargingSessionsQuery.accessTypes = chargingTypes;
      this.chargingSessionsQuery.chargingFrom = this.profileForm.value.chargingFrom;
      this.chargingSessionsQuery.chargingTo = this.profileForm.value.chargingTo;

      // Name pdf file that will be generated
      this.companies.selectedCompany = this.chargingSessionsQuery.companies[0].companyName.replaceAll(' ', '_');

      let datesValid = false;

      let fromDate = new Date(this.chargingSessionsQuery.chargingFrom);
      if(this.chargingSessionsQuery.chargingFrom === "") {
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 7);
      }
      let toDate = new Date(this.chargingSessionsQuery.chargingTo);
      if(this.chargingSessionsQuery.chargingTo === "") {
        toDate = new Date();
      }
      if(fromDate < toDate) {
        datesValid = true;
      }

      if(datesValid && this.chargingSessionsQuery.companies.length > 0 && this.chargingSessionsQuery.accessTypes.length > 0 && 
            fromDate != null && toDate != null) {
        (document.getElementById('submitButton') as any as HTMLElement).style.display = 'block';
        try {

          this.companies.sendApiQuery(this.chargingSessionsQuery)
            .subscribe(result => {
              this.returnedData = result;
              if(this.returnedData === "No charging sessions found") {
                this.noDataFound = true;
                this.dataFound = false;
              } else if (typeof this.returnedData === 'object' && this.returnedData !== null) {
                this.dataFound = true;
                this.noDataFound = false;
              }
            });
        } catch(error) {
          return EMPTY;
        }
      }

    }

    getAllFleetSubs() {
      this.selectAllFleetSubs = !this.selectAllFleetSubs;
    }

}
