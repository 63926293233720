<div class="loader-container">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>

<div class="site-details-container">
  <div class="btn-container">
    <button (click)="goBack()" mat-raised-button>Go Back</button>
  </div>
  <div class="header-section">
    <div class="content">
      <app-card-content
        title="Energy Consumption (KW)"
        [data]="countsData.energyConsumption"
      ></app-card-content>
    </div>
    <div class="content">
      <app-card-content
        title="Time Charging (Hrs)"
        [data]="countsData.chargingTime"
      ></app-card-content>
    </div>
    <div class="content">
      <app-card-content
        title="No. of Chargers"
        [data]="countsData.noOfChargers"
      ></app-card-content>
    </div>
  </div>
  <div class="body-content">
    <div class="left-sidebar">
      <h4 class="filter-title">Filters</h4>
      <div class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Filter</mat-label>
          <input
            type="text"
            placeholder="Search"
            name="myControl"
            matInput
            [formControl]="myControl"
            ngDefaultControl
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            panelWidth="400px"
            [formControl]="locationControl"
            ngDefaultControl
            #auto="matAutocomplete"
            [displayWith]="displayFn"
          >
            <mat-option
              *ngFor="let location of filteredOptions | async"
              [value]="location"
              >{{ location.friendlyName }}</mat-option
            >
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Charge Type</mat-label>
          <mat-select [formControl]="chargeTypeControl" multiple>
            <mat-select-trigger>
              {{ chargeTypeControl.value ? chargeTypeControl.value[0] : "" }}
              <span
                *ngIf="chargeTypeControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ chargeTypeControl.value.length - 1 }}
                {{ chargeTypeControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let chargeType of chargeTypes"
              [value]="chargeType"
              >{{ chargeType }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Year</mat-label>
          <mat-select [formControl]="yearControl">
            <mat-option *ngFor="let year of years" [value]="year">{{
              year
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Months</mat-label>
          <mat-select [formControl]="monthControl" multiple>
            <mat-select-trigger>
              {{ monthControl.value ? monthControl.value[0] : "" }}
              <span
                *ngIf="monthControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ monthControl.value.length - 1 }}
                {{ monthControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option *ngFor="let month of months" [value]="month">{{
              month
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="content">
        <div class="btn-container">
          <button (click)="applyFilter()" mat-raised-button color="primary">
            Apply
          </button>
        </div>
      </div>
    </div>
    <div class="main-content">
      <mat-tab-group>
        <mat-tab label="Energy Consumption" #energyConsumption> </mat-tab>
        <mat-tab label="Charge Sessions" #chargeSessions></mat-tab>
        <mat-tab label="Time Charging" #timeCharging></mat-tab>
      </mat-tab-group>
      <div
        [ngStyle]="{ display: !energyConsumption.isActive ? 'none' : null }"
        class="bar-chart-container"
      >
        <canvas class="bar-chart" #line></canvas>
        <div class="content mr-space">
          <app-card-content
            title="Average of Energy Consumption"
            [data]="energyAverage"
          ></app-card-content>
        </div>
      </div>
      <div
        [ngStyle]="{ display: !chargeSessions.isActive ? 'none' : null }"
        class="bar-chart-container"
      >
        <canvas class="bar-chart" #bar></canvas>
        <div class="content mr-space">
          <app-card-content
            title="Average of Charge Sessions"
            [data]="chargingAverage"
          ></app-card-content>
        </div>
      </div>
      <div
        [ngStyle]="{ display: !timeCharging.isActive ? 'none' : null }"
        class="bar-chart-container"
      >
        <canvas class="bar-chart" #barTime></canvas>
        <div class="content mr-space">
          <app-card-content
            title="Average of Time Charging"
            [data]="timeAverage"
          ></app-card-content>
        </div>
      </div>
    </div>
    <div class="right-sidebar"></div>
  </div>
</div>
