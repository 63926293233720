import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  getUrl:string = 'https://www.easygo-dev.com:8081/v1/getCouponTypes'; // production
  // getUrl:string = 'https://localhost:8081/v1/getCouponTypes'; // development

  postUrl:string = "https://www.easygo-dev.com:8081/v1/issueMerchantCoupons" // production
  // postUrl:string = "https://localhost:8081/v1/issueMerchantCoupons" // development

  returnedMessage: string = '';

  constructor(private http: HttpClient) { }

  postCouponsInstructions(couponPostData: Object): any {
    
    try {      
      const headers = { 'Content-Type': 'application/json'};
      let body = `${JSON.stringify(couponPostData)}`;
      const req = this.http.post<any>(`${this.postUrl}`, body, {'headers': headers});
      req.subscribe(data => {
        if(data.IsSuccessful) {
          this.returnedMessage = `Successfully added ${data.ResultCode} coupons from coupon ID of ${data.Id}`;

        }
      });

    } catch(error) {
      console.error(error);
      return EMPTY;
    }
  }

  public getCouponTypes() {
    try {
      return this.http.get<JSON>(`${this.getUrl}`)
    } catch(error) {
      console.error(error)
      return EMPTY;
    }
  }
}
