import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChargersMapNewComponent } from './components/chargers-map-new/chargers-map-new.component';
import { GHeatMapComponent } from './components/g-heat-map/g-heat-map.component';
import { LoginComponent } from './components/login/login.component';
import { SiteDetailsComponent } from './components/site-details/site-details.component';
import { AuthguardGuard as AuthGuard } from './guards/authguard.guard';
import { ApiInvoiceComponent } from './components/api-invoice/api-invoice.component';
import { ApiInvoiceSiteComponent } from './components/api-invoice-site/api-invoice-site.component';
import { CsvInvoiceComponent } from './components/csv-invoice/csv-invoice.component';
import { TariffsComponent } from './components/tariffs/tariffs.component';
import { TariffLocationsComponent } from './components/tariff-locations/tariff-locations.component';
import { TariffTestRecordsComponent } from './components/tariff-test-records/tariff-test-records.component';
import { RfidAddressesComponent } from './components/rfid-addresses/rfid-addresses.component';
import { ChargingSessionsCsvGenComponent } from './components/charging-sessions-csv-gen/charging-sessions-csv-gen.component';
import { CompaniesCrudComponent } from './components/companies-crud/companies-crud.component';
import { CouponInterfaceComponent } from './components/coupon-interface/coupon-interface.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
// import { KiaFormComponent } from './components/kia-form/kia-form.component';
import { ProjectRapidComponent } from './components/project-rapid/project-rapid.component';

export const routes: Routes = [

  { path: 'api-invoice', component: ApiInvoiceComponent, 
    canActivate: [AuthGuard],
    data: { 
      role: 'ROLE_MODERATOR' 
    }
  },
  { path: 'api-invoice-site', component: ApiInvoiceSiteComponent, 
    canActivate: [AuthGuard],
    data: { 
      role: 'ROLE_MODERATOR' 
    }
  },
  { path: 'csv-invoice', component: CsvInvoiceComponent, 
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_MODERATOR'
    }
  },
  { path: 'tariffs', component: TariffsComponent, 
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_MODERATOR'
    }
  
  },
  { path: 'tariff-locations', component: TariffLocationsComponent, 
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_MODERATOR'
    }
  
  },
  { path: 'tariff-test-records', component: TariffTestRecordsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_MODERATOR'
    }
  },
  {
    path: 'charger-map', component: ChargersMapNewComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_SALES'
    }
  },
  { path: 'fleetcompanies', component: CompaniesCrudComponent, 
    canActivate: [AuthGuard], 
    data: {
      role: 'ROLE_MODERATOR'
    }
  },
  { path: 'coupons', component: CouponInterfaceComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_MODERATOR'
    }
  },
  { path: 'company-users-reg', component: RegistrationFormComponent, 
    canActivate: [AuthGuard], 
    data: {
      role: 'ROLE_MODERATOR'
    }
  },
  // { path: 'kia-user-form', component: KiaFormComponent, 
  //   canActivate: [AuthGuard], 
  //   data: {
  //     role: 'ROLE_KIA'
  //   }
  // },
  { path: 'chargingSessionsCsvGen', component: ChargingSessionsCsvGenComponent, 
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_MODERATOR'
    }  
  },
  {
    path: 'heatmapnew', component: GHeatMapComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_SALES'
    }
  },
  {
    path: 'project-rapid', component: ProjectRapidComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_SALES'
    }
  },
  {
    path: 'chart', component: SiteDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_SALES'
    }
  },
  { path: 'rfid-addresses', component: RfidAddressesComponent, 
    canActivate: [AuthGuard],
    data: {
      role: 'ROLE_MODERATOR'
    }  
  },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }