import { Component, OnInit } from '@angular/core';
import { TariffLocationsService } from '../../services/tariff-locations-service/tariff-locations-service.service';

@Component({
  selector: 'app-tariff-locations',
  templateUrl: './tariff-locations.component.html',
  styleUrls: ['./tariff-locations.component.scss']
})
export class TariffLocationsComponent implements OnInit {

  private tariffLocations: any;
  private newLocation: any;
  private previousRowColour: string;

  constructor(public tariffLocationData: TariffLocationsService) { }

  public ngOnInit(): void {
    this.generateTariffLocationsTable();
  }

  public generateTariffLocationsTable(): void {
    this.tariffLocationData.getAllTariffLocations().subscribe((data: any) => {
      
      this.tariffLocations = data[data.length-1].tariff;

      let table = document.getElementById('tariff-locations-table') as any;

      for(let i = 0; i < this.tariffLocations.length; i++) {
        let temp = table.insertRow().insertCell();
        temp.innerText = this.tariffLocations[i].title;
        temp.contentEditable = true;
      }
      let table2 = document.getElementById('tariff-locations-table-details') as any;
      
      for(let i = 0; i < this.tariffLocations.length; i++) {
        
        let row = table2.insertRow()
        if(i % 2 === 0) {
          row.style.backgroundColor = '#eee';
        } else {
          row.style.backgroundColor = '#d9d9d9';
        }

        row.style.color = '#222'

        for(let k = 0; k < this.tariffLocations[i].locations.length; k++) {
          let cell = row.insertCell();
          cell.contentEditable = true;
          cell.innerText = this.tariffLocations[i].locations[k];
          cell.style.margin = '3px';
          cell.style.padding = '5px';
          cell.style.cursor = 'cell';
        }
        
        let addLocations = document.getElementsByClassName('add_locations') as any;
        for(let loc = 0; loc < addLocations.length; loc++) {
          addLocations[loc].style.float = 'right';
          addLocations[loc].style.marginRight = '12px';
        }
        let removePre = row.insertCell();
        let plusIcon = document.createElement('i');
        plusIcon.className = ('fa fa-minus');
        removePre.appendChild(plusIcon);
        plusIcon.style.marginRight = '8px';
        plusIcon.style.float = 'right';
        plusIcon.style.cursor = 'pointer';
      }

      let menuCells = document.getElementById('tariff-locations-table').querySelectorAll('td') as any;

      for(let i = 0; i < menuCells.length; i++) {

        menuCells[i].style.color = '#333';
        if(i % 2 === 0) {
          menuCells[i].style.backgroundColor = '#eee';
        } else {
          menuCells[i].style.backgroundColor = '#dedede';
        }
        menuCells[i].style.padding = "5px";
      }

    });
  }

  public addLocation(e: any): void {
    e.path[1].querySelectorAll('td')[e.path[1].querySelectorAll('td').length-1].querySelector('i').remove();
    e.path[1].querySelectorAll('td')[e.path[1].querySelectorAll('td').length-1].contentEditable = true;
    let positionToAddLocation = e.path[0].closest('tr');    
    this.newLocation = positionToAddLocation.insertCell();
    this.newLocation.contentEditable = true;
    this.newLocation.style.minWidth = '69px';

    let plusIcon = document.createElement('i');
        plusIcon.className = ('fa fa-minus');
        plusIcon.id = ('minus');
        e.path[1].querySelectorAll('td')[e.path[1].querySelectorAll('td').length-1].appendChild(plusIcon);
        plusIcon.style.marginRight = '8px';
        plusIcon.style.float = 'right';
        plusIcon.style.cursor = 'pointer';
  }

  public highlightRowLocations(e: any): void {
    if(e.path[0].attributes[0] !== undefined && e.path[0].attributes[0].textContent === 'fa fa-minus') {
      let rowCells = e.path[2].querySelectorAll('td').length - 2;
      for(let i = 0; i < e.path[2].querySelectorAll('td').length; i++) {
        if(i === rowCells) {
          e.path[2].querySelectorAll('td')[i].remove();
          break;
        }
      }
    }

    let highlightedRow = e.path[1];

    if(highlightedRow.style.backgroundColor !== 'rgb(255, 255, 146)') {
      this.previousRowColour = highlightedRow.style.backgroundColor;
    }
    if(highlightedRow.style.backgroundColor !== 'rgb(255, 255, 146)') {
      highlightedRow.style.backgroundColor = 'rgb(255, 255, 146)';
    } else {
      highlightedRow.style.backgroundColor = this.previousRowColour;
    }
  }

  public highlightRowHeaders(e: any): void {
    let highlightedRow = e.path[0];

    if(highlightedRow.style.backgroundColor !== 'rgb(255, 255, 146)') {
      this.previousRowColour = highlightedRow.style.backgroundColor;
    }
    if(highlightedRow.style.backgroundColor !== 'rgb(255, 255, 146)') {
      highlightedRow.style.backgroundColor = 'rgb(255, 255, 146)';
    } else {
      highlightedRow.style.backgroundColor = this.previousRowColour;
    }
  }

  public saveNewLocations(): void {
    const tariffLocationsArr = [];
    const tableLocationsHeaders = document.getElementById('tariff-locations-table').querySelectorAll('td') as any;
    const tableLocationsRows = document.getElementById('tariff-locations-table-details').querySelectorAll('tr') as any;
    
    for(let i = 0; i < tableLocationsRows.length; i++) {
      let tr = []
      for(let j = 0; j < tableLocationsRows[i].childNodes.length; j++) {

        if(tableLocationsRows[i].childNodes[j].innerText !== "") {

            tr.push( (tableLocationsRows[i].childNodes[j].innerText) )
        }
      }
      tariffLocationsArr.push( 
          { 
            'Title': tableLocationsHeaders[i].textContent, 
            'Locations': tr
          }
      );
    }
    this.tariffLocationData.postTariffLocations(tariffLocationsArr);
  }

  
}
