
<div class='wrapper'>

    <div class='companyCrud-grid'>
    
        <div>
    
        </div>
        <div id='table-container'>
    

        </div>
        <div>
    
        </div>
        <div>
    
        </div>
    </div>

    <div id='warningModal'>Are you sure?
        <div>
            <span class='buttonContainer'>
                <button (click)='removeCompanyYes()' class='yesButton'>Yes</button>
            </span>
            <span class='buttonContainer'>
                <button (click)='removeCompanyNo()' class='noButton'>No</button>
            </span>
        </div>
    </div>

</div>
