import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { FormBuilder } from '@angular/forms';
import { HeatmapService } from 'src/app/services/heatmap-service/heatmap.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-map-sidebar',
  templateUrl: './map-sidebar.component.html',
  styleUrls: ['./map-sidebar.component.scss']
})
export class MapSidebarComponent implements OnInit {
  dateForm: FormGroup;
  private zipFiles: File = null;
  isLoading = false;
  chargeTypes: any = ['AC', 'DC'];
  months: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  years: any = [];
  sessionTypes: any = ['EasyGo', 'ESB'];
  easyGoTypes: any = ['Public', 'Private'];
  chargeTypeControl: FormControl = new FormControl(['AC'], []);
  sessionControl: FormControl = new FormControl(['EasyGo'], []);
  monthControl: FormControl = new FormControl([], []);
  yearControl: FormControl = new FormControl('', []);
  easyGoControl: FormControl = new FormControl(['Public', 'Private'], []);
  @Output() onDateApply = new EventEmitter<any>();
  energyAverage: number;
  chargingAverage: any;
  timeAverage: number;
  _selectedLocation: any;
  @Input() set selectedLocation(value) {
    this._selectedLocation = value;
    if(this.sessionControl.value.includes("EasyGo")) {
      this.applyFilter();
    }

  }

  countsData: any = {
    energyConsumption: 0,
    chargingTime: 0,
    noOfChargers: 0,
    noOfSessions: 0
  };

  chargePointOwnerType: any = ['EasyGo', 'Client', 'Multiple'];
  chargePointOwnerTypeControl: any = new FormControl(['EasyGo']);

  constructor(private fbBuilder: FormBuilder,
    public heatmap: HeatmapService, private _snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit(): void {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    this.monthControl.setValue([this.months[month - 1]]);
    this.yearControl.setValue(year);
    this.generateYears(2020);
    const heatMapFilters = localStorage.getItem("heatmap_filters");
    if(heatMapFilters) {
      const parsedFilter = JSON.parse(heatMapFilters);
      this.yearControl.setValue(parsedFilter.year);
      this.monthControl.setValue(parsedFilter.fullMonths);
      this.chargeTypeControl.setValue(parsedFilter.chargeType);
      this.sessionControl.setValue(parsedFilter.network);
      this.easyGoControl.setValue(parsedFilter.accessType);
      
    }
      
    this.getMarkerData();
  }

  generateYears(startYear) {
    const year = Number(new Date().getFullYear());
    for (let i = startYear; i <= year; i++) {
      this.years.push(i);
    }
  }

  public onFileChange(event): void {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) throw new Error('Cannot upload multiple files!');
    this.zipFiles = <File>event.target.files[0];
    this.isLoading = true;
    this.heatmap.postZipFile(this.zipFiles).subscribe((res) => {
      this.isLoading = false;
      this.openSnackBar("File uploaded successfully", "Close");
    }, (error) => {
      this.isLoading = false;
      this.openSnackBar("Something went wrong!", "Close")
    })
  }


  // getFormattedMonthAndYear(fieldName) {
  //   return `${this.dateForm.value[fieldName].toLocaleString('en', { month: 'short' })} ${this.dateForm.value[fieldName].getFullYear()}`
  // }

  generateData() {
    const months = this.monthControl.value.map((m) => `${m.slice(0, 3)}`);

    const data: any = {
      chargeType: this.chargeTypeControl.value,
      year: this.yearControl.value,
      months: months,
      accessType: this.easyGoControl.value,
      network: this.sessionControl.value,
      fullMonths: this.monthControl.value,
      chargePointOwnerType: this.chargePointOwnerTypeControl.value
    }

    if (this._selectedLocation) {
      data.locationId = this._selectedLocation.friendlyCode;
    }

    return data;
  }

  reset() {
    this.chargeTypeControl.setValue([]);
    this.yearControl.setValue("");
    this.sessionControl.setValue([]);
    this.monthControl.setValue([]);
    this._selectedLocation = null;
    this.onDateApply.emit(null);
  }

  getMarkerData() {
    const data = this.generateData();
    localStorage.setItem("heatmap_filters", JSON.stringify(data));
    this.onDateApply.emit(data);
  }

  applyFilter() {

    const data = this.generateData();

    if (this._selectedLocation) {
      this.getCounts(data)
    }
    // this.onDateApply.emit(data);

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  generateChartData(data): any {
    return {
      labels: Object.keys(data),
      dataset: Object.values(data)
    }
  }

  getCounts(data) {
    this.isLoading = true;


    this.heatmap.getCounts(data).subscribe((res) => {
      this.isLoading = false;
      this.countsData = res;
    }, (error) => {
      this.openSnackBar("Something Went Wrong!", "Close");
      this.isLoading = false;
     
    })
  }

  getChartData(data) {
    this.isLoading = true;
    this.energyAverage = 0;
    this.timeAverage = 0;
    this.chargingAverage = 0;
    this.heatmap.getChartData(data).subscribe((res: any) => {
      this.isLoading = false;
      if (res.energyConsumption) {
        const { labels, dataset } = this.generateChartData(res.energyConsumption);
        this.energyAverage = dataset.reduce((a, b) => a + b, 0) / dataset.length;
      }

      if (res.noOfChargingSessions) {
        const { labels, dataset } = this.generateChartData(res.noOfChargingSessions);
        this.chargingAverage = dataset.reduce((a, b) => a + b, 0);
      }

      if (res.chargingTime) {
        const { labels, dataset } = this.generateChartData(res.chargingTime);
        this.timeAverage = dataset.reduce((a, b) => a + b, 0) / dataset.length;
      }
    }, (error) => {
      this.openSnackBar("Something Went Wrong!", "Close");
      this.isLoading = false;
    })
  }

  navigateToChart() {
    const data = this.generateData();

    if (this._selectedLocation) {
      this.router.navigate(['/chart'], {
        queryParams: {
          locationId: data.locationId,
          year: data.year,
          months: this.monthControl.value.toString(),
          chargeType: data.chargeType,
          locationName: this._selectedLocation.friendlyName
        }
      })
    }

  }
}
