import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HeatmapService } from 'src/app/services/heatmap-service/heatmap.service';

@Component({
  selector: 'app-chargers-map-new',
  templateUrl: './chargers-map-new.component.html',
  styleUrls: ['./chargers-map-new.component.scss']
})
export class ChargersMapNewComponent implements OnInit {
  @ViewChild('gMap', { static: false }) gMap: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  options: google.maps.MapOptions = {
    center: { lat: 53.355603353851706, lng: -8.208030896545232 },
    zoom: 7,
    minZoom: 7,
    styles: []
  };
  heatmapOptions = { radius: 5 };
  mapMarkers: any = [];
  currentZoomLevel = 7;
  isLoading: boolean;
  currentFilter: any;
  selectedMarker: any;
  hoveredMarker: any = {};

  constructor(private heatMapService: HeatmapService,
    private _snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit(): void {
    const today = new Date();
    const month = today.toLocaleString('en', { month: 'short' });
    const year = today.getFullYear();
    // this.getLocations({});
    // this.getIonityLocations({});
  }


  generateMarkers(res, icon, isEir = false, isMYB = false, isWeevEPower = false) {
    const locations = res.locations;
    const mapMarkers = locations.map((location) => {
      location.lat = Number(location.latitude);
      location.lng = Number(location.longitude);
      if(isEir) {
        const customIcon = icon.replace("{eir-icon}", location.status);
        location.options = {
          icon: customIcon
        }
      } 
      else if(isMYB)
      {
        const customIcon = icon.replace("{myb-icon}", location.status);
        location.options = {
          icon: customIcon
        }
      }
      else if(isWeevEPower)
        {
          const customIcon = icon.replace("{weevEPower-icon}", location.status);
          location.options = {
            icon: customIcon
          }
        }
      else {
        if(location.friendlyCode.includes('EGO')) {
          const egoIcon = location.chargePointOwner === 'Client' ? 'easygo-client-marker' : 'easygo-marker';

          location.options = {
            icon: `../../../assets/${egoIcon}.png`
          }
        } else {
          location.options = {
            icon: icon
          }
        }
        
      }

      return location;
    });

    this.mapMarkers = [...this.mapMarkers, ...mapMarkers];
  }

  getMarkers(filter) {
    this.mapMarkers = [];
    this.currentFilter = filter;

    if(filter.charger.includes('EasyGo')) {
      this.getLocations(filter);
    } 

    if(filter.charger.includes('Ionity')) {
      this.getIonityLocations(filter);
    }
    if(filter.charger.includes('Eir')) {
      this.getEirLocations(filter);
    } 
    if(filter.charger.includes('MYB')) {
      this.getMYBLocations(filter);
    }
    if(filter.charger.includes('Weev/EPower')) {
      this.getWeevEPowerLocations(filter);
    }
    if(filter.charger.includes('Tesla')) {
      this.getTeslaLocations(filter);
    }

    if(filter.charger.includes("ESB")) {
      this.getESBLocations(filter);
    }
    
    if(!filter.charger.length) {
      this.mapMarkers = [];
      this.selectedMarker = null;
      this.currentFilter = null;
    }
  }

  getLocations(data) {
    this.isLoading = true;
    const params = {
      chargeType: data.chargeType,
      accessType: data.accessType,
      chargePointOwnerType: data.chargePointOwnerType
    }
    this.heatMapService.getLocationsWithFilter(params).subscribe((res: any) => {

      if (res.locations.length) {
        this.generateMarkers(res, "../../../assets/easygo-marker.png");
      }
      else {
        this.isLoading = false;
        this.openSnackBar("No data found in the selected period", "Close");
      }
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.openSnackBar("Something went wrong!", "Close")
    })
  }

  getIonityLocations(data) {
    this.isLoading = true;
    const params = {
      chargeType: data.chargeType,
      accessType: data.accessType,
      chargePointOwnerType: data.chargePointOwnerType
    }
    this.heatMapService.getIonityLocations(params).subscribe((res: any) => {
     
      if (res.locations.length) {
        this.generateMarkers(res, "../../../assets/ionity-marker.png");
      }
      else {
        this.openSnackBar("No data found in the selected period", "Close");
      }
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.openSnackBar("Something went wrong!", "Close")
    })
  }

  getTeslaLocations(data) {
    this.isLoading = true;
    const params = {
      chargeType: data.chargeType,
      accessType: data.accessType,
      chargePointOwnerType: data.chargePointOwnerType
    }
    this.heatMapService.getTeslaLocations(params).subscribe((res: any) => {
      if (res.locations.length) {
        this.generateMarkers(res, "../../../assets/tesla-marker.png");
      }
      else {
        this.openSnackBar("No data found in the selected period", "Close");
      }
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.openSnackBar("Something went wrong!", "Close")
    })
  }

  getESBLocations(data) {
    this.isLoading = true;
    const params = {
      chargeType: data.chargeType,
      accessType: data.accessType,
      chargePointOwnerType: data.chargePointOwnerType
    }
    this.heatMapService.getESBLocations(params).subscribe((res: any) => {
      if (res.locations.length) {
        this.generateMarkers(res, "../../../assets/esb-marker.png",);
      }
      else {
        this.openSnackBar("No data found in the selected period", "Close");
      }
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.openSnackBar("Something went wrong!", "Close")
    })
  }

  getEirLocations(data) {
    this.isLoading = true;
    this.heatMapService.getEirLocations(data.eirType.toString()).subscribe((res: any) => {

      if (res.locations.length) {
        this.generateMarkers(res, `../../../assets/eir/{eir-icon}.png`, true, false, false);
      }
      else {
        this.openSnackBar("No data found in the selected period", "Close");
      }
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.openSnackBar("Something went wrong!", "Close")
    })
  }
  getMYBLocations(data) {
    this.isLoading = true;
    this.heatMapService.getMYBLocations(data.mybType.toString()).subscribe((res: any) => {

      if (res.locations.length) {
        this.generateMarkers(res, `../../../assets/myb/{myb-icon}.png`, false, true, false);
      }
      else {
        this.openSnackBar("No data found in the selected period", "Close");
      }
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.openSnackBar("Something went wrong!", "Close")
    })
  }

  getWeevEPowerLocations(data) {
    this.isLoading = true;
    this.heatMapService.getWeevEPowerLocations(data.weevEPowerType.toString()).subscribe((res: any) => {

      if (res.locations.length) {
        this.generateMarkers(res, `../../../assets/weevEPower/{weevEPower-icon}.png`, false, false, true);
      }
      else {
        this.openSnackBar("No data found in the selected period", "Close");
      }
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.openSnackBar("Something went wrong!", "Close")
    })
  }
  zoomChange() {
    this.currentZoomLevel = this.gMap.getZoom();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }

  openInfoWindow(markerEle: MapMarker, marker) {
    this.hoveredMarker = {...marker};
    this.infoWindow.open(markerEle);
  }

  closeInfoWindow() {
    this.hoveredMarker = {};
    this.infoWindow.close();
  }

  onMarkClick(e, marker) {
    this.selectedMarker = { ...marker };
    // marker.locationId = "IE*EGO0526";
    // this.router.navigate(['/chart'], {
    //   queryParams: {
    //     locationId: marker.locationId,
    //     year: this.currentFilter.year,
    //     months: this.currentFilter.months.toString(),
    //     charger: 'DC'
    //   }
    // })
    // console.log("MARK CLIENT", marker);
  }


}
