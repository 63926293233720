import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { simpleReducer } from '../app/reducers/simpleReducer';

import { CsvInvoiceComponent } from './components/csv-invoice/csv-invoice.component';
import { ApiInvoiceComponent } from './components/api-invoice/api-invoice.component';
import { ApiInvoiceSiteComponent } from './components/api-invoice-site/api-invoice-site.component';
import { RfidAddressesComponent } from './components/rfid-addresses/rfid-addresses.component';
import { TariffsComponent } from './components/tariffs/tariffs.component';
import { TariffLocationsComponent } from './components/tariff-locations/tariff-locations.component';
import { TariffTestRecordsComponent } from './components/tariff-test-records/tariff-test-records.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { ChargingSessionsCsvGenComponent } from './components/charging-sessions-csv-gen/charging-sessions-csv-gen.component';
import { CompaniesCrudComponent } from './components/companies-crud/companies-crud.component';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideNavbarComponent } from './components/side-navbar/side-navbar.component';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { CommonModule } from '@angular/common';
import { NpnSliderModule } from 'npn-slider';
import { LoginComponent } from './components/login/login.component';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEnGB from '@angular/common/locales/en-GB';

import { authInterceptorProviders } from '../app/helper/auth-interceptor/auth-interceptor.service';
import { GHeatMapComponent } from './components/g-heat-map/g-heat-map.component';
import { GoogleMapsModule } from "@angular/google-maps";
import { MapSidebarComponent } from './components/map-sidebar/map-sidebar.component';
import {
  MatSelectModule
} from '@angular/material/select';
import {
  MatButtonModule
} from '@angular/material/button';
import {
  MatCheckboxModule
} from '@angular/material/checkbox';
import {
  MatDatepickerModule
} from '@angular/material/datepicker';
import {
  MatNativeDateModule
} from '@angular/material/core';
import {
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MatProgressBarModule
} from '@angular/material/progress-bar';
import {
  MatProgressSpinnerModule
} from '@angular/material/progress-spinner';
import {
  MatRadioModule
} from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SiteDetailsComponent } from './components/site-details/site-details.component';
import { MatCardModule } from '@angular/material/card';
import { CardContentComponent } from './components/card-content/card-content.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { ChargersMapNewComponent } from './components/chargers-map-new/chargers-map-new.component';
import { ChargerSidebarComponent } from './components/charger-sidebar/charger-sidebar.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TabNavigatorComponent } from './components/tab-navigator/tab-navigator.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
//import { KiaFormComponent } from './components/kia-form/kia-form.component';
import { ProjectRapidComponent } from './components/project-rapid/project-rapid.component';

// Register the 'en-GB' locale data.
registerLocaleData(localeEnGB);

@NgModule({
  declarations: [
    AppComponent,
    CsvInvoiceComponent,
    ApiInvoiceComponent,
    ApiInvoiceSiteComponent,
    RfidAddressesComponent,
    SideNavbarComponent,
    TariffsComponent,
    TariffLocationsComponent,
    TariffTestRecordsComponent,
    CompaniesCrudComponent,
    ChargingSessionsCsvGenComponent,
    RegistrationFormComponent,
    LoginComponent,
    GHeatMapComponent,
    MapSidebarComponent,
    SiteDetailsComponent,
    CardContentComponent,
    ChargersMapNewComponent,
    ChargerSidebarComponent,
    TabNavigatorComponent,
    //KiaFormComponent,
    ProjectRapidComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFileUploaderModule,
    NpnSliderModule,
    GoogleMapsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCardModule,
    MatAutocompleteModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatTabsModule,
    StoreModule.forRoot({ message: simpleReducer })
  ],
  exports: [
    FormsModule
  ],
  providers: [
    authInterceptorProviders,
    { provide: LOCALE_ID, useValue: 'en-GB' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
