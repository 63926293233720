import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthguardGuard implements CanActivate {

  constructor(private authenticationService: AuthService, private router: Router) {}

  canActivate( 
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): 
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | UrlTree
      | boolean {
      return this.authenticationService.user.roles.includes(route.data.role);
  }

  public isUserLoggedIn(): boolean {
    if(this.authenticationService.isLoggedIn()) {

      return true;
    }
    this.router.navigate(['/login']);
    // Send notification to user
    return false;
  }

  
}
