<mat-button-toggle-group
  (change)="onMapChange($event)"
  [(ngModel)]="mapType"
  name="mapType"
>
  <mat-button-toggle value="heatmapnew">Heatmap</mat-button-toggle>
  <mat-button-toggle appearance="primary" value="charger-map"
    >Charger Map</mat-button-toggle
  >
</mat-button-toggle-group>
