export class CompanyCrudHelper {

    createTableHeaders(table: any, tableRow: any) {
        // Client header cell
      const tdCompany = document.createElement('th');
      this.createHeaderCell(tdCompany, "Client");
      tableRow.appendChild(tdCompany);
      table.appendChild(tableRow);

      // UserPayerID header cell
      const tdUserPayerId = document.createElement('th');
      this.createHeaderCell(tdUserPayerId, "Payer ID");
      tableRow.appendChild(tdUserPayerId);
      table.appendChild(tableRow);

      // PO Number header cell
      const tdPONumber = document.createElement('th');
      this.createHeaderCell(tdPONumber, "PO Number");
      tableRow.appendChild(tdPONumber);
      table.appendChild(tableRow);

       // SAP Code header cell
       const tdSapCode = document.createElement('th');
       this.createHeaderCell(tdSapCode, "Sap Code");
       tableRow.appendChild(tdSapCode);
       table.appendChild(tableRow);

      // Name header cell
      const tdName = document.createElement('th');
      this.createHeaderCell(tdName, "Name");
      tableRow.appendChild(tdName);
      table.appendChild(tableRow);

      // Email header cell
      const tdEmail = document.createElement('th');
      this.createHeaderCell(tdEmail, "Email");
      tableRow.appendChild(tdEmail);
      table.appendChild(tableRow);

      // Driver Invoicing header cell
      const tdDriver = document.createElement('th');
      this.createHeaderCell(tdDriver, "Driver Invoicing");
      tableRow.appendChild(tdDriver);
      table.appendChild(tableRow);

      // Excel Report header cell
      const tdExcelReportOption = document.createElement('th');
      this.createHeaderCell(tdExcelReportOption, "Excel Report");
      tableRow.appendChild(tdExcelReportOption);
      table.appendChild(tableRow);

      // OceanUK Fleet header cell
      const tdOceanUk = document.createElement('th');
      this.createHeaderCell(tdOceanUk, "Ocean UK");
      tableRow.appendChild(tdOceanUk);
      table.appendChild(tableRow);
    }

    private createHeaderCell(element: any, heading: string) {
        element.style.marginLeft = '0.3rem';
        element.style.marginRight = '0.3rem';
        element.style.paddingLeft = '0.3rem';
        element.style.paddingRight = '0.3rem';
        element.append(heading);
    }

    createFleetRow(newFleetRow: any, company: any, counter: number) {
        // Client
        let companyRowCell = document.createElement('td');
        this.createTextRowCell(companyRowCell, company.name, counter);
        newFleetRow.appendChild(companyRowCell);

        // User Payer ID
        let userPayerIdRowCell = document.createElement('td');
        this.createTextRowCell(userPayerIdRowCell, company.clientId, counter);
        newFleetRow.appendChild(userPayerIdRowCell);

        // PO Number
        let poNumberRowCell = document.createElement('td');
        this.createTextRowCell(poNumberRowCell, company.poNumber, counter);
        newFleetRow.appendChild(poNumberRowCell);

        // SAP Code
        let sapCodeRowCell = document.createElement('td');
        this.createTextRowCell(sapCodeRowCell, company.sapCode, counter);
        newFleetRow.appendChild(sapCodeRowCell);

        // Contact Name
        let nameRowCell = document.createElement('td');
        this.createTextRowCell(nameRowCell, company.contactName, counter);
        newFleetRow.appendChild(nameRowCell);

        // Contact Email
        let emailRowCell = document.createElement('td');
        this.createTextRowCell(emailRowCell, company.email, counter);
        newFleetRow.appendChild(emailRowCell);

        // Driver Invoicing checkbox
        // To determine if fleet should receive invoice/reports based on number of drivers
        let driverInvoicingRowCell = document.createElement('td');
        this.createCheckboxRowCell(driverInvoicingRowCell, company.driverInvoicing, counter, "driverCheckbox");
        newFleetRow.appendChild(driverInvoicingRowCell);

        // Excel Report checkbox
        // To determine if fleet should receive excel or pdf reports
        let excelReportOptionRowCell = document.createElement('td');
        this.createCheckboxRowCell(excelReportOptionRowCell, company.excelReportOption, counter, "excelReportOptionCheckbox");
        newFleetRow.appendChild(excelReportOptionRowCell);

        // Ocean UK fleet checkbox
        // To determine if a fleet is on Ocean UK or Ireland
        let oceanUkRowCell = document.createElement('td');
        this.createCheckboxRowCell(oceanUkRowCell, company.oceanUK, counter, "oceanCheckbox");
        newFleetRow.appendChild(oceanUkRowCell);
    }

    private createTextRowCell(element: any, text: string, counter: number) {
        element.append(text);
        element.style.paddingRight = '.5rem';
        element.style.paddingLeft = '.5rem';
        element.style.marginRight = '1.3rem';
        element.style.marginLeft = '1.3rem';
        element.style.textAlign = 'center';
        element.style.color = '#343434';
        element.style.cursor = 'default';
        counter % 2 === 0 ? element.style.backgroundColor = 'white' : element.style.backgroundColor = 'rgb(238, 238, 238)';
    }

    private createCheckboxRowCell(element: any, checkboxTicked: boolean, counter: number, className: string) {
        let checkbox = document.createElement('input');
        checkbox.type = "checkbox";
        checkbox.name = "checkbox" + counter;
        checkbox.checked = checkboxTicked;
        checkbox.className = className;

        element.append(checkbox);
        this.createTextRowCell(element, "", counter);
    }

}