import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HeatmapService } from 'src/app/services/heatmap-service/heatmap.service';

@Component({
  selector: 'app-g-heat-map',
  templateUrl: './g-heat-map.component.html',
  styleUrls: ['./g-heat-map.component.scss']
})
export class GHeatMapComponent implements OnInit {
  @ViewChild('gMap', { static: false }) gMap: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  options: google.maps.MapOptions = {
    center: { lat: 53.355603353851706, lng: -8.208030896545232 },
    zoom: 7,
    minZoom: 7,
    styles: []
  };
  heatmapOptions = { radius: 5 };
  mapMarkers: any = [];
  currentZoomLevel = 7;
  isLoading: boolean = true;
  currentFilter: any;
  selectedMarker: any = null;
  hoveredMarker: any = {};
  heatMap: google.maps.visualization.HeatmapLayer;

  constructor(private heatMapService: HeatmapService,
    private _snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit(): void {
    const today = new Date();
    const month = today.toLocaleString('en', { month: 'short' });
    const year = today.getFullYear();
    // this.getEasyGodata([`${month} ${year}`]);
  }


  filterChange(data) {
    this.mapMarkers = [];
    this.selectedMarker = null;
    this.currentFilter = null;
    if(this.heatMap) {
      this.heatMap.setMap(null);
    }


    if (!data) {
      return;
    }

    // if (data.sessionType === 'EasyGo') {
    //   this.getEasyGodata(data);
    // } else if (data.sessionType === 'ESB') {
    //   this.getESBdata(data);
    // }

    this.getMapData(data)
  }

  generateMarker(res) {
    const markers = []
    for (let key in res) {
      let icon = '';
      const isEasyGo = key.includes('EGO') ? true : false;
     
      const currentData = res[key];
      currentData.lat = Number(currentData.latitude);
      currentData.lng = Number(currentData.longitude);
      currentData.friendlyCode = key;

      if(isEasyGo) {
        icon = currentData.chargePointOwner === 'Client' ? 'easygo-client-marker' : 'easygo-marker';
        currentData.options = {
          icon: `../../../assets/${icon}.png`
        }
      } else {
        icon = 'esb-marker'
        currentData.options = {
          icon: `../../../assets/${icon}.png`
        }
      }

     
      markers.push(currentData);
    }
    this.mapMarkers = markers;
  }

  getMapData(data) {

    this.currentFilter = { year: data.year, months: data.months };
    this.isLoading = true;

    this.heatMapService.getHeatmapSessions(data).subscribe((res: any) => {
      if (Object.keys(res).length) {
        // this.generateMarker(res, 'easygo-marker');
        this.generateMarker(res);

        this.initHeatMap();
        this.isLoading = false;
      }
      else {
        this.isLoading = false;
        this.openSnackBar("No data found in the selected period", "Close");
      }

    }, (error) => {
      this.isLoading = false;
      this.openSnackBar("Something went wrong!", "Close")
    })
  }

  getEasyGodata(data) {

    this.currentFilter = { year: data.year, months: data.months };
    this.isLoading = true;

    this.heatMapService.getLocationMarker(data).subscribe((res: any) => {
      if (Object.keys(res).length) {
        this.generateMarker(res);
        this.initHeatMap();
        this.isLoading = false;
      }
      else {
        this.isLoading = false;
        this.openSnackBar("No data found in the selected period", "Close");
      }

    }, (error) => {
      this.isLoading = false;
      this.openSnackBar("Something went wrong!", "Close")
    })
  }

  getESBdata(data) {

    this.currentFilter = { year: data.year, months: data.months };
    this.isLoading = true;
    this.heatMapService.getESBMarker(data).subscribe((res: any) => {
      if (Object.keys(res).length) {
        this.generateMarker(res);
        this.initHeatMap();
        this.isLoading = false;
      }
      else {
        this.isLoading = false;
        this.openSnackBar("No data found in the selected period", "Close");
      }

    }, (error) => {
      this.isLoading = false;
      this.openSnackBar("Something went wrong!", "Close")
    })
  }

  ngAfterViewInit() {
    this.initHeatMap();
  }

  initHeatMap() {
    if (this.heatMap) {
      this.heatMap.setMap(null);
    }

    if (this.mapMarkers.length) {
      this.heatMap = new google.maps.visualization.HeatmapLayer({
        radius: 12,
        opacity: .8,
        maxIntensity: 600,
        data: this.mapMarkers.map((d) => {
          return {location: new google.maps.LatLng(d.lat, d.lng), weight: d.noOfSessions};
        })
      });
      this.heatMap.setMap(this.gMap.googleMap);
    }

  }

  zoomChange() {
    this.currentZoomLevel = this.gMap.getZoom();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }

  openInfoWindow(markerEle: MapMarker, marker) {
    this.hoveredMarker = { ...marker };
    this.infoWindow.open(markerEle);
  }

  closeInfoWindow() {
    this.hoveredMarker = {};
    this.infoWindow.close();
  }

  onMarkClick(e, marker) {
    this.selectedMarker = { ...marker };
    // marker.locationId = "IE*EGO0526";
    // this.router.navigate(['/chart'], {
    //   queryParams: {
    //     locationId: marker.locationId,
    //     year: this.currentFilter.year,
    //     months: this.currentFilter.months.toString(),
    //     chargeType: 'DC'
    //   }
    // })
    // console.log("MARK CLIENT", marker);
  }

}
