
<div class="csv_downloader_wrapper">
    <div class="block" id="left-filler">
        
    </div>

    <div class="instructions">

        <div class="block">
            <div class="select">
                <label id="label_file">Step 1:  Select a CSV file to a generate PDF report from:</label>
            </div>
        </div>

        <div>
            <div style="float: left; padding-left:3px">
                <input type="file" (change)="onFileSelected($event)">
                
            </div>
        </div>

        <span id="red_line">
            <hr id="redLine">
        </span>

        <div>
            <div style="float: left; margin-top: 6px;">
                Step 2:  Generate PDF report from the selected CSV file:
            </div>
        </div>

        <div>
            <button id="upload_csv_button" type="button" (click)="onUpload()">Upload CSV File</button>
        </div>

        <div class="block"></div>

    </div>
    
</div>
<div class="returned_message">
    <span>{{this.waitingMessage}}</span>

    <span>{{this.returnedMessage}}</span>
</div>
