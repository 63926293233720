import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { ChargingSessionsQuery } from '../../interfaces/chargingSessionsQuery';
import { ChargingSessionsFleetQuery } from '../../interfaces/chargingSessionsFleetQuery';
import * as FileSaver from 'file-saver';

import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class InvoiceApiRetrieveService {

  url:string = 'https://www.easygo-dev.com:6502/api/' // for production in digitalocean
  //url:string = 'https://localhost:6502/api/'; // for development

  private singleCompaniesList: any;
  private fleetPlanCompaniesList:any;
  private freeAccessCompaniesList:any;
  private companyUserPayers:any;
  private badSessions:any;
  public pdfStatus: string;
  private zipStatus: string;
  public pdfFleetStatus: string;
  public dateTo: string;
  public dateFrom: string;
  private fleetResponse: any;

  selectedCompany: any; // TODO later add an array and counter
  showWaitingSpinner = false;
  showFleetWaitingSpinner = false;
  showFleetsWaitingSpinner = false;

  // private flaggedSessions = [];
  // public uniqueFlaggedSessions: Array<any> = [];

  constructor(private http: HttpClient) { }


  public getSingleCompaniesList(): any {
    try {
      this.singleCompaniesList =
      this.http.get<any>(`${this.url}singleCompaniesList`);
      return this.singleCompaniesList;
    } catch (error) {
      console.error(error);
      return EMPTY;
    }
  }

  public getFleetPlanCompaniesList(): any {
    try {
      this.fleetPlanCompaniesList =
      this.http.get<any>(`${this.url}fleetPlanCompaniesList`);
      return this.fleetPlanCompaniesList;
    } catch (error) {
      console.error(error());
      return EMPTY;
    }
  }

  public getFreeAccessCompaniesList(): any {
    try {
      this.freeAccessCompaniesList =
      this.http.get<any>(`${this.url}freeAccessCompaniesList`);
      return this.freeAccessCompaniesList;
    } catch (error) {
      console.error(error);
      return EMPTY;
    }
  }

  public getCompanyUserPayers() {
    try {
      this.companyUserPayers = this.http.get<any>(`${this.url}companyUserPayers`);
      return this.companyUserPayers;
    } catch(error) {
      console.error(error);
      return EMPTY;
    }
  }

  public getBadSessions() {
    try {
      this.badSessions = this.http.get<any>('https://www.easygo-dev.com:8081/v1/naughtySessions');
      return this.badSessions;
    } catch(error) {
      console.error(error)
      return EMPTY;
    }

  }

  public sendApiQuery(queryObject: ChargingSessionsQuery): any {

    this.showWaitingSpinner = true;
    try {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/pdf')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE,PUT');
      const body = JSON.stringify(queryObject);
      const req = this.http.post(`${this.url}getApiQuery`, body, { headers: headers, responseType: 'blob' as 'json'} );
      req.subscribe( response => {
            FileSaver.saveAs(response, `${this.selectedCompany}_${queryObject.chargingFrom.substring(0, 10)}_to_${queryObject.chargingTo.substring(0, 10)}_Charging_Sessions`);
            this.showWaitingSpinner = false;
            const momento = moment().format('MMMM Do YYYY, h:mm:ss a');
            this.pdfStatus = `Successfully generated and downloaded ${this.selectedCompany}_${queryObject.chargingFrom.substring(0, 10)}_to_${queryObject.chargingTo.substring(0, 10)}_Charging_Sessions on ${momento}`;
          });
    } catch(error) {
      console.error(error);
      this.pdfStatus = 'Sorry, something went wrong.  Please check the logs of the service.'
      return EMPTY;
    }
  }

  public sendFleetsApiQuery(selectedCompanies: ChargingSessionsFleetQuery[], chargingFrom: string, chargingTo: string) {
    this.showFleetsWaitingSpinner = true;
    console.log(this.showFleetsWaitingSpinner);
    let uniqueFlaggedSessions = [];

    try {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/zip')
        .set('Content-Type', 'application/json')
        .set('Allow-Control-Allow-Origin', '*')
        .set('Access-Control-Allow-Methods', 'POST')
        let queryParams = {
          selectedCompanies : selectedCompanies,
          chargingFrom : chargingFrom,
          chargingTo : chargingTo
        };
        const body = JSON.stringify(queryParams);
        const req = this.http.post(`${this.url}getMultipleFleetSubReports`, body, {headers: headers, responseType: 'blob' as 'arraybuffer'});
        req.subscribe( response => {
          console.log(response);
          
          const date = moment().format('LLLL');;
          const momento = moment().format('L').replace('/', '_');
          
          const fileName = `fleetSubscribersPdfs_${momento}.zip`;
          FileSaver.saveAs(response, fileName )
          this.zipStatus = `Successfully generated and downloaded ${fileName} on ${date}`;
          
          this.showFleetsWaitingSpinner = false;
        });
        
    } catch(error) {
      this.zipStatus = 'Sorry, something went wrong.  Please check the logs of the service at.'

      console.error(error);

      return EMPTY;
    }
  }
  
  public sendFleetApiQuery(queryObject: ChargingSessionsFleetQuery): any {
    this.showFleetWaitingSpinner = true;
    try {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/pdf')
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE,PUT');
      const body = JSON.stringify(queryObject);
      const req = this.http.post(`${this.url}getFleetApiQuery/`, body, { headers: headers, responseType: 'blob' as 'json'});

      req.subscribe( response => {
        this.fleetResponse = response;
        if(this.fleetResponse.size === 0) {
          this.pdfFleetStatus = `Failed to generate a pdf file as no charging sessions have been found for ${queryObject.company} from ${queryObject.chargingFrom.substring(0, 10)} to ${queryObject.chargingTo.substring(0, 10)}`;
        } else {

          FileSaver.saveAs(response, `${queryObject.company.replace('_', '').replace(',', '_').replace(".", "_")}_${queryObject.chargingFrom.substring(0, 10)}_to_${queryObject.chargingTo.substring(0, 10)}_Charging_Sessions`);
          const momento = moment().format('MMMM Do YYYY, h:mm:ss a');
          this.pdfFleetStatus = `Successfully generated and downloaded ${queryObject.company}_${queryObject.chargingFrom.substring(0, 10)}_to_${queryObject.chargingTo.substring(0, 10)}_Charging_Sessions on ${momento}`; 
        }

      });
    } catch(error) {
      console.error(error);
      this.pdfFleetStatus = 'Sorry, something went wrong.  Please check the logs of the service.'
      return EMPTY;
    }
  }


}
