
<div>
    <div id="tariffTableWrapper" (click)="highlightRow($event)">
        <table id="tariffTable">
            <th id="tariff_names" (click)="sortByTariffName($event)">Tariff Name <i class="fa fa-angle-down" aria-hidden="true"></i></th>
            <th>Contract Type</th>
            <th>Access Fee(€)</th>
            <th>Access Fee Valid After(mins)</th>
            <th>Charge Time Fee(€)</th>
            <th>Chargetime Fee Valid After(mins)</th>
            <th>Consumed Energy Fee(€)</th>
            <th>Consumed Energy Fee After(mins)</th>
            <th>Parking fee(€)</th>
            <th>Parking fee valid after(mins)</th>
            <th>Overstay Fee(€)</th>
            <th>Overstay Fee Valid After(hrs)</th>
            <th>Valid From</th>
            <th>Valid To</th>
            <th>Tested</th>
        </table>
    </div>
    <div id="tableButtons">
        <span class="tableButtons">
            <button id="add" (click)="addTableRow()">add tariff</button>
        </span>
        <span class="tableButtons">
            <button class="remove" (click)="removeTableRow()">delete bottom row</button>
        </span>
        <span>
            <button id="submit" (click)="updateTariffData()">save changes</button>
        </span>
    </div>
    <div class="savedMessage">
        {{this.tariffData.savedConfirm}}
    </div>
</div>
