<div class="table-grid">
    <div>

    </div>
    <div>
        Generate addresses for key fob postage ->
    </div>
    
    <div>
        <button (click)="getRfidAddressesPDF()">Get RFID Addresses PDF</button>

    </div>
    
    <div>
        
    </div>
    
    
</div>

<div id='returnedMessage'>
    {{this.rfidAddressesService.returnedMessage}}
</div>