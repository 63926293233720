import { Component } from '@angular/core';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { RegistrationFormService } from 'src/app/services/registration_form_service/registration-form.service';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent {

  excelData: any;
  excelData2: any;
  usersNumber: number = 0;
  createButtonPressed: boolean = false;
  
  constructor(public registerFormService: RegistrationFormService) { }

  
  onFileChange(e : any) {
    const target : DataTransfer = <DataTransfer>(e.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files!');
    const reader: FileReader = new FileReader();

    reader.onload = (event: any) => {
      const bStr: string = event.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bStr, { type: 'binary'});
      const wsname = wb.SheetNames[1];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      this.excelData = (XLSX.utils.sheet_to_json(ws, {header: 1})) as any;

      const wsname2: string = wb.SheetNames[0];
      const ws2: XLSX.WorkSheet = wb.Sheets[wsname2];
      this.excelData2 = (XLSX.utils.sheet_to_json(ws2, {header: 1}))

      const companyName = this.excelData2[9];
      if(companyName[2] === undefined) {
        document.getElementById('companyNameWarning').style.display = 'block';
        document.getElementById('companyNameWarning').style.visibility = 'visible';

      } else {

        if(document.getElementById('companyNameWarning').style.display === 'block') {
          document.getElementById('companyNameWarning').style.display = 'none';
        }
        if(document.getElementById('companyNameWarning').style.visibility === 'visible') {
          document.getElementById('companyNameWarning').style.visibility = 'hidden';
        }

        let companyN: string = companyName[2];
        if(companyN !== undefined) {
          companyN = companyN.trim();
        }
  
        const companyNumber = this.excelData2[21];
        const companyNum: string = companyNumber[2];
  
        let fleetSubUsers = [];
  
        for (let i = 11; i < this.excelData.length; i++) {
  
          if(this.excelData[i][2] === undefined) {
            continue;
          } else {
            fleetSubUsers.push(this.excelData[i]);
          }
        }
  
        for (let i = 0; i < fleetSubUsers.length; i++) {
          if(fleetSubUsers[i][6] === false && fleetSubUsers[i][7] === false) {
            continue;
          } else {
            fleetSubUsers[i] = fleetSubUsers[i].splice(1, fleetSubUsers[i].length);
            fleetSubUsers[i][2] = fleetSubUsers[i][2].toString();
          }
        }
  
  
        const companyDetails = {
          name: companyN,
          companyNumber: companyNum,
          countryId: 140
        }
        this.populateTable(fleetSubUsers, companyDetails);
      }
      
    };

    reader.readAsBinaryString(target.files[0]);
  }

  populateTable(fleetUsers: [][], companyDetails: any) : void {

    // console.log(fleetUsers);

    for(let row = 0; row < fleetUsers.length; row++) {
      let tempNameArr = [];
      for(let col = 0; col < fleetUsers[row].length; col++) {
        let firstName = (fleetUsers[row][col] as string).split(' ')[0];
        let lastName = '';
        for(let i = 1; i < ((fleetUsers[row][col]) as string).split(' ').length; i++ ) {
          lastName += (fleetUsers[row][col] as string).split(' ')[i] + ' ';
        }
        lastName = lastName.trim();

        // Double first name checks
        if(lastName.startsWith('Jane')) {
          lastName = lastName.split('Jane')[1];
          firstName = firstName + ' ' + 'Jane';
        } else if(lastName.startsWith('John')) {
          lastName = lastName.split('John')[1];
          firstName = firstName + ' ' + 'John';
        } else if(lastName.startsWith('Jack')) {
          lastName = lastName.split('Jack')[1];
          firstName = firstName + ' ' + 'Jack';
        } else if(lastName.startsWith('Jon')) {
          lastName = lastName.split('Jon')[1];
          firstName = firstName + ' ' + 'Jon';
        } else if(lastName.startsWith('Jonny')) {
          lastName = lastName.split('Jonny')[1];
          firstName = firstName + ' ' + 'Jonny';
        } else if(lastName.startsWith('Johny')) {
          lastName = lastName.split('Johny')[1];
          firstName = firstName + ' ' + 'Johny';
        } else if(lastName.startsWith('Paul')) {
          lastName = lastName.split('Paul')[1];
          firstName = firstName + ' ' + 'Paul';
        } else if(lastName.startsWith('James')) {
          lastName = lastName.split('James')[1];
          firstName = firstName + ' ' + 'James';
        } else if(lastName.startsWith('Jamie')) {
          lastName = lastName.split('Jamie')[1];
          firstName = firstName + ' ' + 'Jamie';
        } else if(lastName.startsWith('Jimmy')) {
          lastName = lastName.split('Jimmy')[1];
          firstName = firstName + ' ' + 'Jimmy';
        } else if(lastName.startsWith('Jim')) {
          lastName = lastName.split('Jim')[1];
          firstName = firstName + ' ' + 'Jim';
        } else if(lastName.startsWith('Joe')) {
          lastName = lastName.split('Joe')[1];
          firstName = firstName + ' ' + 'Joe';
        } else if(lastName.startsWith('Joseph')) {
          lastName = lastName.split('Joseph')[1];
          firstName = firstName + ' ' + 'Joseph';
        } else if(lastName.startsWith('Mike')) {
          lastName = lastName.split('Mike')[1];
          firstName = firstName + ' ' + 'Mike';
        } else if(lastName.startsWith('Mic')) {
          lastName = lastName.split('Mic')[1];
          firstName = firstName + ' ' + 'Mic';
        } else if(lastName.startsWith('Mick')) {
          lastName = lastName.split('Mick')[1];
          firstName = firstName + ' ' + 'Mick';
        } else if(lastName.startsWith('Mark')) {
          lastName = lastName.split('Mark')[1];
          firstName = firstName + ' ' + 'Mark';
        } else if(lastName.startsWith('Markus')) {
          lastName = lastName.split('Markus')[1];
          firstName = firstName + ' ' + 'Markus';
        } else if(lastName.startsWith('Peter')) {
          lastName = lastName.split('Peter')[1];
          firstName = firstName + ' ' + 'Peter';
        } else if(lastName.startsWith('Pete')) {
          lastName = lastName.split('Pete')[1];
          firstName = firstName + ' ' + 'Pete';
        } else if(lastName.startsWith('Dave')) {
          lastName = lastName.split('Dave')[1];
          firstName = firstName + ' ' + 'Dave';
        } else if(lastName.startsWith('Brian')) {
          lastName = lastName.split('Brian')[1];
          firstName = firstName + ' ' + 'Brian';
        } else if(lastName.startsWith('Michael')) {
          lastName = lastName.split('Michael')[1];
          firstName = firstName + ' ' + 'Michael';
        } else if(lastName.startsWith('David')) {
          lastName = lastName.split('David')[1];
          firstName = firstName + ' ' + 'David';
        } else if(lastName.startsWith('Patrick')) {
          lastName = lastName.split('Patrick')[1];
          firstName = firstName + ' ' + 'Patrick';
        } else if(lastName.startsWith('Pat')) {
          lastName = lastName.split('Pat')[1];
          firstName = firstName + ' ' + 'Pat';
        } else if(lastName.startsWith('Paddy')) {
          lastName = lastName.split('Paddy')[1];
          firstName = firstName + ' ' + 'Paddy';
        }

        else if(lastName.startsWith('Grace')) {
          lastName = lastName.split('Grace')[1];
          firstName = firstName + ' ' + 'Grace';
        } else if(lastName.startsWith('Fiadh')) {
          lastName = lastName.split('Fiadh')[1];
          firstName = firstName + ' ' + 'Fiadh';
        } else if(lastName.startsWith('Emily')) {
          lastName = lastName.split('Emily')[1];
          firstName = firstName + ' ' + 'Emily';
        } else if(lastName.startsWith('Sarah')) {
          lastName = lastName.split('Sarah')[1];
          firstName = firstName + ' ' + 'Sarah';
        } else if(lastName.startsWith('Sara')) {
          lastName = lastName.split('Sara')[1];
          firstName = firstName + ' ' + 'Sara';
        } else if(lastName.startsWith('Ann')) {
          lastName = lastName.split('Ann')[1];
          firstName = firstName + ' ' + 'Ann';
        } else if(lastName.startsWith('Anne')) {
          lastName = lastName.split('Anne')[1];
          firstName = firstName + ' ' + 'Anne';
        } else if(lastName.startsWith('Lily')) {
          lastName = lastName.split('Lily')[1];
          firstName = firstName + ' ' + 'Lily';
        } else if(lastName.startsWith('Ellie')) {
          lastName = lastName.split('Ellie')[1];
          firstName = firstName + ' ' + 'Ellie';
        } else if(lastName.startsWith('Molly')) {
          lastName = lastName.split('Molly')[1];
          firstName = firstName + ' ' + 'Molly';
        } else if(lastName.startsWith('Katie')) {
          lastName = lastName.split('Katie')[1];
          firstName = firstName + ' ' + 'Katie';
        } else if(lastName.startsWith('Kate')) {
          lastName = lastName.split('Kate')[1];
          firstName = firstName + ' ' + 'Kate';
        } else if(lastName.startsWith('Alice')) {
          lastName = lastName.split('Alice')[1];
          firstName = firstName + ' ' + 'Alice';
        } else if(lastName.startsWith('Leah')) {
          lastName = lastName.split('Leah')[1];
          firstName = firstName + ' ' + 'Leah';
        } else if(lastName.startsWith('Ciara')) {
          lastName = lastName.split('Ciara')[1];
          firstName = firstName + ' ' + 'Ciara';
        }

        tempNameArr.push(firstName);
        tempNameArr.push(lastName);

        break;
      }
      fleetUsers[row] = (tempNameArr as any).concat(fleetUsers[row].splice(1, fleetUsers[row].length));
    }

    const tableContainer = document.getElementById('tableContainer');

    let table = document.createElement('table');
    table.style.backgroundColor = 'rgb(177, 177, 177)';
    table.style.opacity = '0';
    
    setTimeout(() => {
      table.style.transition = 'opacity 1s';
      table.style.opacity = '1';

    }, 10)

    tableContainer.appendChild(table);

    let firstNameHeaderCell = document.createElement('th');
    firstNameHeaderCell.style.backgroundColor = 'rgb(168, 202, 153)';
    firstNameHeaderCell.style.color = 'rgb(52, 52, 52)';
    firstNameHeaderCell.style.marginLeft = '0.3rem';
    firstNameHeaderCell.style.marginRight = '0.3rem';
    firstNameHeaderCell.style.paddingLeft = '0.3rem';
    firstNameHeaderCell.style.paddingRight = '0.3rem';
    firstNameHeaderCell.textContent = 'First Name';


    let lastNameHeaderCell = document.createElement('th');
    lastNameHeaderCell.textContent = 'Last Name';
    lastNameHeaderCell.style.backgroundColor = 'rgb(168, 202, 153)';
    lastNameHeaderCell.style.color = 'rgb(52, 52, 52)';
    lastNameHeaderCell.style.marginLeft = '0.3rem';
    lastNameHeaderCell.style.marginRight = '0.3rem';
    lastNameHeaderCell.style.paddingLeft = '0.3rem';
    lastNameHeaderCell.style.paddingRight = '0.3rem';

    let emailHeaderCell = document.createElement('th')
    emailHeaderCell.textContent = 'Email';
    emailHeaderCell.style.backgroundColor = 'rgb(168, 202, 153)';
    emailHeaderCell.style.color = 'rgb(52, 52, 52)';
    emailHeaderCell.style.marginLeft = '0.3rem';
    emailHeaderCell.style.marginRight = '0.3rem';
    emailHeaderCell.style.paddingLeft = '0.3rem';
    emailHeaderCell.style.paddingRight = '0.3rem';

    let phoneNumberHeaderCell = document.createElement('th');
    phoneNumberHeaderCell.textContent = 'Phone Number';
    phoneNumberHeaderCell.style.backgroundColor = 'rgb(168, 202, 153)';
    phoneNumberHeaderCell.style.color = 'rgb(52, 52, 52)';
    phoneNumberHeaderCell.style.marginLeft = '0.3rem';
    phoneNumberHeaderCell.style.marginRight = '0.3rem';
    phoneNumberHeaderCell.style.paddingLeft = '0.3rem';
    phoneNumberHeaderCell.style.paddingRight = '0.3rem';

    let countryHeaderCell = document.createElement('th');
    countryHeaderCell.textContent = 'County';
    countryHeaderCell.style.backgroundColor = 'rgb(168, 202, 153)';
    countryHeaderCell.style.color = 'rgb(52, 52, 52)';
    countryHeaderCell.style.marginLeft = '0.3rem';
    countryHeaderCell.style.marginRight = '0.3rem';
    countryHeaderCell.style.paddingLeft = '0.3rem';
    countryHeaderCell.style.paddingRight = '0.3rem';

    let districtHeaderCell = document.createElement('th');
    districtHeaderCell.textContent = 'District';
    districtHeaderCell.style.backgroundColor = 'rgb(168, 202, 153)';
    districtHeaderCell.style.color = 'rgb(52, 52, 52)';
    districtHeaderCell.style.marginLeft = '0.3rem';
    districtHeaderCell.style.marginRight = '0.3rem';
    districtHeaderCell.style.paddingLeft = '0.3rem';
    districtHeaderCell.style.paddingRight = '0.3rem';

    let postalCodeHeaderCell = document.createElement('th');
    postalCodeHeaderCell.textContent = 'Postal Code';
    postalCodeHeaderCell.style.backgroundColor = 'rgb(168, 202, 153)';
    postalCodeHeaderCell.style.color = 'rgb(52, 52, 52)';
    postalCodeHeaderCell.style.marginLeft = '0.3rem';
    postalCodeHeaderCell.style.marginRight = '0.3rem';
    postalCodeHeaderCell.style.paddingLeft = '0.3rem';
    postalCodeHeaderCell.style.paddingRight = '0.3rem';

    let streetNameHeaderCell = document.createElement('th');
    streetNameHeaderCell.textContent = 'Street Name';
    streetNameHeaderCell.style.backgroundColor = 'rgb(168, 202, 153)';
    streetNameHeaderCell.style.color = 'rgb(52, 52, 52)';
    streetNameHeaderCell.style.marginLeft = '0.3rem';
    streetNameHeaderCell.style.marginRight = '0.3rem';
    streetNameHeaderCell.style.paddingLeft = '0.3rem';
    streetNameHeaderCell.style.paddingRight = '0.3rem';

    let fobHeaderCell = document.createElement('th');
    fobHeaderCell.textContent = 'Fob';
    fobHeaderCell.style.backgroundColor = 'rgb(168, 202, 153)';
    fobHeaderCell.style.color = 'rgb(52, 52, 52)';
    fobHeaderCell.style.marginLeft = '0.3rem';
    fobHeaderCell.style.marginRight = '0.3rem';
    fobHeaderCell.style.paddingLeft = '0.3rem';
    fobHeaderCell.style.paddingRight = '0.3rem';

    let appAccess = document.createElement('th');
    appAccess.textContent = 'App Access';
    appAccess.style.backgroundColor = 'rgb(168, 202, 153)';
    appAccess.style.color = 'rgb(52, 52, 52)';
    appAccess.style.marginLeft = '0.3rem';
    appAccess.style.marginRight = '0.3rem';
    appAccess.style.paddingLeft = '0.3rem';
    appAccess.style.paddingRight = '0.3rem';

    table.appendChild(firstNameHeaderCell);
    table.appendChild(lastNameHeaderCell);
    table.appendChild(emailHeaderCell);
    table.appendChild(phoneNumberHeaderCell);
    table.appendChild(countryHeaderCell);
    table.appendChild(districtHeaderCell);
    table.appendChild(postalCodeHeaderCell);
    table.appendChild(streetNameHeaderCell);
    table.appendChild(fobHeaderCell);
    table.appendChild(appAccess);

    for(let row = 0; row < fleetUsers.length; row++) {
      let newRow = document.createElement('tr');
      newRow.contentEditable = 'true';
      newRow.style.cursor = 'text';
      newRow.style.color = 'rgb(52, 52, 52)';
      let defaultBackgroundColour = '';
      
      if(row % 2 === 0) {
        newRow.style.backgroundColor = 'white';
        defaultBackgroundColour = 'white';
      } else {
        newRow.style.backgroundColor = 'rgb(238, 238, 238)';
        defaultBackgroundColour = 'rgb(238, 238, 238)';
      }
      
      newRow.onmouseenter = () => {
        newRow.style.backgroundColor = '#ffffd4';
      }

      newRow.onmouseleave = () => {
        newRow.style.backgroundColor = defaultBackgroundColour;
      }

      for(let cell = 0; cell < fleetUsers[row].length; cell++) {
        let newCell = document.createElement('td');
        newCell.style.paddingLeft = '0.3rem';
        newCell.style.paddingRight = '0.3rem';
        let currentRow = fleetUsers[row] as any;
        let cellText = currentRow[cell];
        newCell.append(cellText);
        if(cell === 8 || cell === 9) {
          const oldBgColourOfCell = newCell.style.backgroundColor;

          newCell.onmouseenter = (event) => {
            (event.target as HTMLElement).style.cursor = 'pointer';
            (event.target as HTMLElement).style.backgroundColor = '#ff6d35';
          }
          newCell.onmouseleave = (event) => {
            (event.target as HTMLElement).style.backgroundColor = oldBgColourOfCell;
          }
          newCell.onclick = (event) => {
            (event.target as HTMLElement).style.backgroundColor = '#ffc3ad';
            if( (event.target as HTMLElement).textContent == 'false') {
              newCell.textContent = 'true';
            } else {
              newCell.textContent = 'false';
            }
          }
        }
        newRow.appendChild(newCell);
      }
      table.appendChild(newRow);
    }

    const users: any = [];
    let tableRows = document.getElementById('tableContainer').querySelectorAll('tr');

    for(let i = 0; i < tableRows.length; i++) {
      let user = {
        FirstName: tableRows[i].childNodes[0].textContent.trim(),
        LastName: tableRows[i].childNodes[1].textContent.trim(),
        CompanyName: companyDetails.name.trim(),
        IsCompany: true,
        CompanyRegistrationNumber : null,
        Nationality: null,
        Email: tableRows[i].childNodes[2].textContent.trim(),
        Address: {
          Country: {
            Id: 140
          },
          CityName: tableRows[i].childNodes[4].textContent.trim(),
          CityDistrictName : tableRows[i].childNodes[5].textContent.trim(),
          PostNumber: tableRows[i].childNodes[6].textContent.trim(),
          StreetName: tableRows[i].childNodes[7].textContent.trim(),
          HouseNumber: '',
          RoomNumber: ''
        },
        LocalEmsp: {
          Id: null
        },
        ExternalCode: null,
        ExternalCodeSecondary: null,
        TelephoneNumber: null,
        GSM: tableRows[i].childNodes[3].textContent.trim().replace(' ', ''),
        TaxNumber: null,
        NationalIdentityNumber: null,
        UiCulture: {
          Id: 'en-ie'
        },
        CommunicationChannel: {
          Id: 4
        },
        DateOfBirth: '',
        DefaultTimeZone: 0,
        DaylightSavingEnabled: moment().isDST()
      }
      users.push(user);
    }
    this.createUsers(users);
  }

  createUsers( users: []): void {
    let tableCell = document.getElementById('tableContainer');
    let buttonContainer = document.createElement('div');
    let createUsersButton = document.createElement('button');
    createUsersButton.textContent = 'Create New Users';
    createUsersButton.style.marginTop = '1.3rem';
    createUsersButton.style.opacity = '0';
    createUsersButton.style.backgroundColor = 'rgb(31, 76, 255)';
    createUsersButton.style.color = 'white';

    createUsersButton.onmouseenter = (event) => {
      (event.target as HTMLElement).style.backgroundColor = 'rgb(217, 225, 255)';
      (event.target as HTMLElement).style.color = '#555';
    }
    createUsersButton.onmouseleave = (event) => {
      (event.target as HTMLElement).style.backgroundColor = 'rgb(31, 76, 255)';
      (event.target as HTMLElement).style.color = 'white';
    }

    setTimeout(() => {
      createUsersButton.style.opacity = '1';
      createUsersButton.style.transition = '.5s';
    }, 10);

    buttonContainer.appendChild(createUsersButton);
    tableCell.appendChild(buttonContainer);

    createUsersButton.onclick = () => {
      this.createButtonPressed = true;
      for(let i = 0; i < users.length; i++ ) {
        this.registerFormService.postNewUsers(users[i]);
      }
    }
    this.usersNumber = users.length;
  }

}
