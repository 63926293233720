<div id='locationsGrid'>
    <div>
        <div>
            Select a location
        </div>
        <table>
            <tr>
                <th>
                    Friendly Code
                </th>
                <th>
                    Friendly Name
                </th>
            </tr>
            <tr (click)='getLocation($event)' *ngFor='let location of locationsArr'>
                <td >
                    {{location.FriendlyCode}}
                </td>
                <td>
                    {{location.FriendlyName}}

                </td>
            </tr>
        </table>

    </div>
    <div id='submit'>
        Generate CSV file 
        <div id='datePicker'>
            <div class="dateLabel">
                <label for="fromDate">Charging From:</label>
            </div>
            <div class="input-group date">
                <input type="datetime-local" (blur)='checkDates()' autocomplete="off" id="chargingFrom"/>
            </div>
            <div class="dateLabel">
                <label for="fromDate">Charging To:</label>
            </div>
            <div class="input-group date">
                <input type="datetime-local" (blur)='checkDates()' autocomplete="off" id="chargingTo"/>
            </div>
        </div>

        <div class='selectedContainer'>

        </div>

        <div id='submitButton'>
            <button id='submitBut' (click)='createCsvFiles($event)'>
                Generate CSV files
            </button>
        </div>
        <div id='errorMessages'>
            <div *ngIf='this.dateFromValue === null || this.dateFromValue === ""'>
                Please select a 'from date' from the date picker
            </div>
            <div *ngIf='this.dateToValue === null || this.dateToValue === ""'>
                Please select a 'to date' from the date picker
            </div>

        </div>
    </div>
</div>
