<div class="dot">
    <img id="logo" src="./../../../assets/EasyGo-nolettering-logo.svg" alt="easy go">
</div>
<div id='nameColour' class='nameContainer'>
    {{name}}
</div>
<div id='roleBgColour' class='roleContainer'>
    {{userRole}}
</div>

<div>
    <div class="menu-grid">
        
        <!-- Invoices -->
        <span *ngIf='userRole === "Admin"'>
            <div class="dropdown-menus">
                <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                <button id="dropdownButton" (click)="invoicesDropdown()">
                    Invoices
                    <span *ngIf="!showInvoiceDropdown" >
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                    <span *ngIf="showInvoiceDropdown">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </button>
    
                <div *ngIf="showInvoiceDropdown" class="submenu">
                    <div class="dropdown-menus-sub" >
                        <a routerLink="/csv-invoice" routerLinkActive="active">Site CSV</a>
                    </div>
                    <div class="dropdown-menus-sub" >
                        <a routerLink="/api-invoice-site" routerLinkActive="active">Site API</a>
                    </div>
                    <div class="dropdown-menus-sub">
                        <a routerLink="/api-invoice">Fleet API</a>
                    </div>
                </div>
            </div>
        </span>

        <!-- Tariffs -->
        <span *ngIf='userRole === "Admin"'>
            <div class="dropdown-menus">
    
                <i class="fa fa-file-text-o" aria-hidden="true"></i>
                <button id="dropdownButton" (click)="tariffsDropdown()">
                    Tariff Data
                    <span *ngIf="!showTariffsDropdown">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                    <span *ngIf="showTariffsDropdown">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </button>
    
                <div *ngIf="showTariffsDropdown" class="submenu">
                    <div class="dropdown-menus-sub">
                        <a routerLink="/tariffs" routerLinkActive="active">Tariffs</a>
                    </div>
                    <div class="dropdown-menus-sub">
                        <a routerLink="/tariff-locations">Tariff Locations</a>
                    </div>
                    <div class="dropdown-menus-sub">
                        <a routerLink="/tariff-test-records">Tariff Test Records</a>
                    </div>
                </div>
            </div>
        </span>

        <!-- Companies -->
        <span *ngIf='userRole === "Admin"'>

            <div class="dropdown-menus">
                <i class="fa fa-handshake-o" aria-hidden="true"></i>
                <button id="dropdownButton" (click)="companiesDropdown()">
                    Clients
                    <span *ngIf="!showCompaniesDropdown">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                    <span *ngIf="showCompaniesDropdown">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </button>
    
                <div *ngIf="showCompaniesDropdown" class="submenu">
                    <div class="dropdown-menus-sub">
                        <a routerLink="/chargingSessionsCsvGen">Charging Session CSV Creation</a>
                    </div>
                    <div class="dropdown-menus-sub">
                        <a routerLink="/fleetcompanies" routerLinkActive="active">FleetSubs</a>
                    </div>
                    <div class="dropdown-menus-sub">
                        <a routerLink="/coupons">Coupons</a>
                    </div>
                    <div class="dropdown-menus-sub">
                        <a routerLink="/company-users-reg">Company Users Reg</a>
                    </div>
                    <!-- <div class="dropdown-menus-sub">
                        <a routerLink="/kia-user-form">KIA User Form</a>
                    </div> -->
                </div>
    
            </div>
        </span>

        <!-- KIA Form -->
        <!-- <span *ngIf='userRole === "Kia"'>
                <div class="submenu">
                    <div class="dropdown-menus-sub">
                        <a routerLink="/kia-user-form">KIA User Form</a>
                    </div>
                </div>
        </span> -->

        <!-- Chargers Map -->
        <span *ngIf='userRole === "Admin" || userRole === "Sales"'>
            <div class="dropdown-menus">
    
                <i class="fa fa-area-chart" aria-hidden="true"></i>
                <button id="dropdownButton" (click)="mapsDropdown()">
                    Tech Data
                    <span *ngIf="!showMapsDropdown">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                    <span *ngIf="showMapsDropdown">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </button>
    
                <span *ngIf='userRole === "Admin" || userRole === "Sales"'>
                    <div *ngIf="showMapsDropdown" class="submenu">
                        <div class="dropdown-menus-sub">
                            <a routerLink="/charger-map" routerLinkActive="active">Chargers Map</a>
                        </div>
                    </div>
                </span>
    
                <span *ngIf='userRole === "Admin" || userRole === "Sales"'>
                    <div *ngIf="showMapsDropdown" class="submenu">
                        <div class="dropdown-menus-sub">
                            <a routerLink="/heatmapnew" routerLinkActive="active">Heatmap</a>
                        </div>
                    </div>
                </span>

                <span *ngIf='userRole === "Admin"'>
                    <div *ngIf="showMapsDropdown" class="submenu">
                        <div class="dropdown-menus-sub">
                            <a routerLink="/project-rapid" routerLinkActive="active">Project Rapid</a>
                        </div>
                    </div>
                </span>
            </div>
        </span>

        <!-- RFID Request Addresses -->
        <span *ngIf='userRole === "Admin"'>
            <div class="dropdown-menus">
                <i aria-hidden="true">
                    <img src="../../../assets/drawing.svg" width="8px" margin="0" padding="0"/>
                </i>
                <button id="dropdownButton" (click)="keyFobAddressesRequestDropdown()">
                    Fob Requests
                    <span *ngIf="!showKeyFobAddressRequestDropdown">
                        <i class="fa fa-angle-right" aria-hidden="true">
                            
                        </i>
                    </span>
                    <span *ngIf="showKeyFobAddressRequestDropdown">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                </button>
    
                <div *ngIf="showKeyFobAddressRequestDropdown" class="submenu">
                    <div class="dropdown-menus-sub">
                        <a routerLink="/rfid-addresses" routerLinkActive="active">RFID addresses</a>
                    </div>
                </div>
            </div>
        </span>
        
</div>
