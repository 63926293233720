
  <div *ngIf='!isLoggedOn'>
    <app-login></app-login>
  </div>

  <div *ngIf='isLoggedOn' class="main-grid">
  
    <div id="menu">
      <app-side-navbar>
      </app-side-navbar>
    </div>
    
    <div>
      <router-outlet (activate)="subscribeToEmitter($event)" (deactivate)="unsubscribe()">
      </router-outlet>
    </div>
  </div>
    