import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tariff-test-records',
  templateUrl: './tariff-test-records.component.html',
  styleUrls: ['./tariff-test-records.component.scss']
})
export class TariffTestRecordsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
