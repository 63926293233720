import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeatmapService {

  url: string = 'https://www.easygo-dev.com:8030/v1';

  url2: string = 'https://www.easygo-dev.com:8030/api';
  constructor(private http: HttpClient) { }

  esbData: Object;

  public getAllEsbChargingSessions() {
    try {
      return this.http.get<JSON>(this.url + '/getAllEsbSessions');
    } catch (error) {
      console.error(error);
      return EMPTY;
    }
  }

  public postZipFile(file: any) {
    const data = new FormData();
    data.append('zipFile', file);
    try {
      return this.http.post(`${this.url}/saveEsbSessions`, data);
    } catch (error) {
      console.error(error);
    }
  }

  getESBChargingSessionsWithinDateRange(dayRange: any) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'POST');
      let httpParams = new HttpParams().set('days', dayRange)
      const req = this.http.get(`${this.url}/getSpecificEsbSessionsDaily`,
        { headers: headers, params: httpParams, responseType: 'text' as 'json' });
      req.subscribe(response => {
      })
    } catch (error) {
      console.error(error);
      return EMPTY;
    }
  }

  getTimesAndSessionsPerSiteMonthly(monthlySessions) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');


      let esbButtonContainer = document.getElementById('esbDataSubmit') as HTMLElement;
      if (document.getElementById('esbReturnedMessage') != null) {
        document.getElementById('esbReturnedMessage').remove();
      }
      let returnedMessageContainer = document.createElement('div');
      returnedMessageContainer.style.marginTop = '.6rem';
      returnedMessageContainer.style.opacity = '0';
      setTimeout(() => {
        returnedMessageContainer.style.opacity = '1';
        returnedMessageContainer.style.transition = '1.6s';
      }, 10);


      returnedMessageContainer.id = 'esbReturnedMessage';
      returnedMessageContainer.textContent = 'Please wait, this is a large dataset';
      esbButtonContainer.appendChild(returnedMessageContainer);

      const body = JSON.stringify(monthlySessions);
      return this.http.post<JSON>(`${this.url}/getSessionsByMonths`,
        body,
        { headers: headers, responseType: 'json' })

    } catch (error) {
      console.error(error);
      return EMPTY;
    }

  }

  getMarkersPerSiteMonthly(monthlySessions) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');


      const body = JSON.stringify(monthlySessions);
      return this.http.post<JSON>(`${this.url}/getSessionsByMonths`,
        body,
        { headers: headers, responseType: 'json' })

    } catch (error) {
      console.error(error);
      return EMPTY;
    }

  }

  getLocations() {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');

      return this.http.post<JSON>(`${this.url2}/getEasyGoLocations`, {}, 
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }

  getAllLocations() {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');

      return this.http.get<JSON>(`${this.url2}/getEasyGoLocations`, 
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }

  getLocationsWithFilter(data) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');

        let url = `${this.url2}/getEasyGoLocations`;

      return this.http.post<JSON>(url, data,
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }

  getIonityLocations(data) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');

        let url = `${this.url2}/getIonityLocations`;


      return this.http.post<JSON>(url, 
        data,
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }

  getTeslaLocations(data) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');

        let url = `${this.url2}/getTeslaLocations`;

      return this.http.post<JSON>(url, 
        data,
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }

  getESBLocations(data) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');

      let url = `${this.url2}/getEsbLocations`;

      return this.http.post<JSON>(url, 
        data,
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }

  getEirLocations(type: string) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');

      return this.http.get<JSON>(`${this.url2}/getEirLocations/${type}`, 
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }

  getMYBLocations(type: string) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET')

        return this.http.get<JSON>(`${this.url2}/getMYBLocations/${type}`,
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }
  getWeevEPowerLocations(type: string) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET')

        return this.http.get<JSON>(`${this.url2}/getWeevEPowerLocations/${type}`,
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }
  getHeatmapSessions(data) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');

      return this.http.post<JSON>(`${this.url2}/getHeatmapSessions`, data,
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }

  getLocationMarker(data) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');

      return this.http.post<JSON>(`${this.url2}/getEasyGoSessions`, data,
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }

  getESBMarker(data) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');

      return this.http.post<JSON>(`${this.url2}/getEsbSessions`, data,
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }

  getCounts(data) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');

      return this.http.post<JSON>(`${this.url2}/getEasyGoSessionsCounts`, data,
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }

  getChartData(data) {
    try {
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Methods', 'GET');

      return this.http.post<JSON>(`${this.url2}/getEasyGoSessionsChartData`, data,
        { headers: headers, responseType: 'json' })
    } catch (error) {
    }
  }


}

