import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SaveFleetSubsService {

  url:string = "https://www.easygo-dev.com:8081/v1/"; // for production in digitalocean TODO: change url
  //url:string = "https://localhost:8081/v1/"; // for dev


  constructor(private http: HttpClient) {}

  public postFleetSubAccounts(fleetSubs : any) {
    try {   
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Access-Control-Allow-Methods', 'POST');
      
      let body = `{fleetSubs:${JSON.stringify(fleetSubs)}}`;

      const req = this.http.post<any>(`${this.url}postFleetSubs/`, body, 
      { headers: headers, responseType: 'json'});
      req.subscribe( () => {
      });
    } catch(error) {
      console.error(error);
      return EMPTY;
    }
  }
}
