export class CompanyCrudUtils {

  createMinusButton(): Element {
  
      let minusButton = document.createElement('button');
      minusButton.className = 'fa fa-minus';
      minusButton.id = 'minusId';
      minusButton.onclick = () => {
        
        let tableRows = document.querySelector('table').querySelectorAll('tr');
        for(let row = 0; row < tableRows.length; row++) {
          tableRows.item(row).id = `row_${row}`;
        }
      }
  
      minusButton.onmouseenter = () => {
        minusButton.style.backgroundColor = 'rgb(255 221 220 / 1)';
        minusButton.style.color = '#960000';
      }
      minusButton.onmouseleave = () => {
        minusButton.style.backgroundColor = 'red';
        minusButton.style.color = 'white';
      }
  
      minusButton.style.backgroundColor = 'red';
      return minusButton;
    }

    sortCompany(company: any) {
      company.sort((companyA, companyB) => {
        if(companyA.name.toLowerCase() < companyB.name.toLowerCase()) {
          return -1;
        }
        if(companyA.name.toLowerCase() > companyB.name.toLowerCase()) {
          return 1;
        }
        return 0
      });
    }
}