import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { AuthguardGuard } from './guards/authguard.guard';
import { ProjectRapidComponent } from './components/project-rapid/project-rapid.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoggedOn:boolean;
  title:string = 'Easy Go Client Side';

  subscription: Subscription;

  constructor(public authGuard : AuthguardGuard, private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.isLoggedOn = this.authGuard.isUserLoggedIn();
  }

  // Subscibes to an event on the ProjectRapidComponent, to increase the height of the page
  subscribeToEmitter(componentRef) {
    if (!(componentRef instanceof ProjectRapidComponent)) {
      return;
    }
    const child : ProjectRapidComponent = componentRef;
    child.changeHeightEvent.subscribe( () => {
      this.changeHeightOfMenuByPixels(2000);
    });
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.changeHeightOfMenuByPercentage(100);
  }

  changeHeightOfMenuByPixels(height: number) {
    const mainGridElement = this.elementRef.nativeElement.querySelector('#menu');
    if (mainGridElement) {
      mainGridElement.style.height = height + 'px'; // Set height
    }
  }

  changeHeightOfMenuByPercentage(height: number) {
    const mainGridElement = this.elementRef.nativeElement.querySelector('#menu');
    if (mainGridElement) {
      mainGridElement.style.height = height + '%'; // Set height
    }
  }
}
