import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { CouponService } from 'src/app/services/coupon-service/coupon.service';

@Component({
  selector: 'app-coupon-interface',
  templateUrl: './coupon-interface.component.html',
  styleUrls: ['./coupon-interface.component.scss']
})
export class CouponInterfaceComponent implements OnInit {

  coupons: any;
  couponSelected : boolean = false;
  selectedCoupon:number = 0;

  showCouponsMessage: boolean = false;
  numberOfCoupons:string = '';

  couponForm = new FormGroup({
    couponTypeId: new FormControl(''),
    numberOfCoupons: new FormControl('')

  });

  constructor(public couponService: CouponService) { }

  ngOnInit() {
    let selectedCoupon;
    this.couponService.getCouponTypes().toPromise()
    .then(response =>  {
      this.coupons = response
      return this.createCouponHtml(this.coupons);
    }).then(e => {
      e.onclick = (el: any) => {
        let bgColour = el.target.parentElement.style.backgroundColor;
        if(bgColour !== 'white') {
          bgColour = 'white';
        }
        if(bgColour == '#ff8282') {
          el.target.parentElement.style.backgroundColor = 'white';
        }
        selectedCoupon = el.target.parentElement.children[0].textContent;
        if(selectedCoupon.length > 10) {
          selectedCoupon = '';
        }
        this.selectedCoupon = selectedCoupon

        let selectedCouponsContainer = document.createElement('span') as any;
        selectedCouponsContainer.id = 'number';
        selectedCouponsContainer.textContent = (this.selectedCoupon);
        if(!this.couponSelected) {    
          document.getElementById('idContainer').appendChild(selectedCouponsContainer);
        } else {
          document.getElementById('idContainer').removeChild(document.getElementById('number'));
          document.getElementById('idContainer').appendChild(selectedCouponsContainer);
        }
        this.couponSelected = true;
      }
    
    });
  }

  createCoupon(e): Object {
    let couponQuantity = e.target.parentElement.parentElement.children[0][0].value as number;
    if(e.target.parentElement.parentElement.children[0].children[2].children[1] === undefined) {
      let warningMessage = document.createElement('div');
      warningMessage.id = 'warningMessage';
      warningMessage.style.marginTop = '.4rem';
      warningMessage.append('Please select a coupon');
      if(!document.getElementById('warningMessage')) {
        document.getElementsByClassName('formContainer')[0].appendChild(warningMessage);
      }
      return;
    }
    if(document.getElementById('warningMessage') && e.target.parentElement.parentElement.children[0].children[2].children[1]) {
      document.getElementById('warningMessage').textContent = '';
    }
    let couponId = e.target.parentElement.parentElement.children[0].children[2].children[1].textContent as number;

    this.showCouponsMessage = true;
    this.numberOfCoupons = `The amount of ${couponQuantity}`;
    let postValues = {
      CouponType: {
        Id : couponId
      },
      NumberOfCoupons: couponQuantity,
      UserEmail: null,
      UserExternalCode: null,
      UserId: null
    }
    let temp = this.couponService.postCouponsInstructions(postValues);
    if(temp === undefined) {
      this.numberOfCoupons = '';
      this.couponService.returnedMessage = 'Failed to generate coupons, error found';
      (document.getElementsByClassName('returnedMessage')[0] as any).style.color = 'hsl(360deg 94% 48%)';
      (document.getElementsByClassName('returnedMessage')[0] as any).style.backgroundColor = 'white';
      (document.getElementsByClassName('returnedMessage')[0] as any).style.paddingTop = '.2rem';
      (document.getElementsByClassName('returnedMessage')[0] as any).style.paddingBottom = '.2rem';
      (document.getElementsByClassName('returnedMessage')[0] as any).style.marginLeft = '3.5rem';
      (document.getElementsByClassName('returnedMessage')[0] as any).style.marginRight = '3.5rem';
      (document.getElementsByClassName('returnedMessage')[0] as any).style.borderRadius = '.4rem';


      let table = document.getElementById('couponsTable');
      let selectedNumber = document.getElementById('number').textContent;

      let tableRows = table.querySelectorAll('tr');

      for(let i = 1; i < tableRows.length; i++) {
        if(tableRows[i].children[0].textContent === selectedNumber) {
          let validFrom = tableRows[i].children[3].textContent as any;
          let validTo = tableRows[i].children[4].textContent as any;
          let currentDate = moment().format('DD-MM-YYYY');

          if(validFrom < currentDate || validTo < currentDate) {
            this.couponService.returnedMessage = `Failed to generate coupons, error found.  Coupon with id ${selectedNumber} date is invalid.`;
            if(validFrom > currentDate) {
              this.couponService.returnedMessage += `  Coupon is not valid until ${validFrom}`; 
            }
            if(validTo < currentDate) {
              this.couponService.returnedMessage += `  Coupon validation date expired at ${validTo}`; 
            }

          }
        }
      }

    } 

  }


  createCouponHtml(coupons: any) {
    let couponsTable = document.getElementById('couponsTable');

    for(let coupon of coupons) {

      let couponContainer = document.createElement('tr');
      couponContainer.style.backgroundColor = 'white';
      couponContainer.style.cursor = 'pointer';

      couponContainer.onmouseenter = () => {
        if(couponContainer.style.backgroundColor !== 'white') {
          couponContainer.style.backgroundColor = 'rbg(255, 180, 180)';
        } else {
          couponContainer.style.backgroundColor = '#ffffda';
        }
      }
      couponContainer.onmouseleave = () => {
        couponContainer.style.backgroundColor = 'white';
      }

      let couponTypeId = document.createElement('td');
      couponTypeId.style.padding = '.1rem';
      couponTypeId.style.paddingLeft = '.3rem';
      couponTypeId.style.paddingRight = '.3rem';

      couponTypeId.append(coupon.CouponTypeId);

      let couponTitle = document.createElement('td');
      couponTitle.style.padding = '.1rem';
      couponTitle.style.paddingLeft = '.3rem';
      couponTitle.style.paddingRight = '.3rem';
      couponTitle.append(coupon.Title);

      let couponDescription = document.createElement('td');
      couponDescription.style.padding = '.1rem';
      couponDescription.style.paddingLeft = '.3rem';
      couponDescription.style.paddingRight = '.3rem';
      couponDescription.append(coupon.Description);

      let couponValidFrom = document.createElement('td');
      couponValidFrom.style.padding = '.1rem';
      couponValidFrom.style.paddingLeft = '.3rem';
      couponValidFrom.style.paddingRight = '.3rem';
      const momentValidFrom = moment(coupon.ValidFrom).format('DD-MM-YYYY');
      couponValidFrom.append(momentValidFrom);

      let couponValidTo = document.createElement('td');
      couponValidTo.style.padding = '.1rem';
      couponValidTo.style.paddingLeft = '.3rem';
      couponValidTo.style.paddingRight = '.3rem';
      
      const momentValidTo = moment(coupon.ValidTo).format('DD-MM-YYYY');
      couponValidTo.append(momentValidTo);

      let couponUsageFlavourId = document.createElement('td');
      couponUsageFlavourId.style.padding = '.1rem';
      couponUsageFlavourId.style.paddingLeft = '.3rem';
      couponUsageFlavourId.style.paddingRight = '.3rem';
      couponUsageFlavourId.style.textAlign = 'center';

      couponUsageFlavourId.append(coupon.CouponUsageFlavourId.Id);

      let couponMerchantCompanyName = document.createElement('td');
      couponMerchantCompanyName.style.padding = '.1rem';
      couponMerchantCompanyName.style.paddingLeft = '.3rem';
      couponMerchantCompanyName.style.paddingRight = '.3rem';
      couponMerchantCompanyName.style.textAlign = 'center';
      couponMerchantCompanyName.append(coupon.MerchantCompanyName);

      couponContainer.appendChild(couponTypeId);
      couponContainer.appendChild(couponTitle);
      couponContainer.appendChild(couponDescription);
      couponContainer.appendChild(couponValidFrom);
      couponContainer.appendChild(couponValidTo);
      couponContainer.appendChild(couponUsageFlavourId)
      couponContainer.appendChild(couponMerchantCompanyName);

      couponsTable.appendChild(couponContainer);

      couponContainer.onclick = (e: any) => {
        let row = e.target.parentElement;

        row.style.backgroundColor = '#ff8282';
      };
    }
    return couponsTable;
  }

  

}
