import { Component, OnChanges, OnInit } from '@angular/core';
import { TariffsService } from '../../services/tariffs-service/tariffs.service';

@Component({
  selector: 'app-tariff-data',
  templateUrl: './tariffs.component.html',
  styleUrls: ['./tariffs.component.scss']
})
export class TariffsComponent implements OnInit, OnChanges {

  private tariffsList: any;
  private tariffsInBackend: number;
  private rowsAdded = 0;
  private disableSave = true;
  private previousRowColour: string;
  private isTableSort: boolean = false;
  private sortCounter: number = 0;
  private tableBackendLoaded: boolean = false;

  constructor(public tariffData: TariffsService) { }

  public ngOnInit(): void {
    if(!this.isTableSort) {
      this.populateTable();
    }
  }

  public ngOnChanges(): void {
    this.checkTableForDashes();
  }

  public sortByTariffName(e): void {
    let faArrow = e.path[0]. querySelector('i');
    let sortBy;

    if(this.sortCounter % 2 == 0) {
      faArrow.classList.remove('fa-angle-down');
      faArrow.classList.add('fa-angle-up');
      sortBy = 'desc';
    } else {
      faArrow.classList.add('fa-angle-down');
      faArrow.classList.remove('fa-angle-up');
      sortBy = 'asc';
    }
    
    this.isTableSort = true;
    let tableBody = e.path[1].querySelector('tbody');
    let tableRows = tableBody.querySelectorAll('tr');
    let tableRowsCount = tableRows.length;

    const sortedTariffsList = [];

    for(let i = 0; i < tableRowsCount; i++) {
        
        const rowData = { 
          tariffName : '',
          type: '',
          accessFee: '',
          accessFeeValidAfter: '',
          chargeTimeFee: '',
          chargeTimeFeeValidAfter: '',
          consumedEnergyFee: '',
          consumedEnergyFeeValidAfter: '',
          parkingTimeFee: '',
          parkingTimeFeeValidAfter: '',
          overstayFee: '',
          overstayFeeValidAfter: '',
          validFrom: '',
          validTo: '',
          tested: ''
        
        };
        rowData.tariffName = tableRows[i].querySelectorAll('td')[0].innerText;
        rowData.type = tableRows[i].querySelectorAll('td')[1].innerText;

        rowData.accessFee = tableRows[i].querySelectorAll('td')[2].innerText;
        rowData.accessFeeValidAfter = tableRows[i].querySelectorAll('td')[3].innerText;
        
        rowData.chargeTimeFee = tableRows[i].querySelectorAll('td')[4].innerText;
        rowData.chargeTimeFeeValidAfter = tableRows[i].querySelectorAll('td')[5].innerText;
        
        rowData.consumedEnergyFee = tableRows[i].querySelectorAll('td')[6].innerText;
        rowData.consumedEnergyFeeValidAfter = tableRows[i].querySelectorAll('td')[7].innerText;
        
        rowData.parkingTimeFee = tableRows[i].querySelectorAll('td')[8].innerText;
        rowData.parkingTimeFeeValidAfter = tableRows[i].querySelectorAll('td')[9].innerText;
        
        rowData.overstayFee = tableRows[i].querySelectorAll('td')[10].innerText;
        rowData.overstayFeeValidAfter = tableRows[i].querySelectorAll('td')[11].innerText;
        rowData.validFrom = tableRows[i].querySelectorAll('td')[12].innerText;
        rowData.validTo = tableRows[i].querySelectorAll('td')[13].innerText;
        rowData.tested = tableRows[i].querySelectorAll('td')[14].innerText;

        sortedTariffsList.length = 0;
        sortedTariffsList.push(rowData);
    }

    // TODO flush older data before using newer, then recreate table
    if(sortBy === 'asc') {
      sortedTariffsList.sort( (a, b) => a.tariffName.localeCompare(b.tariffName) )
    } else if (sortBy === 'desc') {
      sortedTariffsList.sort( (a, b) => b.tariffName.localeCompare(a.tariffName) )
    }

    this.generateNewTable(sortedTariffsList);

    this.isTableSort = false;
    this.sortCounter++;
  }

  public generateNewTable(sortedTariffsList: any) {
  }

  public generateTable(): void {
    const table = document.getElementById('tariffTable') as any;
    this.tariffsInBackend = this.tariffsList.length;
    const tableLength = document.getElementById('tariffTable').querySelectorAll('th').length;

    table.style.borderRadius = '.3rem';
    for(let i = 0; i < this.tariffsList.length; i++) {
      let tableRow = table.insertRow();  
       
      for(let j = 0; j < tableLength; j++) {
        if(j === 0) {
          tableRow.insertCell(0).innerText = this.tariffsList[i].tariffName;
        }
        if(j === 1) {
          tableRow.insertCell(1).innerText = this.tariffsList[i].type;
        }

        if(j === 2) {
          tableRow.insertCell(2).innerText = this.tariffsList[i].accessFee;
        }
        if(j === 3) {
          tableRow.insertCell(3).innerText = this.tariffsList[i].accessFeeValidAfter;
        }
        if(j === 4) {
          tableRow.insertCell(4).innerText = this.tariffsList[i].chargeTimeFee;
        }
        if(j === 5) {
          tableRow.insertCell(5).innerText = this.tariffsList[i].chargeTimeFeeValidAfter;
        }
        if(j === 6) {
          tableRow.insertCell(6).innerText = this.tariffsList[i].consumedEnergyFee;
        }
        if(j === 7) {
          tableRow.insertCell(7).innerText = this.tariffsList[i].consumedEnergyFeeValidAfter;
        }
        if(j === 8) {
          tableRow.insertCell(8).innerText = this.tariffsList[i].parkingTimeFee;
        }
        if(j === 9) {
          tableRow.insertCell(9).innerText = this.tariffsList[i].parkingTimeFeeValidAfter;
        }
        if(j === 10) {
          tableRow.insertCell(10).innerText = this.tariffsList[i].overstayFee;
        }
        if(j === 11) {
          tableRow.insertCell(11).innerText = this.tariffsList[i].overstayFeeValidAfter;
        }
        if(j === 12) {
          tableRow.insertCell(12).innerText = this.tariffsList[i].validFrom;
        }
        if(j === 13) {
          tableRow.insertCell(13).innerText = this.tariffsList[i].validTo;
        }
        if(j === 14) {
          tableRow.insertCell(14).innerText = this.tariffsList[i].tested;
          tableRow.cells[14].style.backgroundColor = '#c55252';
          tableRow.cells[14].style.color = '#fff';

        }
      }   
      tableRow.contentEditable = true;
      tableRow.style.textAlign = "center";
      document.getElementById('tariffTable').querySelector('th').style.paddingBottom = '.3rem';
      document.getElementById('tariffTable').querySelector('th').style.paddingTop = '.1rem';

      let numOfRows = table.rows.length as any;
      for(let k = 0; k < numOfRows; k++) {
        if(k % 2 === 0) {
          tableRow.style.backgroundColor = "#fff";
        } else {
          tableRow.style.backgroundColor = "#eee";
        }
      }
    }
  }

  public populateTable(): void {
    this.tariffData.getAllTariffs().subscribe((data: any) => {
      this.tariffsList = data[data.length-1].tariffs;
      this.generateTable();
      this.tableBackendLoaded = true;
      this.checkTableForDashes();
    });
  }

  public addTableRow(): void {
    const table = document.getElementById('tariffTable') as any;
    let tableRow = table.insertRow();
    const tableLength = document.getElementById('tariffTable').querySelectorAll('th').length;
    for(let i = 0; i < 2; i++) {
      tableRow.insertCell(i).textContent = "-";      
    }
    for(let i = 2; i < tableLength-3; i++) {
      tableRow.insertCell(i).textContent = "0"; 
    }
    tableRow.insertCell(12).textContent = "no date"; 
    tableRow.insertCell(13).textContent = "no date";    
    tableRow.insertCell(tableLength-1).textContent = "false";
    tableRow.cells[tableLength-1].style.backgroundColor = '#c55252';
    tableRow.cells[tableLength-1].style.color = '#eee';

    tableRow.contentEditable = true;
    tableRow.style.textAlign = "center";

    let numOfRows = table.rows.length as any;
    for(let k = 0; k < numOfRows; k++) {
      if(k % 2 === 0) {
        tableRow.style.backgroundColor = "#fff";
      } else {
        tableRow.style.backgroundColor = "#eee";
      }
    }
    this.rowsAdded++;
    this.checkTableForDashes();
  }


  public removeTableRow(): void {
    const table = document.getElementById('tariffTable') as any;
    
    let newestRow = table.rows.length;
    if(newestRow > this.tariffsInBackend) {
      table.deleteRow(newestRow - 1);
      this.rowsAdded--;
    }
    this.checkTableForDashes();
  }


  public updateTariffData(): void {
    const table = document.getElementById('tariffTable') as any;
    let allTariffs = [];
    
    for(let i = 0; i < table.rows.length; i++) {
      
      for (let j = 0; j < table.rows[i].cells.length; j++) {
        let tariffModel = new Map;
        tariffModel.set('tariffName', table.rows[i].cells[j].textContent);
        tariffModel.set('type', table.rows[i].cells[j+1].textContent);

        tariffModel.set('accessFee', table.rows[i].cells[j+2].textContent);
        tariffModel.set('accessFeeValidAfter', table.rows[i].cells[j+3].textContent);

        tariffModel.set('chargeTimeFee', table.rows[i].cells[j+4].textContent);
        tariffModel.set('chargeTimeFeeValidAfter', table.rows[i].cells[j+5].textContent);

        tariffModel.set('consumedEnergyFee', table.rows[i].cells[j+6].textContent);
        tariffModel.set('consumedEnergyFeeValidAfter', table.rows[i].cells[j+7].textContent);

        tariffModel.set('parkingTimeFee', table.rows[i].cells[j+8].textContent);
        tariffModel.set('parkingTimeFeeValidAfter', table.rows[i].cells[j+9].textContent);

        tariffModel.set('overstayFee', table.rows[i].cells[j+10].textContent);
        tariffModel.set('overstayFeeValidAfter', table.rows[i].cells[j+11].textContent);
        tariffModel.set('validFrom', table.rows[i].cells[j+12].textContent);
        tariffModel.set('validTo', table.rows[i].cells[j+13].textContent);
        tariffModel.set('tested', table.rows[i].cells[j+14].textContent);
        let obj = {};
        tariffModel.forEach((value, key) => {
          obj[key] = value;
        })
        allTariffs.push(obj);
        break;
      }
    }
    this.tariffData.postTariffs(allTariffs);
  }

  public checkTableForDashes(): void {
    if(this.rowsAdded > 0) {
      const table = document.getElementById('tariffTable') as any;
      let rowsLength = table.rows.length;
      let columnsLength = table.rows[0].cells.length;
      let foundDash = false;
      for(let i = 0; i < rowsLength; i++) {
        for(let j = 0; j < columnsLength; j++) {
          if(table.rows[i].cells[j].innerText === "-") {
            foundDash = true;
          }
        }
      }
      if(!foundDash) {
        this.disableSave = true;
      } else {
        this.disableSave = false;
      }
    }
  }

  public highlightRow(e: any): void {
    let highlightedRow = e.path[0].closest('tr');

    if(highlightedRow !== null) {
      if(highlightedRow.style.backgroundColor !== 'rgb(255, 255, 146)') {
        this.previousRowColour = highlightedRow.style.backgroundColor;
      }
      if(highlightedRow.style.backgroundColor !== 'rgb(255, 255, 146)') {

        highlightedRow.style.backgroundColor = 'rgb(255, 255, 146)';
      } else {
        highlightedRow.style.backgroundColor = this.previousRowColour;
  
      }
    }
  }

}
