import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TariffLocationsService {

  url:string = 'https://www.easygo-dev.com:8081/v1' // for production in digitalocean TODO: change url

  tariffLocationsList: any;
  responseMessage: string;

  constructor(private http: HttpClient) { }

  public getAllTariffLocations() {
    try {
      return this.http.get<any>(`${this.url}/tariffLocations`)
    } catch (error) {
      console.error(error);
      return EMPTY;
    }
  }

  public getAllCompanies() {
    try {
      return this.http.get<any>(`${this.url}/companies`)
    } catch (error) {
      console.error(error());
      return EMPTY;
    }
  }

  public postTariffLocations(tariffLocationsObject: any) {
    try {      
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('Access-Control-Allow-Methods', 'POST');
      let body = `{"tariffData":${JSON.stringify(tariffLocationsObject)}}`;
      const req = this.http.post<any>(`${this.url}/postLocationTariffs`, body, {headers: headers, responseType: 'json'});
      req.subscribe( (response) => {
          this.responseMessage = response;
      });

    } catch(error) {
        console.error(error);
      return EMPTY;
    }
  }

}
