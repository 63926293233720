
<div class='fleetSubUsersGrid'>
    <div>
    </div>
    <div>
        <form>
            <div id='dropInstructions'>
                <label>Select the fleet account Excel file:  </label>
            </div>
        </form>
    </div>
    <div>
        <form>
            <input type='file' 
            class='hidden'
            id='files'
            (change)='onFileChange($event)' 
            multiple='false' 
            text='test'/>
        </form>
    </div>
    <div>
    </div>
    <div>
    </div>
    <div id='tableContainer'>
    </div>
    <div>

    </div>
    <div>

    </div>
    <div id='returnedMessages'>
        <!-- {{this.registerFormService.returnedMessage}} -->

        <div id='pressAgainMessage' *ngIf='this.createButtonPressed && this.registerFormService.userCreationCounter < this.usersNumber'>
            There is a bug in the Etrel Urchin API when it comes to creating users.  Please press the create button again to add the missing remaining users.
        </div>
        <div id='companyNameWarning'>
            Please select a company name in the first sheet
        </div>
    </div>
</div>

