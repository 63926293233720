import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tab-navigator',
  templateUrl: './tab-navigator.component.html',
  styleUrls: ['./tab-navigator.component.scss']
})
export class TabNavigatorComponent implements OnInit {
  @Input() mapType = "charger-map";
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onMapChange(e) {
    this.router.navigate([e.value]);
  }
}
