import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class RfidAddressesService {

  url:string = 'https://www.easygo-dev.com:8081/v1/';
  // url:string = 'https://localhost:8081/v1/';

  returnedMessage: string;

  constructor(private http: HttpClient) {}

  public getRfidAddressesPdf() {
    try {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/pdf')
      .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
      .set('Access-Control-Allow-Methods', 'POST')

      let body = null;
      let req = this.http.post<File>(`${this.url}getRfidAddresses`, body, 
      { headers: headers, responseType: 'blob' as 'json'});
      req.subscribe( (response) => {
        console.log(response);
        // moment().format('MM/DD/YYYY')
        const momento = moment().format('MM-DD-YYYY');
        let stringDate = momento.replace('-', '_');
        FileSaver.saveAs(response, `RfidRequest_${stringDate}.pdf`);

        const momentForResponesMessage = moment().format('MMMM Do YYYY, h:mm:ss a');
        if(response.size > 0) {
          this.returnedMessage = `PDF file with names and addresses for key fob requests for printing has been successfully created on ${momentForResponesMessage}`;
        } else {
          this.returnedMessage = `The PDF file is empty.  Either something went wrong creating the PDF or there are currently no key fob ticket requests`;
        }
      });
    } catch (error) {
      console.error(error);
      return EMPTY;
    }
  }
}
