import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';

@Component({
  selector: 'app-csv-invoice',
  templateUrl: './csv-invoice.component.html',
  styleUrls: ['./csv-invoice.component.scss']
})
export class CsvInvoiceComponent {

  //private url: string = "https://localhost:6502/api/uploadCsvFileAng";
  private url: string = "https://www.easygo-dev.com:6502/api/uploadCsvFileAng";
  private selectedFile: File = null;
  public returnedMessage: string;
  public waitingMessage: string;
  private waiting: boolean;

  constructor(private http: HttpClient) { }

  public onFileSelected(event: any): void {
    this.selectedFile = <File>event.target.files[0];
  }

  public onUpload(): void {

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    let fileName = this.selectedFile.name;

    if(fileName.endsWith('csv')) {
      fileName = fileName.replace('.csv', '');
    }

    this.waiting = true;

    if(this.waiting) {
      this.waitingMessage = "working...";
      this.returnedMessage = "";
      let placeholder = "";

      setTimeout(function(){
        placeholder = "Please be patient, the service is still generating the file...";
        this.waitingMessage = placeholder;
      }, 10000);

    }

    const fd = new FormData();
    fd.append('file', this.selectedFile, this.selectedFile.name);

    this.http.post(this.url, fd, { headers: headers, responseType: 'blob'})
    .subscribe(res => {

      FileSaver.saveAs(res, `${fileName}_Charging_Sessions`);
      const momento = moment().format('MMMM Do YYYY, h:mm:ss a');

      // "res" = response
      // This represents the size of the generated PDF file
      if(res.size > 0) {

        this.returnedMessage = `PDF successfully generated on ${momento}`;
      } else {
        this.returnedMessage = `Failed to successfully generated PDF on ${momento}`;
      }

      this.waiting = false;

      if(!this.waiting) {
        this.waitingMessage = "";
      }

    },
    error => { // Comes down here in the event of an error (Which happens with Hospice report)
      console.error();
      const momento = moment().format('MMMM Do YYYY, h:mm:ss a');

      this.returnedMessage = `Failed to successfully generated PDF on ${momento}`;
      this.waitingMessage = "";

    }
    );

  } // End of onUpload method

} // End of CsvInvoiceComponent class
