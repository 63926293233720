<div class="loader-container">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>

<div class="google-heat-map">
  <div class="map-container">
    <google-map #gMap (zoomChanged)="zoomChange()" [options]="options">
      <!-- *ngIf="currentZoomLevel >= 10" -->
      <ng-container>
        <map-marker
          #markerEle="mapMarker"
          *ngFor="let marker of mapMarkers"
          (mapClick)="onMarkClick($event, marker)"
          (mapMouseover)="openInfoWindow(markerEle, marker)"
          (mapMouseout)="closeInfoWindow()"
          [clickable]="true"
          [position]="marker"
          [options]="marker.options"
        ></map-marker>
        <map-info-window>
           <span class="city-name-d">{{ hoveredMarker.friendlyName || hoveredMarker.address }}</span>
        </map-info-window>
      </ng-container>
    </google-map>
  </div>
  <div class="map-menu">
    <app-charger-sidebar
      [selectedLocation]="selectedMarker"
      (onDateApply)="getMarkers($event)"
    ></app-charger-sidebar>
  </div>
</div>
