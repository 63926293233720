import { Component, OnInit, EventEmitter } from '@angular/core';
import { ProjectRapidService } from 'src/app/services/project-rapid/project-rapid.service';
import { Charger } from 'src/app/services/project-rapid/project-rapid.service';

@Component({
  selector: 'app-project-rapid',
  templateUrl: './project-rapid.component.html',
  styleUrls: ['./project-rapid.component.scss']
})
export class ProjectRapidComponent implements OnInit  {

  public selectedButton: string = "";
  public displayGeneralInfo: boolean = true;
  public chargers: any;

  changeHeightEvent = new EventEmitter();

  public noOfChargersAC: number = 0;
  public noOfChargersDC: number = 0;
  public noOfSocketsAC: number = 0;
  public noOfSocketsDC: number = 0;

  public irishChargersAC: number = 0;
  public irishChargersDC: number = 0;
  public irishSocketsAC: number = 0;
  public irishSocketsDC: number = 0;

  public ukChargersAC: number = 0;
  public ukChargersDC: number = 0;
  public ukSocketsAC: number = 0;
  public ukSocketsDC: number = 0;

  public insertedFrom: Date;
  public insertedTo: Date = new Date();
  public filteredChargingOption: string = 'Both'; // Initialize filteredChargingOption with 'Both' for radio buttons
  public filteredCountryOption: string = 'Both'; // Initialize filteredCountryOption with 'Both' for radio buttons
  public filteredOwnershipOption: string = 'Both'; // Initialize filteredChargingOption with 'Both' for radio buttons

  counties: string[] = ['Antrim', 'Armagh', 'Carlow', 'Cavan', 'Clare', 'Cork', 'Derry','Donegal', 'Down', 'Dublin', 'Fermanagh', 'Galway', 'Kerry',
  'Kildare', 'Kilkenny', 'Laois', 'Leitrim', 'Limerick', 'Longford', 'Louth', 'Mayo', 'Meath', 'Monaghan', 'Offaly', 'Roscommon', 'Sligo', 
  'Tipperary', 'Tyrone', 'Waterford', 'Westmeath', 'Wexford', 'Wicklow', 'Unknown'];

  selectedCounties: string[] = [];
  selectedSectors: string[] = [];
  selectedVendors: string[] = [];
  selectedLandOwners: string[] = [];
  selectedChargePointOwners: string[] = [];
  selectedAccessTypes: string[] = [];
  selectedLocationTypes: string[] = [];
  selectedModels: string[] = [];
  selectedConnectorTypes: string[] = [];

  sectors: string[] = [];
  vendors: string[] = [];
  landOwners: string[] = [];
  chargePointOwners: string[] = [];
  accessTypes: string[] = [];
  locationTypes: string[] = [];
  models: string[] = [];
  connectorTypes: string[] = [];

  maxOutputPowerFrom: number = 0.0;
  maxOutputPowerTo: number = 1000.0;

  constructor(private projectRapidService: ProjectRapidService) {
    // Setting insertedFrom's default value
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    this.insertedFrom = sevenDaysAgo;
  }

  ngOnInit(): void {

    this.changeHeightEvent.emit();

    // Load asset info from Mongo
    this.projectRapidService.getEasyGoAssetsFromMongo();
  }

  toggleGeneralInfo() {
    this.displayGeneralInfo = !this.displayGeneralInfo;
  }

  get toggleIcon() {
    return this.displayGeneralInfo ? 'fa-minus-circle' : 'fa-plus-circle';
  }

  loadAssets() {
    this.resetValues();
    this.selectedButton = "Assets";

    this.chargers = this.projectRapidService.getChargers();

    // Load in filters
    this.loadAssetFilters(this.chargers);

    // Iterate through array of charger objects to get general info
    for (let charger in this.chargers) {
      let chargerInfo = this.chargers[charger];
      let country = this.projectRapidService.getCountry(chargerInfo["friendlyCode"]);
      this.incrementChargerAndSocketCounts(country, chargerInfo);
    }

  }

  loadUsage() {
    this.selectedButton = "Usage";
  }

  loadUsers() {
    this.selectedButton = "Users";
  }

  resetValues() {
    this.noOfChargersAC = 0;
    this.noOfChargersDC = 0;
    this.noOfSocketsAC = 0;
    this.noOfSocketsDC = 0;

    this.irishChargersAC = 0;
    this.irishChargersDC = 0;
    this.irishSocketsAC = 0;
    this.irishSocketsDC = 0;

    this.ukChargersAC = 0;
    this.ukChargersDC = 0;
    this.ukSocketsAC = 0;
    this.ukSocketsDC = 0;
  }

  loadAssetFilters(chargers: any) {
    // Get filters
    let charger = chargers['995'];
    const keys = Object.keys(charger);

    for (let charger in chargers) {
      
      let chargerInfo = chargers[charger];
      let connectors = chargerInfo["connectors"];

      // Get Country
      let country = this.projectRapidService.getCountry(chargerInfo["friendlyCode"]);
      chargers[charger]["oceanInstance"] = country;

      let connectorOneType: string = '';
      let connectorTwoType: string = '';
      let connectorThreeType: string = '';

      // Load in all possible connectors & create individual connector types
      for (let connector in connectors) {
        let connectorType = connectors[connector]["connectorType"];

        if (connector === '0') {
          connectorOneType = connectorType;
        } else if (connector === '1') {
          connectorTwoType = connectorType;
        } else if (connector === '2') {
          connectorThreeType = connectorType;
        }

        if (!this.connectorTypes.includes(connectorType)) {
          this.connectorTypes.push(connectorType);
        }
      }

      chargers[charger]["connectorOneType"] = connectorOneType;
      chargers[charger]["connectorTwoType"] = connectorTwoType;
      chargers[charger]["connectorThreeType"] = connectorThreeType;

      // Load in all possible sectors
      let sector = chargerInfo['sector'];
      if (!this.sectors.includes(sector)) {
        this.sectors.push(sector);
      }

      // Load in all possible vendors
      let vendor = chargerInfo['vendor'];
      if (!this.vendors.includes(vendor)) {
        this.vendors.push(vendor);
      }

      // Load in all possible landOwners
      let landOwner = chargerInfo['landOwner'];
      if (!this.landOwners.includes(landOwner)) {
        this.landOwners.push(landOwner);
      }

      // Load in all possible chargePointOwners
      let chargePointOwner = chargerInfo['chargePointOwner'];
      if (!this.chargePointOwners.includes(chargePointOwner)) {
        this.chargePointOwners.push(chargePointOwner);
      }

      // Load in all possible accessTypes
      let accessType = chargerInfo['accessType'];
      if (!this.accessTypes.includes(accessType)) {
        this.accessTypes.push(accessType);
      }

      // Load in all possible locationTypes
      let locationType = chargerInfo['locationType'];
      if (!this.locationTypes.includes(locationType)) {
        this.locationTypes.push(locationType);
      }

      // Load in all possible models
      let model = chargerInfo['model'];
      if (!this.models.includes(model)) {
        this.models.push(model);
      }
    }
    this.sectors.sort();
    this.vendors.sort();
    this.landOwners.sort();
    this.chargePointOwners.sort();
    this.accessTypes.sort();
    this.locationTypes.sort();
    this.models.sort();
    this.connectorTypes.sort();
  }

  incrementChargerAndSocketCounts(country: string, chargerInfo: any) {
    if (country === "Ireland") {
      chargerInfo["chargerType"] === "AC" ? this.irishChargersAC++ : this.irishChargersDC++;
    } else {
      chargerInfo["chargerType"] === "AC" ? this.ukChargersAC++ : this.ukChargersDC++;
    }
    chargerInfo["chargerType"] === "AC" ? this.noOfChargersAC++ : this.noOfChargersDC++;

    // Get socket counts
    for (let socket in chargerInfo["connectors"]) {
      if (country === "Ireland") {
        chargerInfo["connectors"][socket]["chargingType"] === "AC" ? this.irishSocketsAC++ : this.irishSocketsDC++;
      } else {
        chargerInfo["connectors"][socket]["chargingType"] === "AC" ? this.ukSocketsAC++ : this.ukSocketsDC++;
      }
      chargerInfo["connectors"][socket]["chargingType"] === "AC" ? this.noOfSocketsAC++ : this.noOfSocketsDC++;
    }
  }

  submitQuery() {

    let filteredChargers: Charger[] = [];

    // Get chargers filtered by "insertedDate"
    this.chargers = this.projectRapidService.getChargers();

    for (let charger in this.chargers) {
      let chargerInfo = this.chargers[charger];

      let insertedDateString = chargerInfo["insertedDate"];
      let insertedDate: Date = this.parseDate(insertedDateString);

      if (insertedDate >= this.insertedFrom && insertedDate <= this.insertedTo) {
        filteredChargers.push(chargerInfo);
      }
    }

    // Filter for specific charger type
    if (this.filteredChargingOption !== "Both") {
      filteredChargers = filteredChargers.filter(charger => charger.chargerType === this.filteredChargingOption);
    }

    // Filter for ownership
    if (this.filteredOwnershipOption !== "Both") {
      filteredChargers = filteredChargers.filter(charger => charger.owner === this.filteredOwnershipOption);
    }

    // Filter for ocean instance (Ireland/UK)
    if (this.filteredCountryOption !== "Both") {
      filteredChargers = filteredChargers.filter(charger => charger.oceanInstance === this.filteredCountryOption);
    }

    // Filtered chargers
    filteredChargers = this.getChargersFilteredByCounty(filteredChargers);
    filteredChargers = this.getChargersFilteredBySector(filteredChargers);
    filteredChargers = this.getChargersFilteredByVendor(filteredChargers);
    filteredChargers = this.getChargersFilteredByLandOwner(filteredChargers);
    filteredChargers = this.getChargersFilteredByCPO(filteredChargers);
    filteredChargers = this.getChargersFilteredByAccessTypes(filteredChargers);
    filteredChargers = this.getChargersFilteredByLocationTypes(filteredChargers);
    filteredChargers = this.getChargersFilteredByModel(filteredChargers);
    filteredChargers = this.getChargersFilteredByMaxOutputPower(filteredChargers);
    filteredChargers = this.getChargersFilteredByConnectorType(filteredChargers);
    this.projectRapidService.createExcel(filteredChargers);
  }

  // Convert string date to Date object
  parseDate(dateString: string): Date {
    const parts = dateString.split('.');
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1; // JavaScript months are 0-indexed
    const year = parseInt(parts[2]);
    return new Date(year, month, day);
  }

  getChargersFilteredByCountry(chargers: Charger[]): Charger[] {
    let filteredCountryChargers: Charger[] = [];
    if (this.selectedCounties.length > 0 && this.selectedCounties.length < 33) {
      for (let charger in chargers) {
        let chargerInfo = chargers[charger];
        let addressInfo = chargerInfo["addressInfo"];
        let county = addressInfo['County'];

        if (this.selectedCounties.includes(county)) {
          filteredCountryChargers.push(chargers[charger]);
        }
      }
    } else {
      filteredCountryChargers = chargers;
    }
    return filteredCountryChargers;
  }

  getChargersFilteredByCounty(chargers: Charger[]): Charger[] {
    let filteredCountyChargers: Charger[] = [];
    if (this.selectedCounties.length > 0 && this.selectedCounties.length < 33) {
      for (let charger in chargers) {
        let chargerInfo = chargers[charger];
        let addressInfo = chargerInfo["addressInfo"];
        let county = addressInfo['County'];

        if (this.selectedCounties.includes(county)) {
          filteredCountyChargers.push(chargers[charger]);
        }
      }
    } else {
      filteredCountyChargers = chargers;
    }
    return filteredCountyChargers;
  }

  getChargersFilteredBySector(chargers: Charger[]): Charger[] {
    let filteredSectorChargers: Charger[] = [];
    if (this.selectedSectors.length > 0 && this.selectedSectors.length < this.sectors.length) {
      for (let charger in chargers) {
        let chargerInfo = chargers[charger];
        let sector = chargerInfo['sector'];

        if (this.selectedSectors.includes(sector)) {
          filteredSectorChargers.push(chargers[charger]);
        }
      }
    } else {
      filteredSectorChargers = chargers;
    }
    return filteredSectorChargers;
  }

  getChargersFilteredByVendor(chargers: Charger[]): Charger[] {
    let filteredVendorChargers: Charger[] = [];
    if (this.selectedVendors.length > 0 && this.selectedVendors.length < this.vendors.length) {
      for (let charger in chargers) {
        let chargerInfo = chargers[charger];
        let vendor = chargerInfo['vendor'];

        if (this.selectedVendors.includes(vendor)) {
          filteredVendorChargers.push(chargers[charger]);
        }
      }
    } else {
      filteredVendorChargers = chargers;
    }
    return filteredVendorChargers;
  }

  getChargersFilteredByLandOwner(chargers: Charger[]): Charger[] {

    let filteredLandOwnerChargers: Charger[] = [];
    if (this.selectedLandOwners.length > 0 && this.selectedLandOwners.length < this.landOwners.length) {
      for (let charger in chargers) {
        let chargerInfo = chargers[charger];
        let landOwner = chargerInfo['landOwner'];

        if (this.selectedLandOwners.includes(landOwner)) {
          filteredLandOwnerChargers.push(chargers[charger]);
        }
      }
    } else {
      filteredLandOwnerChargers = chargers;
    }
    return filteredLandOwnerChargers;
  }

  getChargersFilteredByCPO(chargers: Charger[]): Charger[] {

    let filteredCpoChargers: Charger[] = [];
    if (this.selectedChargePointOwners.length > 0 && this.selectedChargePointOwners.length < this.chargePointOwners.length) {
      for (let charger in chargers) {
        let chargerInfo = chargers[charger];
        let cpo = chargerInfo['chargePointOwner'];

        if (this.selectedChargePointOwners.includes(cpo)) {
          filteredCpoChargers.push(chargers[charger]);
        }
      }
    } else {
      filteredCpoChargers = chargers;
    }
    return filteredCpoChargers;
  }

  getChargersFilteredByAccessTypes(chargers: Charger[]): Charger[] {
    let filteredAccessTypeChargers: Charger[] = [];
    if (this.selectedAccessTypes.length > 0 && this.selectedAccessTypes.length < this.accessTypes.length) {
      for (let charger in chargers) {
        let chargerInfo = chargers[charger];
        let accessType = chargerInfo['accessType'];

        if (this.selectedAccessTypes.includes(accessType)) {
          filteredAccessTypeChargers.push(chargers[charger]);
        }
      }
    } else {
      filteredAccessTypeChargers = chargers;
    }
    return filteredAccessTypeChargers;
  }

  getChargersFilteredByLocationTypes(chargers: Charger[]): Charger[] {
    let filteredLocationTypeChargers: Charger[] = [];
    if (this.selectedLocationTypes.length > 0 && this.selectedLocationTypes.length < this.locationTypes.length) {
      for (let charger in chargers) {
        let chargerInfo = chargers[charger];
        let locationType = chargerInfo['locationType'];

        if (this.selectedLocationTypes.includes(locationType)) {
          filteredLocationTypeChargers.push(chargers[charger]);
        }
      }
    } else {
      filteredLocationTypeChargers = chargers;
    }
    return filteredLocationTypeChargers;
  }

  getChargersFilteredByModel(chargers: Charger[]): Charger[] {
    let filteredModelChargers: Charger[] = [];
    if (this.selectedModels.length > 0 && this.selectedModels.length < this.models.length) {
      for (let charger in chargers) {
        let chargerInfo = chargers[charger];
        let model = chargerInfo['model'];

        if (this.selectedModels.includes(model)) {
          filteredModelChargers.push(chargers[charger]);
        }
      }
    } else {
      filteredModelChargers = chargers;
    }
    return filteredModelChargers;
  }

  getChargersFilteredByMaxOutputPower(chargers: Charger[]): Charger[] {
    let filteredChargersByMaxOutputPower: Charger[] = [];

    for (let charger in chargers) {
        let chargerInfo = chargers[charger];
        let maxOutputPower: number = chargerInfo['maxOutputPower'];

        if (maxOutputPower >= this.maxOutputPowerFrom && maxOutputPower <= this.maxOutputPowerTo) {
          filteredChargersByMaxOutputPower.push(chargers[charger]);
        }
    }
    return filteredChargersByMaxOutputPower;
  }

  getChargersFilteredByConnectorType(chargers: Charger[]): Charger[] {
    let filteredChargersByConnectorType: Charger[] = [];

    if (this.selectedConnectorTypes.length > 0 && this.selectedConnectorTypes.length < this.connectorTypes.length) {
      for (let charger in chargers) {
        let chargerInfo = chargers[charger];

        let connectors = chargerInfo["connectors"];

        for (let connector in connectors) {
          let connectorType = connectors[connector]["connectorType"];
          if (this.selectedConnectorTypes.includes(connectorType) && !filteredChargersByConnectorType.includes(chargers[charger])) {
            filteredChargersByConnectorType.push(chargers[charger]);
          }
        }
    }
    } else {
      filteredChargersByConnectorType = chargers;
    }
    return filteredChargersByConnectorType;
  }

  resetCountiesSelected() {
    this.selectedCounties = [];
  }

  selectAllCounties() {
    this.selectedCounties = this.counties;
  }

  resetSectorsSelected() {
    this.selectedSectors = [];
  }

  selectAllSectors() {
    this.selectedSectors = this.sectors;
  }

  resetVendorsSelected() {
    this.selectedVendors = [];
  }

  selectAllVendors() {
    this.selectedVendors = this.vendors;
  }

  resetLandOwnersSelected() {
    this.selectedLandOwners = [];
  }

  selectAllLandOwners() {
    this.selectedLandOwners = this.landOwners;
  }

  resetChargePointOwnersSelected() {
    this.selectedChargePointOwners = [];
  }

  selectAllChargePointOwners() {
    this.selectedChargePointOwners = this.chargePointOwners;
  }

  resetAccessTypesSelected() {
    this.selectedAccessTypes = [];
  }

  selectAllAccessTypes() {
    this.selectedAccessTypes = this.accessTypes;
  }

  resetLocationTypesSelected() {
    this.selectedLocationTypes = [];
  }

  selectAllLocationTypes() {
    this.selectedLocationTypes = this.locationTypes;
  }

  resetConnectorTypesSelected() {
    this.selectedConnectorTypes = [];
  }

  selectAllConnectorTypes() {
    this.selectedConnectorTypes = this.connectorTypes;
  }

  resetModelsSelected() {
    this.selectedModels = [];
  }

  selectAllModels() {
    this.selectedModels = this.models;
  }
}
