import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  url:string = 'https://www.easygo-dev.com:8081/v1';
  // url:string = 'https://localhost:8081/v1';

  constructor(private http: HttpClient) { }
  
  
  public getAllLocations() {
    try {
      return this.http.get<JSON>(this.url + '/locationsOfChargingStations');
    } catch (error) {
      console.error(error);
      return EMPTY;
    }
  }
  
  public postLocationsForCsvCreation(postObject: any) {
    try {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('Access-Control-Allow-Methods', 'POST');

      let body = JSON.stringify(postObject);

      let req = this.http.post<JSON>(`${this.url}/postLocations`, body, 
      { headers: headers, responseType: 'json'});

      req.subscribe( response => {
        FileSaver.saveAs(response, 'test');

      })
    } catch(error) {
      console.error(error);
      return EMPTY;
    }
  }
}