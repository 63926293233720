<div class="map-sidebar">
  <div class="month-selector">
    <div class="btn-group-nav">
      <app-tab-navigator [mapType]="'charger-map'"></app-tab-navigator>
    </div>
   
    <h5 class="title-d">Apply Filter to Map</h5>
    <div class="filter-container">
      <div class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Charger</mat-label>
          <mat-select [formControl]="chargerControl" multiple="">
            <mat-select-trigger>
              {{ chargerControl.value ? chargerControl.value[0] : "" }}
              <span
                *ngIf="chargerControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ chargerControl.value.length - 1 }}
                {{
                  chargerControl.value?.length === 2 ? "other" : "others"
                }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let chargeType of chargers"
              [value]="chargeType"
              >{{ chargeType }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div *ngIf="!chargerControl.value.includes('Eir')" class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Charge Type</mat-label>
          <mat-select [formControl]="chargeTypeControl" multiple>
            <mat-select-trigger>
              {{ chargeTypeControl.value ? chargeTypeControl.value[0] : "" }}
              <span
                *ngIf="chargeTypeControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ chargeTypeControl.value.length - 1 }}
                {{ chargeTypeControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let chargeType of chargeTypes"
              [value]="chargeType"
              >{{ chargeType }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div> -->
      <div *ngIf="chargerControl.value.includes('EasyGo')" class="content">

        <mat-form-field appearance="fill">
          <mat-label>Select</mat-label>

          <mat-select [formControl]="easyGoControl" multiple>

            <mat-select-trigger>
              {{ easyGoControl.value ? easyGoControl.value[0] : "" }}
              <span
                *ngIf="easyGoControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ easyGoControl.value.length - 1 }}
                {{ easyGoControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>

            <mat-option
              *ngFor="let easyGoType of easyGoTypes"
              [value]="easyGoType"
              >{{ easyGoType }}
            </mat-option>

          </mat-select>

        </mat-form-field>

      </div>

      <div *ngIf="chargerControl.value?.includes('EasyGo') " class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Charge Point Owner Type</mat-label>
          <mat-select [formControl]="chargePointOwnerTypeControl" multiple>
            <mat-select-trigger>
              {{ chargePointOwnerTypeControl.value ? chargePointOwnerTypeControl.value[0] : "" }}
              <span
                *ngIf="chargePointOwnerTypeControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ chargePointOwnerTypeControl.value.length - 1 }}
                {{ chargePointOwnerTypeControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let cpot of chargePointOwnerType"
              [value]="cpot"
              >{{ cpot }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="chargerControl.value.includes('Eir')" class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Eir Type</mat-label>
          <mat-select [formControl]="eirTypeControl" multiple>
            <mat-select-trigger>
              {{
                eirTypeControl.value
                  ? getEirSelectedItem(eirTypeControl.value[0])?.label
                  : ""
              }}
              <span
                *ngIf="eirTypeControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ eirTypeControl.value.length - 1 }}
                {{ eirTypeControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let eirType of eirTypes"
              [value]="eirType.key"
              >{{ eirType.label }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      
      <div *ngIf="chargerControl.value.includes('MYB')" class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select MYB Type</mat-label>
          <mat-select [formControl]="mybTypeControl" multiple>
            <mat-select-trigger>
              {{
                mybTypeControl.value
                  ? getMYBSelectedItem(mybTypeControl.value[0])?.label
                  : ""
              }}
              <span
                *ngIf="mybTypeControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ mybTypeControl.value.length - 1 }}
                {{ mybTypeControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let mybType of mybTypes"
              [value]="mybType.key"
              >{{ mybType.label }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="chargerControl.value.includes('Weev/EPower')" class="content">
        <mat-form-field appearance="fill">
          <mat-label>Select Weev/EPower</mat-label>
          <mat-select [formControl]="weevEPowerTypeControl" multiple>
            <mat-select-trigger>
              {{
                weevEPowerTypeControl.value
                  ? getWeevEPowerSelectedItem(weevEPowerTypeControl.value[0])?.label
                  : ""
              }}
              <span
                *ngIf="weevEPowerTypeControl.value?.length > 1"
                class="example-additional-selection"
              >
                (+{{ weevEPowerTypeControl.value.length - 1 }}
                {{ weevEPowerTypeControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger>
            <mat-option
              *ngFor="let weevEPowerType of weevEPowerTypes"
              [value]="weevEPowerType.key"
              >{{ weevEPowerType.label }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
  <div class="btn-container">
    <button (click)="getMarkerData()" mat-raised-button color="primary">
      Apply
    </button>
    <button class="reset-btn" (click)="reset()" mat-raised-button>Reset</button>
  </div>
  <div *ngIf="!isLoading && _selectedLocation" class="show-details">
    <h5 class="city-name title-d">
      {{ _selectedLocation.friendlyName || _selectedLocation.address }}
    </h5>
    <!-- <div class="content">
        <ul class="average-list">
          <li>
            Energy Consumption (avg):
            <span>{{ energyAverage | number: "0.0-2" }}</span>
          </li>
          <li>
            Charge Sessions (avg):
            <span>{{ chargingAverage | number: "0.0-2" }}</span>
          </li>
          <li>
            Time Charging (avg):
            <span>{{ timeAverage | number: "0.0-2" }}</span>
          </li>
        </ul>
        <div class="btn-container">
          <button (click)="navigateToChart()" mat-raised-button color="primary">
            Show More Details
          </button>
        </div>
      </div> -->
  </div>
  <div class="loader-container">
    <mat-spinner [diameter]="50" *ngIf="isLoading"></mat-spinner>
  </div>
</div>
