import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
// import {
//   Chart, registerables
// } from 'chart.js';
// import { ChartConfiguration } from 'chart.js';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HeatmapService } from 'src/app/services/heatmap-service/heatmap.service';
//Chart.register(...registerables);

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss']
})
export class SiteDetailsComponent implements OnInit {
  @ViewChild("line") lineChart: ElementRef<any>;
  @ViewChild("bar") barChart: ElementRef<any>;
  @ViewChild("barTime") barTimeChart: ElementRef<any>;

  chargeTypes: any = ['AC', 'DC'];
  months: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  years: any = [];
  chargeTypeControl: FormControl = new FormControl('', []);
  monthControl: FormControl = new FormControl([], []);
  yearControl: FormControl = new FormControl('', []);
  locationControl: FormControl = new FormControl('', []);
  isLoading: boolean;
  locations: any[] = [];
  countsData: any = {
    energyConsumption: 0,
    chargingTime: 0,
    noOfChargers: 0
  };
  chartData: any = {};
  //energyChart: Chart;
  //timeChart: Chart;
  //chargingChart: Chart;
  energyAverage: number;
  timeAverage: number;
  chargingAverage: number;
  filteredOptions: Observable<string[]>;
  myControl = new FormControl('');

  constructor(private heatMapService: HeatmapService,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.generateYears(2020);
    this.getLocations();

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.months) {
        const monthsArr = params.months.split(',');
        this.monthControl.setValue(monthsArr);
      }

      if (params.year) {
        this.yearControl.setValue(Number(params.year));
      }

      if (params.locationId) {
        this.myControl.setValue({friendlyCode: params.locationId, friendlyName: params.locationName});
      }

      if (params.chargeType) {
        if (Array.isArray(params.chargeType)) {
          this.chargeTypeControl.setValue(params.chargeType);
        } else {
          this.chargeTypeControl.setValue(params.chargeType.split(','));
        }
      }

      if (this.myControl.value && this.chargeTypeControl.value && this.yearControl.value && this.monthControl.value) {
        this.applyFilter();
      }
    });

  }

  private _filter(value: any): string[] {

    const filterValue = (typeof value === "object") ? value.friendlyName.toLowerCase() : value.toLowerCase();

    if (!value || value == '') {
      return this.locations;
    }

    return this.locations.filter(option => option.friendlyName.toLowerCase().includes(filterValue));
  }

  goBack() {
    this.router.navigate(['/heatmapnew']);
  }

  displayFn(location) {
    return location.friendlyName;
  }

  getLocations() {
    this.isLoading = true;
    this.heatMapService.getAllLocations().subscribe((res: any) => {
      this.isLoading = false;
      if (res.locations.length) {
        this.locations = res.locations;
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );
      }

    }, (error) => {
      this.isLoading = false;
    })
  }

  generateFilterParam() {
    const months = this.monthControl.value.map((m) => m.slice(0, 3));
    const data = {
      chargeType: this.chargeTypeControl.value,
      locationId: this.myControl.value.friendlyCode,
      months: months,
      year: this.yearControl.value,
      "accessType": ["Public", "Private"]
    }
    return data;
  }

  getCounts(data) {
    this.isLoading = true;


    this.heatMapService.getCounts(data).subscribe((res) => {
      this.isLoading = false;
      this.countsData = res;
    }, (error) => {
      this.openSnackBar("Something Went Wrong!", "Close");
      this.isLoading = false;
    })
  }

  generateChartData(data): any {
    return {
      labels: Object.keys(data).map((d) => d.slice(0, 3)),
      dataset: Object.values(data)
    }
  }

  getChartData(data) {
    this.isLoading = true;

    this.heatMapService.getChartData(data).subscribe((res: any) => {
      this.isLoading = false;
      this.chartData = res;
      if (res.energyConsumption) {
        const { labels, dataset } = this.generateChartData(res.energyConsumption);
        this.energyAverage = dataset.reduce((a, b) => a + b, 0) / dataset.length;
        //this.energyChart.data.datasets[0].data = dataset;
        //this.energyChart.data.labels = labels;
        //this.energyChart.update();
        // this.energyConsumptionChart(dataset, labels);
      }

      if (res.noOfChargingSessions) {
        const { labels, dataset } = this.generateChartData(res.noOfChargingSessions);
        this.chargingAverage = dataset.reduce((a, b) => a + b, 0) / dataset.length;
        //this.chargingChart.data.datasets[0].data = dataset;
        //this.chargingChart.data.labels = labels;
        //this.chargingChart.update();
      }

      if (res.chargingTime) {
        const { labels, dataset } = this.generateChartData(res.chargingTime);
        this.timeAverage = dataset.reduce((a, b) => a + b, 0) / dataset.length;
        //this.timeChart.data.datasets[0].data = dataset;
        //this.timeChart.data.labels = labels;
        //this.timeChart.update();
      }
    }, (error) => {
      this.openSnackBar("Something Went Wrong!", "Close");
      this.isLoading = false;
    })
  }

  applyFilter() {
    const data = this.generateFilterParam();
    this.getCounts(data);
    this.getChartData(data);
  }

  generateYears(startYear) {
    const year = Number(new Date().getFullYear());
    for (let i = startYear; i <= year; i++) {
      this.years.push(i);
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }

  ngAfterViewInit() {
    this.energyConsumptionChart();
    this.chargeSessionsChart();
    this.timeChargeChart();
  }

  onTabChange(e) {
    this.energyConsumptionChart();
    this.chargeSessionsChart();
    this.timeChargeChart();
  }

  energyConsumptionChart(dataset = [], labels = []) {
    const ctx = this.lineChart.nativeElement.getContext('2d');
    // this.energyChart = new Chart(ctx, {
    //   type: 'line',
    //   data: {
    //     labels: labels,
    //     datasets: [{
    //       label: 'Energy Consumption (KW) by Month and Year',
    //       data: dataset,
    //       backgroundColor: [
    //         '#3C5F3D',

    //       ],
    //       borderColor: [
    //         '#4AA545',
    //       ],
    //       borderWidth: 1,
    //       fill: true,
    //     }]
    //   },
    //   options: {
    //     scales: {
    //       y: {
    //         beginAtZero: true,
    //         ticks: {
    //           color: '#fff'
    //         }
    //       },
    //       x: {
    //         ticks: {
    //           color: '#fff'
    //         }
    //       }
    //     },
    //     plugins: {
    //       legend: {
    //         labels: {
    //           color: '#fff'
    //         }
    //       }
    //     },
    //     responsive: false
    //   }
    // });
  }

  timeChargeChart(dataset = [], labels = []) {
    const ctx = this.barTimeChart.nativeElement.getContext('2d');
    // this.timeChart = new Chart(ctx, {
    //   type: 'bar',
    //   data: {
    //     labels: labels,
    //     datasets: [{
    //       label: 'Time Charging (Hrs) by Month and Year',
    //       data: dataset,
    //       backgroundColor: [
    //         '#3C5F3D',

    //       ],
    //       borderColor: [
    //         '#4AA545',
    //       ],
    //       borderWidth: 1,
    //       maxBarThickness: 80
    //     }]
    //   },
    //   options: {
    //     scales: {
    //       y: {
    //         beginAtZero: true,
    //         ticks: {
    //           color: '#fff'
    //         }
    //       },
    //       x: {
    //         ticks: {
    //           color: '#fff'
    //         }
    //       },
    //     },
    //     plugins: {
    //       legend: {
    //         labels: {
    //           color: '#fff'
    //         }
    //       }
    //     },
    //     responsive: false
    //   }
    // });
  }

  chargeSessionsChart(dataset = [], labels = []) {
    const ctx = this.barChart.nativeElement.getContext('2d');
    // this.chargingChart = new Chart(ctx, {
    //   type: 'bar',
    //   data: {
    //     labels: labels,
    //     datasets: [{
    //       label: 'No. of Charging Sessions by Month and Year',
    //       data: dataset,
    //       backgroundColor: [
    //         '#3C5F3D',

    //       ],
    //       borderColor: [
    //         '#4AA545',
    //       ],
    //       borderWidth: 1,
    //       maxBarThickness: 80
    //     }]
    //   },
    //   options: {
    //     scales: {
    //       y: {
    //         beginAtZero: true,
    //         ticks: {
    //           color: '#fff'
    //         }
    //       },
    //       x: {
    //         ticks: {
    //           color: '#fff'
    //         }
    //       }
    //     },
    //     plugins: {
    //       legend: {
    //         labels: {
    //           color: '#fff'
    //         }
    //       }
    //     },
    //     responsive: false
    //   }
    // });
  }

}
