import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationFormService {

  url: string = 'https://www.easygo-dev.com:8081/v1/'; // production
  // url: string = 'https://localhost:8081/v1/'; // development



  returnedMessage: string = '';
  userCreationCounter: number = 0;

  constructor(private http: HttpClient) {}

  postNewUsers(user: {}) {

    try {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', 'https://www.easygo-dev.com')
        .set('Access-Control-Allow-Methods', 'POST');
  
        let body = JSON.stringify(user);
        const req = this.http.post<any>(`${this.url}postUser/`, body, { headers: headers, responseType: 'json'});
        req.subscribe(data => {

          let message = document.createElement('div');
          message.textContent = data;
          message.style.opacity = '0';
          message.style.margin = '.25rem';
          setTimeout(() => {
            message.style.opacity = '1';
            message.style.transition = '.5s';
          }, 10);
          document.getElementById('returnedMessages').appendChild(message);
          this.userCreationCounter++;
        })
    } catch(error) {
      console.error(error);
      return EMPTY;
    }
  }
}
