<div class="appletContainer" >

    <div class="company_reports_grid">
    
        <!-- <div> -->
            <!-- <form> -->

                <!-- First row -->
                <div class='menu_spacer'>
                </div>
                <div class='dropdown'>    
                    <div class='fleetDropdown'>
                        <div (mouseleave)='hideFleetDropdown()'>
                            <button class='companiesDropdown' (mouseenter)='getFleetplanCompaniesList($event)'>
                                Fleet Subscriber Companies
                            </button>
                            <div id='fleetDropdownList' class='dropdownContent'>
                                <span *ngFor="let fleetplanCompanies of fleetplanCompaniesList">
                                    <option (click)="createFleetSubscriberForm($event)" class="fleetCompany">{{fleetplanCompanies.name}}</option>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="menu_spacer">
                </div>
                <div class="dropdown">
                    <div class="buttons_grid_child">
                        <button (click)="getSingleCompaniesList()">Single Companies</button>
                    </div>
                </div>

                <div class="menu_spacer">
                </div>

                <!-- Second row -->
                <div class="column_filler">

                </div>


                <!-- Third row -->
                <div class="menu_spacer">

                </div>
                <!-- Fourth row, beginning of form -->
                <div class="charging">
                    <div>
                        <div class="dateLabel">
                            <label for="fromDate">Charging From</label>
                        </div>
                        <div class="input-group date">
                            <input type="datetime-local" 
                            class="form-control" 
                            autocomplete="off" 
                            id="fleetSubscriberChargingFrom"/>
                        </div>
                    </div>
                </div>
                <div class="menu_spacer">

                </div>
                <div class="charging">
                    <div>
                        <div class="dateLabel">
                            <label for="fromDate">Charging To</label>
                        </div>
                        <div class="input-group date">
                            <input type="datetime-local" 
                            class="form-control" 
                            autocomplete="off" 
                            id="fleetSubscriberChargingTo"/>
                        </div>
                    </div>
                </div>
                
                <div>

                </div>
                
                <div class="column_filler">
                    <div class="submit_grid">
                        <div class="menu_spacer">

                        </div>
                        <div id="submitFleetForm" *ngIf="showSubmitButton">
                            <button (click)="getFleetCompanySessions($event)" id="submitButtonFleet" type="submit">Create PDF Report for {{fleetCompany}} charging sessions</button>
                        </div>
                        <div class="menu_spacer">

                        </div>
                    </div>
                </div>
            



        <div class="single_companies_container">

                <!-- Single Companies -->
                <div>
                    <div>
                        <form [formGroup]="profileForm" (ngSubmit)="getCompanyUserPayers()">
                
                            <div class="checkboxes-grid" (click)="highlightCompanyChecks($event)">
                                <div *ngFor="let singleCompany of singleCompaniesList" > 
                                    <label>
                                        <input type="checkbox" name="{{singleCompany}}" value="{{singleCompany}}" checked="false" formControlName="companies"/>
                                        <span class="textOffset">{{singleCompany}}</span>
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="showFormInputs === true">
                        
                                <div class="dates-grid">
                                    <div class="charging">
                                        <div class="dateLabel">
                                            <label for="fromDate">Charging From</label>
                                        </div>
                                        <div class="input-group date">
                                            <input type="datetime-local" class="form-control" autocomplete="off" formControlName="chargingFrom" id="chargingFrom"/>
                                        </div>
                                    </div>
                                    <div></div>
                                    <div class="charging">
                                        <div class="dateLabel">
                                            <label for="fromDate">Charging To</label>
                                        </div>
                                        <div class="  date">
                                            <input type="datetime-local" class="form-control" autocomplete="off" formControlName="chargingTo" id="chargingTo"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="chargingTypes_grid" (click)="highlightCompanyChecks($event)">
                                    <div>
                                        <label>
                                            <input type="checkbox" name="{{this.accessTypes.privateHome}}" value="{{accessTypes.privateHome}}" checked="false" formControlName="accessType"/>
                                            <span class="accessText textOffset">{{accessTypes.privateHome}}</span>
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input type="checkbox" name="{{this.accessTypes.privateOnSite}}" value="{{accessTypes.privateOnSite}}" checked="false" formControlName="accessType"/>
                                            <span class="accessText textOffset">{{accessTypes.privateOnSite}}</span>
                                        </label>
                                    </div>
                                    <div>
                                        <div>
                                            <label>
                                                <input type="checkbox" name="{{this.accessTypes.publicFree}}" value="{{accessTypes.publicFree}}" checked="false" formControlName="accessType"/>
                                                <span class="accessText textOffset">{{accessTypes.publicFree}}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <label>
                                            <input type="checkbox" name="{{this.accessTypes.publicPayable}}" 
                                            value="{{accessTypes.publicPayable}}" checked="false" formControlName="accessType"/>
                                            <span class="accessText textOffset">{{accessTypes.publicPayable}}</span> 
                                        </label>
                                    </div>
                                    
                                </div>
                
                                <div class="submitGrid">
                                    <div>
                
                                    </div>
                                        <div id="submitForm">
                                            <button id="submitButton" type="submit" [disabled]="!profileForm.valid">Create PDF Report for Charging Sessions</button>
                                        </div>
                                    <div>
                
                                    </div>
                                </div>
                            </div>
                            <div class="centerOverlay" *ngIf="companies.showWaitingSpinner">
                                <div class="showWaitingSpinner"></div>
                                <div class="retrievingData">Attempting to retrieve charging sessions and generate invoice report</div>
                            </div>
                            <div class="pdfGenResponseMessage">
                                {{companies.pdfStatus}}
                            </div>
                        </form>
                    </div>
                    <div *ngIf="noDataFound">        
                        <div id="returnedData">{{returnedData}}</div>
                    </div>
                    <div class="result" *ngIf="dataFound">
                        Data found for: 
                        <span *ngFor="let companySaved of companiesSaved">
                            {{companySaved}}, 
                        </span>
                
                        <div>
                            <span  *ngFor="let companySaved of companiesSaved">
                                CSV Invoice template and charging sessions report for {{companySaved}} has been saved to "Invoices/{{companySaved}}"<br>
                            </span>
                    
                        </div>
                    </div>
                </div>
        
        
            </div>
        </div>

        <div>
            <div>
                <div class="centerOverlay" *ngIf="companies.showFleetWaitingSpinner">
                    <div class="showWaitingSpinner"></div>
                    <div class="retrievingData">Attempting to retrieve charging sessions and generate invoice report</div>
                </div>
            </div>
            
    
            <div class="pdfGenResponseMessage">
                {{companies.pdfFleetStatus}}
            </div>
        </div>


        <div>
            <hr class="solid">

        </div>

        <div>
            <form [formGroup]='multipleFleetSubReportForm' (ngSubmit)='submitMultipleFleetsubs($event)'>
                <div class="multipleFleet">
                    <div id='fleetCompanies'>
                        <div *ngFor='let fleetCompany of fleetplanCompaniesList'>
                            <label >
                                <input formControlName='fleetSubCompanies' class='checkbox' [checked]='selectAllFleetSubs' type='checkbox' name='{{fleetCompany.name}}' value= '{{fleetCompany.name}}' />
                                <span class="accessText" >{{fleetCompany.name}}</span>
                            </label>
                            
                        </div>
                        <div (click)='getAllFleetSubs()'>
                            <input  formControlName='fleetSubCompanies' class='checkbox' [checked]='selectAllFleetSubs' type='checkbox' name='Select All' value= 'Select All' />
                            <span class="accessText" >Select All</span>
                        </div>
                    </div>

                    <div class='multiFleetBottom'>
                        <div></div>

                        <div class="charging">
                           <div>
                               <div class="dateLabel">
                                   <label for="fromDate">Charging From</label>
                               </div>
                               <div class="input-group date">
                                   <input type="datetime-local" 
                                   class="form-control" 
                                   autocomplete="off" 
                                   formControlName='chargingFrom'
                                   id="fleetSubscriberMultipleChargingFrom"/>
                               </div>
                           </div>
                       </div>
                       <div class="menu_spacer">
           
                       </div>
                       <div class="charging">
                           <div>
                               <div class="dateLabel">
                                   <label for="fromDate">Charging To</label>
                               </div>
                               <div class="input-group date">
                                   <input type="datetime-local" 
                                   class="form-control" 
                                   autocomplete="off" 
                                   formControlName='chargingTo'
                                   id="fleetSubscriberMultipleChargingTo"/>
                               </div>
                           </div>
                       </div>
                       <div>

                       </div>
           
                    </div>
                    <div class='multiFleetSubmitButton'>
                        <div>

                        </div>
                        <div>
                            <button id='fleetSubmitButtonForZip' type='submit' [disabled]='!multipleFleetSubReportForm.valid'>Generate Multiple Fleetsub Reports</button>
                        </div>
                        <div>

                        </div>

                        <div>

                        </div>
                        <div>
                            
                        </div>
                    </div>
                    
                </div>
            </form>
        </div>
        <div>
            <div class='centerOverlay' *ngIf='companies.showFleetsWaitingSpinner'>
                <div id='fleetsWaitingSpinner' class='showWaitingSpinner'></div>
                <div class="retrievingData">Attempting to retrieve charging sessions for selected fleet subscribers and generate invoice reports</div>
            </div>
        </div>

        <div>
            <table id='dodgySessionsTable'>
                <tr>
                    <th>Session Id</th>
                    <th>User Id</th>
                    <th>Sum of Costs</th>
                    <th>Energy Consumed</th>
                    <th>Location Name</th>
                    <th>Charging From</th>
                    <th>Charging To</th>
                    <th>Mins Charging</th>
                    <th>Access Type</th>
                </tr>
                <tr *ngFor='let company of uniqueFlaggedSessions'>
                    <td>{{company.sessionId}}</td>
                    <td>{{company.identificationUserId}}</td>
                    <td>{{company.sumOfCosts | number : '1.0-2' }}</td>
                    <td>{{company.activeEnergyConsumption | number : '1.0-2' }}</td>
                    <td>{{company.locationName}}</td>
                    <td>{{company.chargingFrom | date : 'short' }}</td>
                    <td>{{company.chargingTo | date : 'short' }}</td>
                    <td>{{company.minsCharging | number : '1.0-2' }}</td>
                    <td>{{company.accessType}}</td>
                </tr>
            </table>
        </div>


</div>


